// HOOKS
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

// MUI
import LoginPopup from "../../../../components/Desktop/LoginPopup";
import {Box, Button, Typography} from "@mui/material";
import {isLoggedIn} from "../../../../utils/AuthUtils";

// ASSETS
import {WORKERS_HOME} from "../../../../assets/Images";
import {theme} from "../../../../styles/theme";

const HeroSection = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  // MODAL HANLDERS - SIGN IN POP
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box sx={{display: "flex", justifyContent: "space-between", paddingX: 10, marginTop: 10}}>
        <Box mt={8}>
          <Typography variant="h2" color={"#3B3B3B"} fontWeight={600}>
            {t("Need Something Done?")}
          </Typography>
          <Typography variant="h2" color={"#3B3B3B"} fontWeight={600}>
            {t("We've Got You Covered.")}
          </Typography>
          <Typography variant="h4" color={theme.palette.primary.main} fontWeight={600} mt={2}>
            {t("A Plateform For Everyone.")}
          </Typography>
          <Box mt={10} sx={{display: "flex", justifyContent: "flex-start"}}>
            <Button
              variant="contained"
              size="large"
              sx={{borderRadius: 2.5, fontSize: 20}}
              onClick={() => navigate("/search")}>
              {t("Looking for miniJob")}
            </Button>
            <Button
              variant="contained"
              size="large"
              sx={{borderRadius: 2.5, marginLeft: 5, fontSize: 20}}
              onClick={() => {
                if (!isLoggedIn()) {
                  return handleShow();
                }
                navigate("/my-jobs/new-job");
              }}>
              {t("Looking for Helper")}
            </Button>
          </Box>
        </Box>
        <Box textAlign={"end"}>
          <img src={WORKERS_HOME} alt="workers" height={400} />
        </Box>
      </Box>

      <LoginPopup open={open} closeModal={handleClose} />
    </>
  );
};

export default HeroSection;
