// HOOKS
import {FC, useState} from "react";
import {useNavigate} from "react-router";
import {useAppDispatch, useAppSelector} from "../../../store";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

// UI
import {Avatar, Box, Button, CircularProgress, Container, Typography} from "@mui/material";
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import MDrawer from "../../../components/Mobile/MDrawer";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import {removeUser} from "../../../store/Slices/UserSlice";

// ASSETS & ICONS
import {ReactComponent as ChevronRightIcon} from "../../../assets/Icons/chevron-right.svg";
import {ReactComponent as EditIcon} from "../../../assets/Icons/edit-m.svg";
import {ReactComponent as LocationIcon} from "../../../assets/Icons/location.svg";
import UpdateProfileModal from "../../Desktop/Profile/UpdateProfileModal";

const MProfile = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useAppSelector(state => state.user);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  // DRAWER HANDLERS
  const [openDrawer, setOpenDrawer] = useState(false);
  const hanldeOpenDrawer = () => setOpenDrawer(true);
  const hanldeCloseDrawer = () => setOpenDrawer(false);

  // DRAWER HANDLERS
  const [openChangeDP, setOpenChangeDP] = useState(false);
  const hanldeOpenChangeDP = () => setOpenChangeDP(true);
  const hanldeCloseChangeDP = () => setOpenChangeDP(false);

  const handleLogOut = () => {
    setLoading(true);
    setTimeout(() => {
      dispatch(removeUser());
      navigate("/m/sign-in");
      enqueueSnackbar("Logged out successfully.", {variant: "success"});
    }, 1000);
  };

  return (
    <>
      <HomeNavigationHeader isPrimary primaryText="Profile" />

      {/* PROFILE */}
      <Container disableGutters sx={{position: "relative"}}>
        {/* GRADEINT CARD */}
        <Box sx={{borderRadius: 2.5, overflow: "hidden", marginBottom: 4}}>
          <BoxPrimaryGradient>
            <Box sx={{padding: 2, color: theme.palette.common.white, display: "flex", alignItems: "center"}}>
              <Box sx={{position: "relative"}}>
                <Avatar sx={{width: 80, height: 80, textTransform: "uppercase"}} src={user.profile}>
                  {user.name.split("")[0]}
                </Avatar>
                <EditIcon
                  style={{position: "absolute", bottom: 0, right: 0, height: 24, width: 24}}
                  onClick={hanldeOpenChangeDP}
                />
              </Box>
              <Box sx={{marginLeft: 2}}>
                <Typography variant="h6">{user.name}</Typography>
                {user.address ? (
                  <Typography variant="body2" sx={{display: "flex", alignItems: "center"}}>
                    <LocationIcon style={{width: 14, height: 16, marginRight: 8}} fill="#fff" />
                    {user?.address}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </BoxPrimaryGradient>
        </Box>

        {/* PROFILE NAVIGATION LINKS */}
        <Box sx={{marginY: 2}}>
          <ProfileNavItem label={t("Edit Profile")} onClick={() => navigate("/m/edit-profile")} />
          <ProfileNavItem label={t("Languages")} onClick={() => navigate("/m/languages")} />
          <ProfileNavItem
            label={t("Terms & Conditions")}
            onClick={() => navigate("/m/terms-and-conditions")}
          />
          <ProfileNavItem label={t("Privacy Policy")} onClick={() => navigate("/m/privacy-policy")} />
          <ProfileNavItem label={t("FAQs")} onClick={() => navigate("/m/faqs")} />
          <ProfileNavItem label={t("About Us")} onClick={() => navigate("/m/about-us")} />
        </Box>

        {/* LOGOUT BUTTON */}
        <Button
          variant="outlined"
          fullWidth
          sx={{
            paddingY: 1.5,
            textTransform: "none",
            borderRadius: 2.5,
            fontSize: 16,
            fontWeight: 600,
            marginY: 2,
            // position: "absolute",
            // top: "calc(100vh - 245px)",
          }}
          onClick={hanldeOpenDrawer}>
          {t("Logout")}
        </Button>
      </Container>

      {/* CHANGE DP MODAL */}
      <UpdateProfileModal open={openChangeDP} handleClose={hanldeCloseChangeDP} user={user} isMobileView />

      {/* LOG OUT DRAWER */}
      <MDrawer
        open={openDrawer}
        closeDrawer={hanldeCloseDrawer}
        HeaderText={() => (
          <Typography variant="h5" color={theme.palette.error.main} fontWeight={700}>
            {t("Logout")}
          </Typography>
        )}
        Content={() => (
          <>
            <Typography variant="h6" textAlign={"center"} fontWeight={600}>
              {t("Are you sure you want to log out?")}
            </Typography>
            <Box sx={{display: "flex", gap: 2, marginY: 3}}>
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  fontSize: 16,
                  borderRadius: 2.5,
                  py: 2,
                  flexBasis: "50%",
                  textTransform: "none",
                  fontWeight: 700,
                }}
                onClick={hanldeCloseDrawer}>
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  fontSize: 14,
                  borderRadius: 2.5,
                  py: 2,
                  flexBasis: "50%",
                  textTransform: "none",
                  fontWeight: 700,
                }}
                onClick={handleLogOut}>
                {t("Yes, Logout.")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </Box>
          </>
        )}
      />
    </>
  );
};

export default MProfile;

interface Props {
  label: string;
  onClick: () => void;
}

const ProfileNavItem: FC<Props> = ({label, onClick}) => {
  return (
    <>
      <Box
        sx={{
          // border: "1px solid lightgray",
          borderRadius: 2.5,
          padding: 1.5,
          boxShadow: "0px 0px 7px rgba(146, 146, 146, 0.45)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 1.5,
        }}
        onClick={onClick}>
        <Typography variant="body1">{label}</Typography>
        <ChevronRightIcon />
      </Box>
    </>
  );
};
