// HOOKS
import {FC, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useAppDispatch} from "../../../store";
import {useTranslation} from "react-i18next";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import {userSchema} from "../../Mobile/EditProfile/schema";
import {updateUser} from "../../../store/Slices/UserSlice";
import AuthServices from "../../../services/AuthServices";
import {IUserData} from "../../../interfaces/IUser";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

// ASSETS & ICONS
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";

interface Props {
  user: IUserData;
  open: boolean;
  handleClose: () => void;
}

export interface IUserEditForm {
  name: string;
  email: string;
  phone: string;
  address: string;
  description: string;
}

const EditProfileModal: FC<Props> = ({open, handleClose, user}) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {
    handleSubmit,
    control,
    formState: {errors},
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      description: "",
    },
    resolver: joiResolver(userSchema),
  });

  async function submitForm(formData: IUserEditForm) {
    setLoading(true);
    const {name, address, description} = formData;
    try {
      const resp = await AuthServices.UpdateMe({name, address, description});

      // SUCCESS
      if (resp.status === 200) {
        setLoading(false);
        enqueueSnackbar(t("Profile updated successfully."), {variant: "success"});
        dispatch(updateUser({name, address, description}));
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      const message = (err.response?.data as {message: string})?.message;
      console.log(err);
      // EMAIL ALREADY REGISTERED
      if (err.response?.status === 400 && message === "Email is registered already.") {
        enqueueSnackbar(t("This email is already registered."), {variant: "error"});
      }
      // PHONE ALREADY REGISTERED
      else if (err.response?.status === 400 && message === "Phone is already registered.") {
        enqueueSnackbar(t("This phone number is already registered."), {variant: "error"});
      }
      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    setValue("description", user.description);
    setValue("email", user.email);
    setValue("address", user.address);
    setValue("name", user.name);
    setValue("phone", user.phone);
  }, [user]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        sx={{
          height: "100vh",
          overflowY: "scroll",
          display: "grid",
          placeItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            marginY: 3,
            width: "60%",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}>
          {/* HEADER */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="h5" fontWeight={600} color={theme.palette.primary.main}>
              {t("Edit Details")}
            </Typography>
            <CloseIcon
              onClick={() => {
                handleClose();
                reset();
              }}
            />
          </Box>

          {/* BODY */}
          <Box
            sx={{
              marginTop: 2,
            }}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* NAME */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, display: "block"}}
                htmlFor="name">
                {t("Name")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="name"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="name"
                        size="medium"
                        variant="outlined"
                        type="text"
                        {...field}
                        error={errors?.name ? true : false}
                        helperText={errors?.name ? errors?.name?.message : null}
                        placeholder={t("Name")}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#D2EBFF !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* EMAIL */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, display: "block"}}
                htmlFor="email">
                {t("Email")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="email"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="email"
                        size="medium"
                        variant="outlined"
                        type="email"
                        value={field.value}
                        disabled
                        error={errors?.email ? true : false}
                        helperText={errors?.email ? errors?.email?.message : null}
                        placeholder={t("Email")}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#D2EBFF !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* PHONE */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, display: "block"}}
                htmlFor="phone">
                {t("Phone")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="phone"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="phone"
                        size="medium"
                        variant="outlined"
                        type="text"
                        value={field.value}
                        disabled
                        error={errors?.phone ? true : false}
                        helperText={errors?.phone ? errors?.phone?.message : null}
                        placeholder={t("Phone")}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#D2EBFF !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* LOCATION */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, display: "block"}}
                htmlFor="address">
                {t("Location")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="address"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="address"
                        size="medium"
                        variant="outlined"
                        type="text"
                        {...field}
                        error={errors?.address ? true : false}
                        helperText={errors?.address ? errors?.address?.message : null}
                        placeholder={t("Location")}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#D2EBFF !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* DESCRIPTION */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, display: "block"}}
                htmlFor="description">
                {t("Who am I?")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="description"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        multiline
                        id="description"
                        size="medium"
                        variant="outlined"
                        type="text"
                        {...field}
                        error={errors?.description ? true : false}
                        helperText={errors?.description ? errors?.description?.message : null}
                        placeholder={t("Description here")}
                        rows={6}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                            padding: 2,
                          },
                          "& .MuiInputBase-root": {
                            padding: 0,
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* SUBMIT BUTTON */}
              <FormControl sx={{display: "block", marginTop: 8}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                  disabled={isLoading}>
                  {t("Save")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EditProfileModal;
