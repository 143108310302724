import Joi from "joi";

export const registerUser = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .empty()
    .required()
    .messages({
      "string.base": "Email is required.",
      "string.required": "Email is required.",
      "string.empty": "Email is required.",
      "string.email": "Must be a valid email.",
    }),
  password: Joi.string().empty().min(8).required().messages({
    "string.base": "Password is required.",
    "string.required": "Password is required.",
    "string.empty": "Password is required.",
    "string.min": "Must have 8 letters.",
  }),
  confirmPassword: Joi.string().valid(Joi.ref("password")).required().empty().messages({
    "any.only": "Passwords do not match.",
    "string.base": "Confirm Password is required.",
    "string.required": "Confirm Password is required.",
    "string.empty": "Confirm Password is required.",
  }),
  name: Joi.string().empty().min(3).required().messages({
    "string.base": "Name is required.",
    "string.required": "Name is required.",
    "string.empty": "Name is required.",
    "string.min": "Must have 3 letters.",
  }),
  phone: Joi.string().empty().min(10).required().messages({
    "string.base": "Phone is required.",
    "string.required": "Phone is required.",
    "string.empty": "Phone is required.",
    'string.min': 'Phone number must be in international format.'
  }),
  agreedToTC: Joi.boolean().valid(true).required().messages({
    "any.only": "You must agree to the Terms & Conditions.",
  }),
});