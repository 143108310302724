// HOOKS
import {useState} from "react";
import {useTranslation} from "react-i18next";

// MUI
import {Box, Typography} from "@mui/material";
import CustomAccordion from "../../../../components/Desktop/Accordian";

const MFAQs = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const {t} = useTranslation();

  return (
    <>
      <Typography variant="h4" fontWeight={600} textAlign={"center"} mb={4}>
        {t("Our Most Frequently Asked Questions")}
      </Typography>

      {/* ACCORDIAN */}
      <Box mb={10} paddingX={10}>
        <CustomAccordion
          id="p1"
          question={t("What is a miniJob?")}
          answer={t(
            `A miniJob is an offer created by the CLIENT. Typically, it involves a service, a sales contract, or a charitable activity. Each miniJob includes essential details such as the allocated budget, a deadline, a detailed description, and a list of the required tools and skills.`
          )}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <CustomAccordion
          id="p2"
          question={t("Which miniJobs are allowed?")}
          answer={t(
            "The specific guidelines for this can be found in our Terms and Conditions (T&Cs). It's important to emphasize that sexual or violent actions are strictly prohibited. Our platform respects the laws of each country and promotes a safe and positive environment. We do not tolerate any illegal, violent, harmful, or negatively intended activities. Such content will be promptly removed and, if necessary, reported to the authorities. We call upon the Helper2GO community to always act respectfully and with understanding. Open and respectful communication is key to mutual understanding. Thank you for your commitment and support in making Helper2GO a trustworthy and safe platform."
          )}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <CustomAccordion
          id="p3"
          question={t("How does payment work between HELPER and CLIENT?")}
          answer={t(
            "The CLIENT sets the budget for the miniJob. When a HELPER applies and does not submit a counteroffer, the budget set by the CLIENT is accepted. If counteroffers are made, the one that is accepted last will be the valid one. The payment between HELPER and CLIENT is conducted personally. Helper2GO, in its first version, focuses on facilitating the connection for miniJobs, leaving the payment arrangements to the parties involved. This allows for all types of payment methods, including cash, to be accepted. However, the specific details of the payment must be bilaterally agreed upon between the HELPER and the CLIENT"
          )}
          expanded={expanded}
          setExpanded={setExpanded}
        />
        <CustomAccordion
          id="p4"
          question={t("How much does it cost to use Helper2GO?")}
          answer={t(
            "The use of Helper2GO, including creating and applying for a miniJob, is free for all users. However, a service fee is charged once a HELPER is accepted for a miniJob. Both the HELPER and the CLIENT will then be billed a fee of 5% of the total cost of the miniJob. HELPERs should be aware that when applying for a miniJob and subsequently being accepted by a CLIENT, a fee of 5% will be incurred. Similarly, CLIENTs should be aware that for every HELPER they accept, a fee of 5% will be charged. These service fees are billed separately by Helper2GO."
          )}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      </Box>
    </>
  );
};

export default MFAQs;
