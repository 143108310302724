// HOOKS
import {SyntheticEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector, useAppDispatch} from "../../../store";

// UI
import {Box, Button, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import {IJob, IJobAPI} from "../../../interfaces/IJob";
import {theme} from "../../../styles/theme";
import ClientCard from "./ClientCard";
import HelperCard from "./HelperCard";

// SERVICES & UTILS
import JobServices from "../../../services/JobServices";

// ASSETS & ICONS
import {ReactComponent as PlusIcon} from "../../../assets/Icons/plus.svg";
import {setMyJobs} from "../../../store/Slices/JobsSlice";
import RequestServices from "../../../services/RequestServices";
import {IRequest, IRequestAPI} from "../../../interfaces/IRequest";
import {setMyRequests} from "../../../store/Slices/RequestsSlice";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MMyJobs = () => {
  const [isLoading, setLoading] = useState(true);
  const [isLoading2, setLoading2] = useState(false);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {id} = useAppSelector(state => state.user);
  const jobs = useAppSelector(state => state.jobs);
  const requests = useAppSelector(state => state.requests);
  const dispatch = useAppDispatch();

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // GET MY JOBS
  async function getMyJobs() {
    if (id) {
      setLoading(true);
      try {
        const resp = await JobServices.GetJobsFiltered({client: id});

        // SUCCESS
        if (resp.status === 200) {
          const arrOfJobs = resp.data as IJobAPI[];
          const allMyJobs: IJob[] = arrOfJobs.map((item: IJobAPI) => ({
            id: item._id,
            budget: item.budget,
            client: item.client._id,
            description: item.description,
            experience: item.experience,
            helper: item.helper?._id,
            status: item.status,
            isNotified: item.isNotified,
            startOn: item.startOn,
            endOn: item.endOn,
            title: item.title,
            tools: item.tools,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
          }));
          dispatch(setMyJobs(allMyJobs));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }

  // GET MY REQUESTS
  async function getMyRequests() {
    if (id) {
      setLoading2(true);
      try {
        const resp = await RequestServices.GetRequestsWithFilters({helper: id});

        // SUCCESS
        if (resp.status === 200) {
          const arrOfRequests = resp.data as IRequestAPI[];

          const allMyRequests: IRequest[] = arrOfRequests.map((item: IRequestAPI) => ({
            id: item._id,
            client: item.client,
            counterOffers: item.counterOffers,
            createdAt: item.createdAt,
            helper: item.helper,
            job: item.job,
            note: item.note,
            status: item.status,
            updatedAt: item.updatedAt,
          }));
          dispatch(setMyRequests(allMyRequests));
          setLoading2(false);
        }
      } catch (error) {
        setLoading2(false);
        // const err = error as AxiosError;
        // INTERNET ISSUE
        // if (err.code === "ERR_NETWORK") {
        //   enqueueSnackbar("Make sure your internet is working.", {variant: "error"});
        // } else {
        //   enqueueSnackbar("Something went wrong.", {variant: "error"});
        // }
      }
    }
  }

  useEffect(() => {
    if (id) {
      getMyJobs();
      getMyRequests();
    }
  }, []);

  return (
    <>
      <HomeNavigationHeader isPrimary primaryText="My Jobs" />

      {/* TABS */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        sx={{borderBottom: `1px solid ${theme.palette.primary.main}`}}>
        <Tab label="Client" {...a11yProps(0)} />
        <Tab label="Helper" {...a11yProps(1)} />
      </Tabs>

      {/* CLIENT */}
      <CustomTabPanel value={value} index={0}>
        <Box textAlign={"right"} mb={2}>
          <Button
            variant="outlined"
            sx={{marginLeft: "auto", textTransform: "none"}}
            onClick={() => navigate("/m/post-job")}>
            {t("Add New Job")}
            <PlusIcon style={{marginLeft: 8, height: 16, width: 16}} />
          </Button>
        </Box>
        {isLoading ? (
          <>
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
          </>
        ) : null}
        {!isLoading &&
          jobs.map((job, i) => {
            return (
              <ClientCard
                job={job}
                onClickClient={() => {
                  JobServices.UpdateJob(job.id, {isNotified: true});
                  navigate(`/m/job-details?job_id=${job.id}`);
                }}
                key={i}
              />
            );
          })}
        {!isLoading && !jobs.length ? (
          <Typography textAlign={"center"} marginTop={10}>
            {t("No job created yet.")}
          </Typography>
        ) : null}
      </CustomTabPanel>

      {/* HELPER */}
      <CustomTabPanel value={value} index={1}>
        {isLoading2 ? (
          <>
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={94} sx={{borderRadius: 2.5, marginBottom: 2}} />
          </>
        ) : null}
        {!isLoading2 &&
          requests.map(request => {
            return (
              <HelperCard
                key={request.id}
                request={request}
                onClickHelper={() =>
                  navigate(`/m/job-request-details?request_id=${request.id}`, {
                    state: {_id: request.id, ...request},
                  })
                }
              />
            );
          })}
        {!isLoading && !requests.length ? (
          <Typography textAlign={"center"} marginTop={10}>
            {t("No pending request yet.")}
          </Typography>
        ) : null}
      </CustomTabPanel>
    </>
  );
};

export default MMyJobs;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{paddingY: 2}}>{children}</Box>}
    </div>
  );
}
