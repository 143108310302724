import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISignUpResponse, IUserData } from "../../interfaces/IUser";

const initialState: IUserData = {
  id: "",
  name: "",
  email: "",
  phone: "",
  address: "",
  fcm: "",
  profile: "",
  description: "",
  access_token: "",
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<ISignUpResponse>) => {
      const { id, email, name, phone, fcm, access_token, profile, address, description } = payload;
      return state = { id, email, name, phone, fcm, address, description: description || "", access_token, profile };
    },
    updateUser: (state, { payload }) => {
      return state = { ...state, ...payload };
    },
    removeUser: (state) => {
      localStorage.removeItem("access_token");
      localStorage.removeItem("userId");
      return state = initialState;
    }
  }
});

// ACTIONS
export const { setUser, updateUser, removeUser } = UserSlice.actions;

export default UserSlice;
