// UI
import {Box} from "@mui/material";
import LeftBanner from "../../../components/Desktop/LeftBanner";
import {TOP_RIGHT_BLOB, TNC_AUTH} from "../../../assets/Images";
import AuthHeader from "../Register/AuthHeader";
import TermsNConditionsText from "../../../components/Desktop/TermsNConditionsText/TermsNConditionsText";

const TermsNConditions = () => {
  return (
    <>
      <Box sx={{display: "flex"}}>
        <LeftBanner imageOnTop />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <AuthHeader headline={TNC_AUTH} />

          <Box paddingX={10}>
            <TermsNConditionsText />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TermsNConditions;
