import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import TermsNConditionsText from "../../../components/Desktop/TermsNConditionsText/TermsNConditionsText";

const MTermsNConditions = () => {
  const {t} = useTranslation();

  return (
    <>
      <Box sx={{paddingX: 2}}>
        <HomeNavigationHeader isPrimary={false} screenLabel={t("Terms & Conditions")} />

        <TermsNConditionsText />
      </Box>
    </>
  );
};

export default MTermsNConditions;
