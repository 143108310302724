import AppInstance from "../config/global.axios";
import { ILoginForm, ISignUpAPI } from "../interfaces/IUser";
import { IUserEditForm } from "../pages/Mobile/EditProfile";

// SIGN UP
async function SignUp(user: ISignUpAPI) {
  const response = await AppInstance({
    url: "/auth/sign-up",
    method: "POST",
    data: user
  });
  return response;
}

// SIGN IN
async function SignIn(user: ILoginForm) {
  const response = await AppInstance({
    url: "/auth/sign-in",
    method: "POST",
    data: user
  });
  return response;
}

// SEND OTP TO EMAIL/PHONE NO
async function SendOTP(type: "email" | "phone", payLoad: { email?: string, phone?: string }) {
  const response = await AppInstance({
    url: `/auth/send-otp?mode=${type}`,
    method: "POST",
    data: payLoad
  });
  return response;
}

// VERIFY OTP FOR EMAIL/PHONE NO
async function VerifyOTP(type: "email" | "phone", payLoad: { email?: string, phone?: string, otp: string }) {
  const response = await AppInstance({
    url: `/auth/verify-otp?mode=${type}`,
    method: "POST",
    data: payLoad
  });
  return response;
}

// VERIFY EMAIL
async function ResetPassword(payLoad: { email: string, password: string, confirmPassword: string }) {
  const response = await AppInstance({
    url: "/auth/reset-password",
    method: "POST",
    data: payLoad
  });
  return response;
}

// GET ME
async function GetMe() {
  const response = await AppInstance({
    url: "/auth/get-me",
    method: "GET"
  });
  return response;
}

// UPDATE ME
async function UpdateMe(payLoad: Partial<IUserEditForm>) {
  const response = await AppInstance({
    url: "/users/update-me",
    method: "PATCH",
    data: payLoad
  });
  return response;
}

const AuthServices = {
  SignUp,
  SignIn,
  SendOTP,
  VerifyOTP,
  ResetPassword,
  GetMe,
  UpdateMe
}

export default AuthServices;