import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const PrivacyPolicyText = () => {
  const {t} = useTranslation();

  return (
    <>
      <h2 style={{marginBottom: 20}}>
        <strong>{t("Privacy Policy")}</strong>
      </h2>
      <p style={{marginBottom: 20}}>
        {t(
          `We take the protection of your personal data very seriously and treat your personal data confidentially and in accordance with statutory data protection regulations as well as this privacy policy. Below, we inform you about which data we process and for what purpose.`
        )}
      </p>
      <h3 id="1-responsible-party" style={{marginBottom: 12}}>
        {t("1. Responsible Party")}
      </h3>
      <p style={{marginBottom: 20}}>
        {t("The responsible party for data processing on this website is:")}
        <br />
        Saci Khan
        <br />
        khan@helper2go.ch
      </p>
      <h3 id="2-processing-of-personal-data" style={{marginBottom: 12}}>
        {t("2. Processing of Personal Data")}
      </h3>
      <h4 id="a-type-of-data" style={{marginBottom: 12}}>
        {t("a) Type of Data")}
      </h4>
      <p style={{marginBottom: 12}}>
        {t("For the creation of your invoice, we process the following personal data:")}
      </p>
      <ul style={{marginLeft: 30, marginBottom: 20}}>
        <li>{t("Name")}</li>
        <li>{t("Address")}</li>
        <li>{t("Invoice information (e.g., items, prices, payment information)")}</li>
      </ul>
      <h4 id="b-purpose-of-data-processing" style={{marginBottom: 12}}>
        {t("b) Purpose of Data Processing")}
      </h4>
      <p style={{marginBottom: 20}}>
        {t(
          `The processing of your personal data is solely for the purpose of creating and providing the invoice. Your data will not be used for advertising purposes or any other purposes.`
        )}
      </p>
      <h4 id="c-legal-basis" style={{marginBottom: 12}}>
        {t("c) Legal Basis")}
      </h4>
      <p style={{marginBottom: 20}}>
        {t(
          "The processing of your data is based on Art. 6(1)(b) GDPR, which permits the processing of data to fulfill a contract or pre-contractual measures."
        )}
      </p>
      <h3 id="3-no-data-storage" style={{marginBottom: 6}}>
        {t("3. No Data Storage")}
      </h3>
      <p style={{marginBottom: 20}}>
        {t(
          "We do not store any personal data. All data is deleted immediately after the invoice is generated. There is no storage or further processing of the data."
        )}
      </p>
      <h3 id="4-use-of-cookies" style={{marginBottom: 6}}>
        {t("4. Use of Cookies")}
      </h3>
      <h4 id="a-necessary-cookies" style={{marginBottom: 6}}>
        {t("a) Necessary Cookies")}
      </h4>
      <p style={{marginBottom: 20}}>
        {t(
          "Our web app uses only necessary cookies that are essential for the operation of the website and the provision of our services. These cookies do not collect personal data and do not require user consent."
        )}
      </p>
      <h4 id="b-no-additional-cookies" style={{marginBottom: 6}}>
        {t("b) No Additional Cookies")}
      </h4>
      <p style={{marginBottom: 20}}>
        {t(
          "Apart from the necessary cookies, no additional cookies are used. In particular, we do not use tracking or advertising cookies."
        )}
      </p>
      <h3 id="5-your-rights" style={{marginBottom: 6}}>
        {t("5. Your Rights")}
      </h3>
      <p style={{marginBottom: 6}}>{t("You have the right to:")}</p>
      <ul style={{marginLeft: 30, marginBottom: 6}}>
        <li style={{marginBottom: 6}}>
          <strong>{t("Access")}</strong> {t("the personal data we process about you (Art. 15 GDPR).")}
        </li>
        <li style={{marginBottom: 6}}>
          <strong>{t("Rectification")}</strong>{" "}
          {t("of incorrect data or completion of incomplete data (Art. 16 GDPR).")}
        </li>
        <li style={{marginBottom: 6}}>
          <strong>{t("Erasure")}</strong>{" "}
          {t(
            `of your personal data stored by us, unless the processing is necessary to comply with a legal obligation (Art. 17 GDPR).`
          )}
        </li>
        <li style={{marginBottom: 6}}>
          <strong>{t("Restriction of Processing")}</strong> {t("of your personal data (Art. 18 GDPR).")}
        </li>
        <li style={{marginBottom: 6}}>
          <strong>{t("Object")}</strong> {t("to the processing of your personal data (Art. 21 GDPR).")}
        </li>
        <li style={{marginBottom: 6}}>
          <strong>{t("Data Portability")}</strong> {t("(Art. 20 GDPR).")}
        </li>
      </ul>
      <p style={{marginBottom: 20}}>
        {t(
          "If you wish to exercise any of these rights, please contact us using the contact details provided above."
        )}
      </p>
      <h3 id="6-data-security" style={{marginBottom: 6}}>
        {t("6. Data Security")}
      </h3>
      <p style={{marginBottom: 20}}>
        {t(
          "We implement technical and organizational security measures to protect your data against accidental or intentional manipulation, partial or complete loss, destruction, or unauthorized access by third parties. Our security measures are continuously improved in line with technological developments."
        )}
      </p>
      <h3 id="7-validity-and-changes-to-this-privacy-policy" style={{marginBottom: 6}}>
        {t("7. Validity and Changes to this Privacy Policy")}
      </h3>
      <p style={{marginBottom: 20}}>
        {t(
          "This privacy policy is currently valid as of June 2024. Due to the further development of our website or changes in legal or regulatory requirements, it may be necessary to amend this privacy policy. The current privacy policy can be accessed and printed at any time on our website at "
        )}
        <Link to={"/privacy-policy"} target="_blank">
          Link
        </Link>
        .
      </p>
    </>
  );
};

export default PrivacyPolicyText;
