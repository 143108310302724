import {FC} from "react";
import {Box, Button, CircularProgress, Modal, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";
import {useTranslation} from "react-i18next";
import {checkDeviceType} from "../../../utils/AppUtils";

interface Props {
  open: boolean;
  onCancel: () => void;
  onDelete: () => void;
  deleteLoading: boolean;
}

const DeleteJobConfirmModal: FC<Props> = ({onCancel, onDelete, open, deleteLoading}) => {
  const {t} = useTranslation();

  return (
    <>
      <Modal
        open={open}
        onClose={onCancel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        sx={{
          height: "100vh",
          overflowY: "scroll",
          display: "grid",
          placeItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            marginY: 3,
            width: checkDeviceType() === "M" ? "70%" : "20%",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}>
          {/* HEADER */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}>
            <Typography variant="h5" fontWeight={600} color={theme.palette.error.main}>
              {t("Delete")}?
            </Typography>
            <div
              style={{height: 1, width: "100%", backgroundColor: theme.palette.grey[400], margin: "12px 0"}}
            />
            <Typography>{t("Are you sure to delete this miniJob?")}</Typography>

            {/* ACTION BUTTONS */}
            <Box mt={2} display={"flex"}>
              <Button size="medium" variant="contained" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                size="medium"
                variant="contained"
                color={"error"}
                sx={{marginLeft: 4}}
                onClick={onDelete}
                disabled={deleteLoading}>
                Delete
                {deleteLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteJobConfirmModal;
