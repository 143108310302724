// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import AuthHeader from "../Register/AuthHeader";

// SERVICES & UTILS
import AuthServices from "../../../services/AuthServices";
import {theme} from "../../../styles/theme";
import {AxiosError} from "axios";
import Joi from "joi";

// ASSSETS & ICONS
import {ALRIGHT_AUTH, TOP_RIGHT_BLOB} from "../../../assets/Images";
import {ReactComponent as EmailIcon} from "../../../assets/Icons/email.svg";

const verifyEmailSchema = Joi.object({
  email: Joi.string()
    .email({tlds: {allow: false}})
    .empty()
    .required()
    .messages({
      "string.base": "Email is required.",
      "string.required": "Email is required.",
      "string.empty": "Email is required.",
      "string.email": "Must be a valid email.",
    }),
});

interface FormData {
  email: string;
}

const ForgotPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors, isDirty, isValid},
    control,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
    resolver: joiResolver(verifyEmailSchema),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {email} = formData;

    try {
      const resp = await AuthServices.SendOTP("email", {email});

      // SUCCESS
      if (resp.status === 200) {
        setLoading(false);
        enqueueSnackbar(t("OTP is sent to your email."), {variant: "info"});
        navigate("/verify-otp?verification_channel=email&verification_path=resetPassword", {
          state: {email},
        });
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;

      // EMAIL NOT FOUND
      if (err.response?.status === 404) {
        enqueueSnackbar(t("This email does not exist."), {variant: "error"});
      }

      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <AuthHeader title={t("Enter your Email to continue")} headline={ALRIGHT_AUTH} />

          <Box py={6} px={10} pr={20}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* EMAIL */}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="email"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="email"
                        size="medium"
                        variant="outlined"
                        type="email"
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon fill={theme.palette.grey[400]} />
                            </InputAdornment>
                          ),
                        }}
                        error={errors?.email ? true : false}
                        helperText={t(errors?.email?.message || "")}
                        placeholder="Email"
                        sx={{mb: 2}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* SUBMIT BUTTON - CREATE ACCOUNT */}
              <FormControl sx={{display: "block", marginTop: 15}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                  disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                  {t("Continue")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>

            {/* GREY TEXT */}
            <Box mt={14} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <Typography
                px={4}
                variant="body2"
                fontWeight={500}
                color={theme.palette.grey[400]}
                mr={1}
                textAlign={"center"}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae quaerat maiores consequuntur
                nostrum ex aut debitis saepe provident soluta a!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
