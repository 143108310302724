// HOOKS
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

// UI
import {Box, Button, Link, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";

// SERVICE & UTILS
import {isLoggedIn} from "../../../utils/AuthUtils";

// ASSETS & ICONS
import {HANDS_FOOTER, LINES_FOOTER, LOGO_FOOTER} from "../../../assets/Images";
import {ReactComponent as LinkedInIcon} from "../../../assets/Icons/linked-in.svg";
import {ReactComponent as InstagramIcon} from "../../../assets/Icons/instagram.svg";
import {ReactComponent as FacebookIcon} from "../../../assets/Icons/facebook.svg";
import {ReactComponent as TikTokIcon} from "../../../assets/Icons/tiktok.svg";
// import {ReactComponent as TwitterXIcon} from "../../../assets/Icons/twitter-x.svg";

const Footer = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <>
      <Box
        paddingX={10}
        paddingY={7}
        bgcolor={theme.palette.primary.main}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundImage: `url(${LINES_FOOTER})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "inherit",
          position: "relative",
          overflow: "hidden",
        }}>
        <img
          src={HANDS_FOOTER}
          alt="logo-hands"
          style={{position: "absolute", top: "50%", right: "-60px", translate: "0 -50%", height: 250}}
        />
        <Box>
          <img src={LOGO_FOOTER} alt="footer-logo" width={300} />

          {/* SOCIAL ICONS */}
          <Box sx={{display: "flex", marginTop: 4}}>
            {/* LINKED IN */}
            <Link
              href="https://www.linkedin.com/company/helper2go-schweiz/"
              target="_blank"
              sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <LinkedInIcon />
              <Typography fontWeight={300} marginLeft={1} color={theme.palette.common.white}>
                LinkedIn
              </Typography>
            </Link>

            {/* FACEBOOK */}
            <Link
              href="https://www.facebook.com/profile.php?id=61558931969385"
              target="_blank"
              sx={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 4}}>
              <FacebookIcon />
              <Typography fontWeight={300} marginLeft={1} color={theme.palette.common.white}>
                Facebook
              </Typography>
            </Link>

            {/* INSTAGRAM */}
            <Link
              href="https://www.instagram.com/helper2go.company?igsh=MThxMjJhMGh5Ymw5dg=="
              target="_blank"
              sx={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 4}}>
              <InstagramIcon height={30} width={30} />
              <Typography fontWeight={300} marginLeft={1} color={theme.palette.common.white}>
                Instgram
              </Typography>
            </Link>

            {/* TIKTOK */}
            <Link
              href="https://www.tiktok.com/@helper2go?_t=8mMgl2nUezQ&_r=1"
              target="_blank"
              sx={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 4}}>
              <TikTokIcon height={30} width={30} />
              <Typography fontWeight={300} marginLeft={1} color={theme.palette.common.white}>
                TikTok
              </Typography>
            </Link>
          </Box>
        </Box>

        {/* LINKS */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "right",
            color: theme.palette.common.white,
            marginRight: 15,
          }}>
          <Typography mb={2} mt={5} textTransform={"uppercase"}>
            consectetur adipiscing
          </Typography>
          {/* <Typography mb={2} textTransform={"uppercase"}>
            {t("About Us")}
          </Typography> */}
          {/* <Typography mb={2} textTransform={"uppercase"}>
            {t("How it works")}
          </Typography> */}
          {/* <Typography mb={2} textTransform={"uppercase"}>
            {t("Contact Us")}
          </Typography> */}
          <Typography
            sx={{mb: 2, textTransform: "uppercase", cursor: "pointer"}}
            onClick={() => navigate("/terms-and-conditions")}>
            {t("Terms & Conditions")}
          </Typography>
          <Typography
            sx={{mb: 2, textTransform: "uppercase", cursor: "pointer"}}
            onClick={() => navigate("/privacy-policy")}>
            {t("Privacy Policy")}
          </Typography>
          {!isLoggedIn() ? (
            <Button
              id="get-started-footer"
              variant="outlined"
              sx={{
                borderRadius: 2.5,
                backgroundColor: theme.palette.common.white,
                fontWeight: 600,
                marginTop: 2,
              }}
              onClick={() => navigate("/register")}>
              {t("Get Started")}
            </Button>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default Footer;
