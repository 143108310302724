import AppInstance from "../config/global.axios";
import { ICounterOfferPayload, IRequestAPI, IRequestFilters, IRequestPayload } from "../interfaces/IRequest";

interface IRequestPayloadAPI extends Omit<IRequestPayload, "budget" | "job"> {
  budget?: number;
  client?: string;
  job?: string;
  helper: string;
  note: string;
  budgetNote: string;
}

// CREATE REQUEST - APPLY AS HELPER
async function CreateRequest(request: IRequestPayloadAPI) {
  const response = await AppInstance({
    url: "/requests/new-request",
    method: "POST",
    data: request
  });
  return response;
}

// GET REQUESTS (WITH FILTERS)
async function GetRequestsWithFilters(filters: IRequestFilters) {
  const response = await AppInstance({
    url: `/requests`,
    method: "POST",
    data: filters
  });
  return response;
}

// GET REQUESTS OF A JOB
async function GetRequestOfJob(jobId?: string, client?: string, helper?: string) {
  const response = await AppInstance({
    url: `/requests/get-requests/${jobId}`,
    method: "POST",
    data: { client, helper }
  });
  return response;
}

// ADD COUNTER OFFER
async function AddCounterOffer(requestId?: string, counterOffer?: ICounterOfferPayload) {
  const response = await AppInstance({
    url: `requests/${requestId}/add-offer`,
    method: "POST",
    data: counterOffer
  });
  return response;
}

async function UpdateRequest(requestId?: string, requestPayload?: Partial<IRequestAPI>) {
  const response = await AppInstance({
    url: `/requests/${requestId}`,
    method: "PATCH",
    data: requestPayload
  });
  return response;
}

const RequestServices = {
  CreateRequest,
  GetRequestsWithFilters,
  GetRequestOfJob,
  AddCounterOffer,
  UpdateRequest
}

export default RequestServices;