import {FC} from "react";
import {Box, Button, CircularProgress} from "@mui/material";
import {useTranslation} from "react-i18next";

interface Props {
  isLoading: boolean;
  declineLoad: boolean;
  acceptLoad: boolean;
  onAccept: () => void;
  onDecline: () => void;
}

const MCounterOfferActionButtons: FC<Props> = ({onAccept, onDecline, isLoading, acceptLoad, declineLoad}) => {
  const {t} = useTranslation();

  return (
    <>
      <Box sx={{display: "flex", gap: 4, marginTop: 4, marginBottom: 2}}>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          disabled={isLoading}
          sx={{
            fontSize: 14,
            borderRadius: 2.5,
            py: 2,
            flexBasis: "50%",
            textTransform: "none",
            fontWeight: 600,
            boxShadow: `4px 8px 24px 0px rgba(0, 0, 0, 0.25)`,
          }}
          onClick={onDecline}>
          {t("Decline")}
          {declineLoad ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
        </Button>
        <Button
          variant="contained"
          type="submit"
          onClick={onAccept}
          disabled={isLoading}
          sx={{
            fontSize: 14,
            borderRadius: 2.5,
            py: 2,
            flexBasis: "50%",
            textTransform: "none",
            fontWeight: 600,
            boxShadow: `4px 8px 24px 0px rgba(0, 0, 0, 0.25)`,
          }}>
          {t("Accept")}
          {acceptLoad ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
        </Button>
      </Box>
    </>
  );
};

export default MCounterOfferActionButtons;
