// HOOKS
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// UI
import {Box, Button} from "@mui/material";
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";

// ASSETS & ICONS
import {ReactComponent as ChevronRightIcon} from "../../../assets/Icons/chevron-right-circled.svg";
import {JOBS_EVERYWHERE_HOME, HELPER_EVERYWHERE_HOME} from "../../../assets/Images";
import HomeNavigationHeader from "./HomeNavigationHeader";

const MHome = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <>
      <HomeNavigationHeader isPrimary primaryText={t("Welcome!")} showNotification />
      <HomeCard
        imageSrc={JOBS_EVERYWHERE_HOME}
        btnLabel={t("Looking for miniJob")}
        onBtnPress={() => {
          navigate("/m/search");
        }}
      />
      <HomeCard
        imageSrc={HELPER_EVERYWHERE_HOME}
        btnLabel={t("Looking for Helper")}
        onBtnPress={() => {
          navigate("/m/post-job");
        }}
      />
    </>
  );
};

export default MHome;

interface HomeCardProps {
  imageSrc: string;
  btnLabel: string;
  onBtnPress: () => void;
}

const HomeCard: FC<HomeCardProps> = ({imageSrc, btnLabel, onBtnPress}) => {
  return (
    <>
      <Box sx={{borderRadius: 2.5, overflow: "hidden", marginBottom: 3}}>
        <BoxPrimaryGradient>
          <Box
            sx={{
              padding: 2,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <img
              src={imageSrc}
              alt="jobs-everywhere"
              style={{
                minHeight: 100,
                maxHeight: "calc(50vh - 200px)",
                minWidth: 150,
                objectFit: "cover",
                display: "inline-block",
              }}
            />
            <Button
              sx={{textTransform: "none", borderRadius: 2.5, fontWeight: 400, marginTop: 2}}
              variant="contained"
              onClick={onBtnPress}>
              {btnLabel}
              <ChevronRightIcon style={{marginLeft: 8, height: 16, width: 16}} />
            </Button>
          </Box>
        </BoxPrimaryGradient>
      </Box>
    </>
  );
};
