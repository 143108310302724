// HOOKS
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate} from "react-router";
import AuthServices from "../../../../services/AuthServices";
import {useSnackbar} from "notistack";
import {useAppDispatch} from "../../../../store";
import {useTranslation} from "react-i18next";

// SERVICES & UTILS
import {ILoginForm, ISignUpResponse} from "../../../../interfaces/IUser";
import {app} from "../../../../config/global.firebase";
import {setUser} from "../../../../store/Slices/UserSlice";
import {theme} from "../../../../styles/theme";
import {getMessaging, getToken, isSupported} from "firebase/messaging";
import {loginUser} from "./schema";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AuthHeader from "../../Register/AuthHeader";

// ASSETS & ICONS
import {HELLO_AUTH, TOP_RIGHT_BLOB} from "../../../../assets/Images";
import {ReactComponent as EmailIcon} from "../../../../assets/Icons/email.svg";
import {ReactComponent as LockIcon} from "../../../../assets/Icons/lock.svg";
import {ReactComponent as VisibilityOff} from "../../../../assets/Icons/hide-eye.svg";
import {ReactComponent as Visibility} from "../../../../assets/Icons/eye.svg";
import {AxiosError} from "axios";

const LoginForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [fcm, setFCM] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors, isDirty, isValid},
    control,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onBlur",
    resolver: joiResolver(loginUser),
  });

  // SING IN WITH EMAIL & PASSWORD
  async function sumbitLogin(formData: ILoginForm) {
    const {email, password} = formData;
    setLoading(true);
    try {
      const res = await AuthServices.SignIn({email, password, fcm});

      setLoading(false);
      // SUCCESS
      if (res.status === 200) {
        const userData = res.data as ISignUpResponse;

        // EMAIL NOT VERIFIED YET
        if (!res.data?.isEmailVerified) {
          dispatch(setUser(userData));
          navigate("/verify-email?verification_channel=email&verification_path=account");
          return enqueueSnackbar(t("We need to verify your email."), {
            variant: "success",
          });
        }
        enqueueSnackbar(t("Signed in successfully."), {variant: "success"});
        localStorage.setItem("access_token", userData.access_token);
        dispatch(
          setUser({
            id: userData.id,
            access_token: userData.access_token,
            email: userData.email,
            fcm: userData.fcm,
            isEmailVerified: userData.isEmailVerified,
            isPhoneVerified: userData.isPhoneVerified,
            name: userData.name,
            phone: userData.phone,
            profile: userData.profile,
            address: userData.address,
          })
        );
        return navigate("/");
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      console.log(error);
      // INVALID CREDENTIALS
      if (err.response?.status === 401) {
        enqueueSnackbar(t("Invalid email or password."), {variant: "error"});
      }
      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  async function getPermission() {
    if (await isSupported()) {
      const messaging = getMessaging(app);
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const tokenFCM = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH,
        });
        setFCM(tokenFCM);
      } else if (permission === "denied") {
        alert("You've denied the notifications.");
      }
    }
  }

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${TOP_RIGHT_BLOB})`,
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          width: "55%",
          minHeight: "100vh",
          backgroundSize: "300px",
        }}>
        <AuthHeader title={t("Login in to your account")} headline={HELLO_AUTH} />

        <Box py={6} px={10} pr={20}>
          <form onSubmit={handleSubmit(sumbitLogin)}>
            {/* EMAIL */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="email"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="email"
                      size="medium"
                      variant="outlined"
                      type="email"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.email ? true : false}
                      helperText={t(errors?.email?.message || "")}
                      placeholder="Email"
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* PASSWORD */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="password"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="password"
                      size="medium"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.password ? true : false}
                      helperText={t(errors?.password?.message || "")}
                      placeholder="Password"
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* FORGOT PASSWORD */}
            <Box display={"flex"} justifyContent={"end"}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => navigate("/forgot-password")}>
                {t("Forgot Password?")}
              </Typography>
            </Box>

            {/* SUBMIT BUTTON - LOGIN */}
            <FormControl sx={{display: "block", marginTop: 15}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                {t("Login")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>

          {/* ALREADY HAVE AN ACCOUNT */}
          <Box mt={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="body2" fontWeight={500} color={theme.palette.grey[400]} mr={1}>
              {t("Don't have an account?")}
            </Typography>
            <Button variant="contained" sx={{px: 2, borderRadius: 2.5}} onClick={() => navigate("/register")}>
              {t("Create Account")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LoginForm;
