// HOOKS
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSwipeable} from "react-swipeable";
import {useAppSelector, useAppDispatch} from "../../../store";
import {useSnackbar} from "notistack";

// UI
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import MSplitText from "../../../components/Mobile/MSplitText";
import {Avatar, Box, Container, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import DeleteJobConfirmModal from "../../Desktop/MyJobs/DeleteJobConfirmModal";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import RequestServices from "../../../services/RequestServices";
import {IRequestAPI} from "../../../interfaces/IRequest";
import {IJob} from "../../../interfaces/IJob";
import "../JobDetails/job-details.css";
import {AxiosError} from "axios";
import moment from "moment";

// ASSETS & ICONS
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import {ReactComponent as RightSwipeIcon} from "../../../assets/Icons/right-swipe.svg";
import {ReactComponent as HorizontalDotsIcon} from "../../../assets/Icons/horizontal-dots.svg";
import JobServices from "../../../services/JobServices";
import {removeJob} from "../../../store/Slices/JobsSlice";

const MJobDetails = () => {
  const jobs = useAppSelector(state => state.jobs);
  const [showDetails, setShowDetails] = useState(true);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const job_id = searchParams.get("job_id");
  const jobDetails = jobs.find(item => item.id === job_id);
  const [jobRequests, setJobRequests] = useState<IRequestAPI[]>([]);

  // DELETE JOB MODAL HANDELERS
  const [delLoading, setDelLoading] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const handleDelOpen = () => {
    handleMenuClose();
    setOpenDel(true);
  };
  const handleDelClose = () => {
    setOpenDel(false);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setShowDetails(false),
    onSwipedRight: () => setShowDetails(true),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  async function getRequestsOfJob() {
    try {
      const resp = await RequestServices.GetRequestOfJob(jobDetails?.id, jobDetails?.client);
      const arrOfRequests = resp.data as IRequestAPI[];
      setJobRequests(arrOfRequests);
    } catch (error) {
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  // DELETE THIS JOB
  async function deleteThisJob() {
    setDelLoading(true);
    try {
      const resp = await JobServices.DeleteJob(jobDetails?.id as string);
      // SUCCESS
      if (resp.status === 200) {
        enqueueSnackbar(t("Job deleted successfully."), {variant: "success"});
        setOpenDel(false);
        dispatch(removeJob(jobDetails as IJob));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDelLoading(false);
      navigate("/m/my-jobs");
    }
  }

  // HANDLE EDIT JOB
  function handleEditJob() {
    navigate(`/m/post-job?job_id=${jobDetails?.id}`, {state: jobDetails});
  }

  useEffect(() => {
    getRequestsOfJob();
  }, []);

  return (
    <>
      <HomeNavigationHeader
        isPrimary={false}
        screenLabel={showDetails ? t("Job Details") : t("Requests")}
        CustomHeaderBtn={
          <>
            <IconButton sx={{marginLeft: "auto"}} onClick={handleMenuClick}>
              <HorizontalDotsIcon fill={"#000"} />
            </IconButton>
            {/* DROPDOWN MENU */}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              sx={{
                marginTop: 1,
                "& .MuiPaper-root": {
                  borderRadius: 5,
                },
                textAlign: "center",
              }}>
              <MenuItem
                onClick={handleEditJob}
                sx={{
                  paddingY: 0,
                  minHeight: "30px !important",
                  width: 120,
                }}>
                {t("Edit")}
              </MenuItem>
              <MenuItem
                onClick={handleDelOpen}
                sx={{color: theme.palette.error.dark, paddingY: 0, minHeight: "30px !important"}}>
                {t("Delete")}
              </MenuItem>
            </Menu>
          </>
        }
      />

      <div {...handlers} className="swipeable-spring-container" style={{minHeight: "calc(100vh - 180px)"}}>
        {showDetails ? <JobDetailsLayout job={jobDetails} /> : <JobRequestsLayout requests={jobRequests} />}
      </div>

      {/* SIDE INDICATOR */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: 40,
          left: "50%",
          translate: "-50% 0",
          zIndex: 100,
          backgroundColor: theme.palette.primary.main,
          padding: 1,
          paddingX: 2,
          borderRadius: 3,
          border: "1px solid white",
        }}>
        <div className={showDetails ? "side active" : "side"}></div>
        <div className={!showDetails ? "side active" : "side"}></div>
      </Box>

      {/* DELETE JOB MODAL */}
      <DeleteJobConfirmModal
        onCancel={handleDelClose}
        onDelete={deleteThisJob}
        open={openDel}
        deleteLoading={delLoading}
      />
    </>
  );
};

export default MJobDetails;

interface JobDetailsLayoutProps {
  job?: IJob;
}
const JobDetailsLayout: FC<JobDetailsLayoutProps> = ({job}) => {
  const {t} = useTranslation();

  return (
    <>
      <div className="details-container">
        {/* JOB TITLE */}
        <Box
          sx={{
            borderRadius: 2.5,
            overflow: "hidden",
            color: theme.palette.common.white,
            position: "relative",
          }}>
          <BoxPrimaryGradient>
            <Box sx={{padding: 2}}>
              <Typography marginY={1}>{job?.title}</Typography>
            </Box>
            <Box sx={{display: "flex", alignItems: "center", position: "absolute", top: 10, right: 10}}>
              <ClockIcon style={{width: 12, height: 12}} fill="#fff" />
              <Typography variant="caption" sx={{marginLeft: 0.5, fontSize: 10}}>
                {moment(job?.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </Box>
          </BoxPrimaryGradient>
        </Box>

        {/* JOB DETAILS */}
        <Box
          sx={{border: `1px solid ${theme.palette.grey[300]}`, marginTop: 3, borderRadius: 2.5, padding: 2}}>
          {/* <MSplitText title="Timeline" value={job?.timeline} /> */}
          <MSplitText title={t("Budget")} value={job?.budget.toLocaleString()} />
          <MSplitText title={t("Experience")} value={job?.experience} />
          <MSplitText title={t("Required Tools")} value={job?.tools} />
        </Box>

        {/* JOB DESCRIPTION */}
        <Box
          sx={{border: `1px solid ${theme.palette.grey[300]}`, marginTop: 3, borderRadius: 2.5, padding: 2}}>
          <Typography>{t("Description")}</Typography>
          <Typography fontSize={14} whiteSpace={"pre-wrap"}>
            {job?.description}
          </Typography>
        </Box>
      </div>

      {/* SIDE INDICATOR */}
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 3}}>
        <Typography color={theme.palette.primary.main}>{t("Swipe to see all requests")}</Typography>
        <span className="swipe-icons">
          <RightSwipeIcon className="first icon" />
          <RightSwipeIcon className="second icon" />
        </span>
      </Box>
    </>
  );
};

const JobRequestsLayout: FC<{requests: IRequestAPI[]}> = ({requests}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const pendingRequests = requests.filter(item => item.status === "PENDING");
  const approvedRequests = requests.filter(item => item.status === "APPROVED");

  return (
    <>
      {/* PENDING REQUESTS */}
      <div className="requests-container">
        <Container disableGutters>
          {/* PENDING REQUESTS */}
          <Typography
            variant="body1"
            marginBottom={2}
            fontSize={18}
            fontWeight={600}
            textTransform={"capitalize"}>
            {t("Pending Requests")}
          </Typography>
          {pendingRequests.length ? (
            pendingRequests.map(request => {
              return (
                <RequestCard
                  request={request}
                  key={request._id}
                  onClickDetails={() =>
                    navigate(`/m/job-request-details?request_id=${request._id}`, {state: request})
                  }
                />
              );
            })
          ) : (
            <Typography variant="body1" marginBottom={2} textAlign={"center"} color={theme.palette.grey[500]}>
              {t("No pending request yet.")}
            </Typography>
          )}

          {/* ACCEPTED REQUESTS */}
          <Typography variant="body1" marginBottom={2} fontSize={18} fontWeight={600}>
            {t("Accepted Requests")}
          </Typography>
          {approvedRequests.length ? (
            approvedRequests.map(request => {
              return (
                <RequestCard
                  request={request}
                  key={request._id}
                  onClickDetails={() =>
                    navigate(`/m/job-request-details?request_id=${request._id}`, {state: request})
                  }
                />
              );
            })
          ) : (
            <Typography variant="body1" marginBottom={2} textAlign={"center"} color={theme.palette.grey[500]}>
              {t("No accepted request yet.")}
            </Typography>
          )}
        </Container>
      </div>
    </>
  );
};

interface RequestCardProps {
  onClickDetails: () => void;
  request: IRequestAPI;
}

const RequestCard: FC<RequestCardProps> = ({onClickDetails, request}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          padding: 2,
          borderRadius: 2.5,
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}>
        <Avatar sx={{width: 60, height: 60}} src={request.helper.profile}>
          {request.helper.name.split("")[0]}
        </Avatar>
        <Box marginLeft={2} flexGrow={1}>
          <Typography variant="body2" fontWeight={300} className="truncate-1">
            {request.note}
          </Typography>
          <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 1}}>
            <Typography variant="caption" fontWeight={300}>
              {moment(request.createdAt).fromNow()}
            </Typography>
            <Typography
              variant="caption"
              fontWeight={300}
              sx={{display: "flex", justifyContent: "center", alignItems: "center"}}
              onClick={onClickDetails}>
              See details
              {/* <ChevronRightIcon style={{stroke: "white", height: 10, width: 10, marginLeft: 8}} /> */}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
