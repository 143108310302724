// HOOKS
import {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../store";
import {useNotification} from "../../../hooks/useNotifications";

// UI
import {AppBar, Box, Button, Container, Typography, Avatar, Menu, MenuItem} from "@mui/material";
import BoxPrimaryGradient from "../BoxPrimaryGradient";
import {HashLink} from "react-router-hash-link";

// UTILS
import {isLoggedIn} from "../../../utils/AuthUtils";
import {i18next} from "../../../i18n/i18n";

// ASSETS
import {LOGO_FULL} from "../../../assets/Images";
import {theme} from "../../../styles/theme";
import {ReactComponent as ProfileIcon} from "../../../assets/Icons/profile.svg";
import {ReactComponent as LogoutIcon} from "../../../assets/Icons/logout.svg";
import {enqueueSnackbar} from "notistack";
import {ReactComponent as LanguagesIcon} from "../../../assets/Icons/languages.svg";
import {removeUser} from "../../../store/Slices/UserSlice";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {addNotification, clearNotification} = useNotification();

  const {name, profile} = useAppSelector(state => state.user);
  const jobs = useAppSelector(state => state.jobs);
  const [currentLang, setCurrentLang] = useState<"en" | "de">(localStorage.getItem("lang") as "en" | "de");

  // DROPDOWN MENU - PROFILE
  const [anchorElProfile, setAnchorElPofile] = useState<null | HTMLElement>(null);
  const openProfile = Boolean(anchorElProfile);
  const handleClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPofile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElPofile(null);
  };

  // DROPDOWN MENU - LANGUAGES
  const [anchorElLang, setAnchorElLang] = useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);
  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  function handleLang(lang: "en" | "de") {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    setCurrentLang(lang);
  }
  function getCurrentLang() {
    let selectedLang = localStorage.getItem("lang") as "en" | "de";
    setCurrentLang(selectedLang);
  }

  function activeBG(url: string) {
    if (url === pathname) {
      return isLoggedIn() ? theme.palette.common.white : theme.palette.primary.main;
    }
    return "transparent";
  }

  function activeCLR(url: string) {
    if (url === pathname) {
      return isLoggedIn() ? theme.palette.primary.main : theme.palette.common.white;
    }
    return theme.palette.common.white;
  }

  // LOGOUT USER
  function logOutUser() {
    enqueueSnackbar("Logged out successfully.", {variant: "success"});
    dispatch(removeUser());
    navigate("/");
  }

  // ANY JOB HASN'T NOTIFIED YET
  function isAllJobsNotified() {
    const jobNotNotifiedYet = jobs.find(item => !item.isNotified);
    if (jobNotNotifiedYet) {
      return true;
    } else return false;
  }

  useEffect(() => {
    getCurrentLang();
  }, []);

  useEffect(() => {
    const jobNotNotifiedYet = jobs.find(item => !item.isNotified);
    if (jobNotNotifiedYet) {
      addNotification();
    } else {
      clearNotification();
    }
  }, [jobs]);

  // USER LOGGED IN
  if (isLoggedIn()) {
    return (
      <>
        <BoxPrimaryGradient>
          <AppBar position="static" color="transparent" sx={{boxShadow: "none"}}>
            <Container
              disableGutters
              maxWidth="xl"
              sx={{display: "flex", alignItems: "center", paddingX: 10, paddingY: 2}}>
              <img
                src={LOGO_FULL}
                height={80}
                alt="logo"
                className="cursor-pointer"
                onClick={() => navigate("/")}
              />
              <Box sx={{display: "flex", alignItems: "baseline", marginLeft: "auto", textAlign: "right"}}>
                <Typography
                  sx={{
                    marginX: 2,
                    padding: 0.3,
                    paddingX: 1,
                    borderRadius: 6,
                    cursor: "pointer",
                    textTransform: "uppercase",
                  }}
                  bgcolor={activeBG("/")}
                  color={activeCLR("/")}
                  onClick={() => navigate("/")}>
                  {t("Home")}
                </Typography>

                <Typography
                  sx={{
                    marginX: 2,
                    padding: 0.3,
                    paddingX: 1,
                    borderRadius: 6,
                    cursor: "pointer",
                    textTransform: "uppercase",
                    position: "relative",
                  }}
                  color={activeCLR("/my-jobs")}
                  bgcolor={activeBG("/my-jobs")}
                  onClick={() => navigate("/my-jobs")}>
                  {t("My Jobs")}
                  {isAllJobsNotified() && (
                    <span
                      style={{
                        position: "absolute",
                        backgroundColor: theme.palette.error.main,
                        top: -4,
                        right: -4,
                        width: 12,
                        height: 12,
                        borderRadius: 6,
                      }}
                    />
                  )}
                </Typography>

                <Typography
                  sx={{
                    marginX: 2,
                    padding: 0.3,
                    paddingX: 1,
                    borderRadius: 6,
                    cursor: "pointer",
                    textTransform: "uppercase",
                  }}
                  color={activeCLR("/search")}
                  bgcolor={activeBG("/search")}
                  onClick={() => navigate("/search")}>
                  {t("Search")}
                </Typography>

                <HashLink smooth to={"/#HowItWorks"} style={{textDecoration: "none"}}>
                  <Typography
                    sx={{marginX: 2, textTransform: "uppercase"}}
                    color={theme.palette.common.white}>
                    {t("How It Works")}
                  </Typography>
                </HashLink>
                <HashLink smooth to={"/#AboutUs"} style={{textDecoration: "none"}}>
                  <Typography
                    sx={{marginX: 2, textTransform: "uppercase"}}
                    color={theme.palette.common.white}>
                    {t("About Us")}
                  </Typography>
                </HashLink>
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorElProfile}
                open={openProfile}
                onClose={handleCloseProfile}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                sx={{
                  marginTop: 1,
                  "& .MuiPaper-root": {
                    borderRadius: 2,
                  },
                }}>
                <MenuItem onClick={() => navigate("/profile")}>
                  <ProfileIcon
                    fill={theme.palette.grey[400]}
                    style={{marginRight: 10, height: 24, width: 24}}
                  />
                  {t("Profile")}
                </MenuItem>
                <MenuItem onClick={logOutUser}>
                  <LogoutIcon
                    fill={theme.palette.grey[400]}
                    style={{marginRight: 10, height: 24, width: 24}}
                  />
                  {t("Logout")}
                </MenuItem>
              </Menu>
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleClickProfile}>
                <Typography
                  sx={{
                    marginLeft: 2,
                    marginRight: 2,
                    color: theme.palette.common.white,
                  }}>
                  {name}
                </Typography>
                <Avatar src={profile}>{name.split("")[0]}</Avatar>
              </Button>
            </Container>
          </AppBar>
        </BoxPrimaryGradient>
      </>
    );
  }

  return (
    <>
      <AppBar position="static" color="transparent" sx={{boxShadow: "none"}}>
        <Container
          disableGutters
          maxWidth="xl"
          sx={{display: "flex", alignItems: "center", paddingX: 10, paddingY: 2}}>
          <img
            src={LOGO_FULL}
            height={80}
            alt="logo"
            className="cursor-pointer"
            onClick={() => navigate("/")}
          />
          <Box sx={{display: "flex", alignItems: "baseline", marginLeft: "auto", textAlign: "right"}}>
            <Typography
              sx={{
                marginX: 2,
                padding: 0.3,
                paddingX: 1,
                borderRadius: 6,
                cursor: "pointer",
                textTransform: "uppercase",
              }}
              bgcolor={activeBG("/")}
              color={activeCLR("/")}
              onClick={() => navigate("/")}>
              {t("Home")}
            </Typography>
            <HashLink smooth to={"/#HowItWorks"} style={{textDecoration: "none"}}>
              <Typography sx={{marginX: 2, textTransform: "uppercase"}} color={theme.palette.common.white}>
                {t("How It Works")}
              </Typography>
            </HashLink>
            <HashLink smooth to={"/#AboutUs"} style={{textDecoration: "none"}}>
              <Typography sx={{marginX: 2, textTransform: "uppercase"}} color={theme.palette.common.white}>
                {t("About Us")}
              </Typography>
            </HashLink>
            <Button sx={{display: "flex", alignItems: "center", cursor: "pointer"}} onClick={handleClickLang}>
              <LanguagesIcon width={20} height={20} />
              <Typography ml={1}>
                {currentLang === "de" && "DE"}
                {currentLang === "en" && "EN"}
              </Typography>
            </Button>
          </Box>
          <Button
            variant="contained"
            sx={{borderRadius: 2.5, paddingX: 3, marginLeft: 2}}
            onClick={() => navigate("/register")}>
            {t("Get Started")}
          </Button>
        </Container>
      </AppBar>

      {/* LANGUAGE SELECT DROPDOWN */}
      <Menu
        id="basic-menu"
        anchorEl={anchorElLang}
        open={openLang}
        onClose={handleCloseLang}
        sx={{
          marginTop: 1,
          "& .MuiPaper-root": {
            borderRadius: 5,
          },
          textAlign: "center",
        }}>
        <MenuItem
          onClick={() => handleLang("en")}
          sx={{
            width: 108,
          }}>
          English
        </MenuItem>
        <MenuItem onClick={() => handleLang("de")}>German</MenuItem>
      </Menu>
    </>
  );
};

export default Navbar;
