import {FC} from "react";

// UI
import {Box, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";

// ASSETS & ICONS
import {ReactComponent as BellRedIcon} from "../../../assets/Icons/bell-red.svg";
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import {IJob, IJobOnSearch} from "../../../interfaces/IJob";
import moment from "moment";

interface Props {
  job?: IJob | IJobOnSearch;
  showDetails?: () => void;
  showNotification?: boolean;
}

const Job: FC<Props> = ({showDetails, job, showNotification}) => {
  return (
    <>
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", mb: 2}}>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            padding: 3,
            borderRadius: 2.5,
            width: "80%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={showDetails}>
          <Box>
            <Typography variant="h6" mb={1} color={theme.palette.common.white} className="truncate-1">
              {job?.title}
            </Typography>
            <Typography variant="body2" color={theme.palette.common.white} className="truncate-1">
              {job?.description}
            </Typography>
          </Box>
          {showNotification && !job?.isNotified && (
            <span
              style={{
                position: "absolute",
                backgroundColor: theme.palette.error.main,
                top: -4,
                right: -4,
                width: 12,
                height: 12,
                borderRadius: 6,
              }}
            />
          )}
        </Box>
        <Box sx={{width: "20%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <ClockIcon style={{width: 16, height: 16}} />
          <Typography ml={1} variant="subtitle2">
            {moment(job?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Job;
