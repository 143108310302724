// HOOKS
import {useTranslation} from "react-i18next";

import {Box, Typography} from "@mui/material";
import {LOGO_TEXT} from "../../../assets/Images";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";

const MAboutUs = () => {
  const {t} = useTranslation();

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("About Us")} />
      <Box sx={{paddingX: 1, flexBasis: "50%"}}>
        <img src={LOGO_TEXT} alt="logo-text" style={{marginBottom: 12}} />
        <Typography fontWeight={500} color={"#3B3B3B"} lineHeight={2} fontSize={12} mb={2}>
          {t(
            "Helper2GO is revolutionizing the world of services, sales agreements, and social projects by bringing all these elements together under one roof. The principle here is simple: you have a wish or a need, and someone else fulfills it for you. At the heart of Helper2GO are two key roles: the CLIENT, who posts a miniJob, and the HELPER, who takes on and completes the task."
          )}
        </Typography>
        <Typography fontWeight={500} color={"#3B3B3B"} lineHeight={2} fontSize={12} mb={2}>
          {t(
            "Our vision for the future is to develop Helper2GO into your personal digital assistant – a central hub for all your I need something done needs. With the goal of making Helper2GO more than just a platform; it's the beginning of a movement aimed at redefining how we view services and mutual support. Helper2GO was brought to life by Saçi Khan, right out of Niedergösgen, Switzerland. Helper2GO stands for innovation, community, and a new era of digital connectivity."
          )}
        </Typography>
      </Box>
    </>
  );
};

export default MAboutUs;
