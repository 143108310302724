import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "./styles/theme";
import {BrowserRouter} from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import {i18next} from "./i18n/i18n";
import {SnackbarProvider} from "notistack";
import {Provider} from "react-redux";
import {store} from "./store";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18next}>
          <SnackbarProvider autoHideDuration={3000} anchorOrigin={{horizontal: "center", vertical: "top"}}>
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <App />
              </LocalizationProvider>
            </Provider>
          </SnackbarProvider>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
