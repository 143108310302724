import moment from "moment";

function calculateDuration(start?: string, end?: string) {
  const startOn = moment(start);
  const endOn = moment(end);
  const duration = moment.duration(endOn.diff(startOn));
  return duration.humanize();
}

export {
  calculateDuration
}