// UI
import {Box} from "@mui/material";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import CreateAccount from "./CreateAccount";

const Register = () => {
  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />
        <CreateAccount />
      </Box>
    </>
  );
};

export default Register;
