import {FC} from "react";
import {Box, Button, Modal, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";
import {useNavigate} from "react-router-dom";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const LoginPopup: FC<Props> = ({closeModal, open}) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus
        disableAutoFocus
        disableEnforceFocus>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 280,
            boxShadow: 24,
            p: 2,
            textAlign: "center",
            borderRadius: 2,
          }}>
          <Typography variant="h6" mb={4}>
            You haven't logged in yet. <br />
            Please login first.
          </Typography>
          <Button
            variant="outlined"
            sx={{borderRadius: 2.5, marginRight: 2, textTransform: "none", fontSize: 20, px: 2.5}}
            onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{borderRadius: 2.5, textTransform: "none", fontSize: 20, px: 2.8}}
            onClick={() => {
              localStorage.removeItem("access_token");
              navigate("/login");
            }}>
            Login
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default LoginPopup;
