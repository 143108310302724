// HOOKS
import {useTranslation} from "react-i18next";

// UI
import {Box, Tab, Tabs, Typography} from "@mui/material";
import Navbar from "../../../components/Desktop/Navbar";
import Footer from "../../../components/Desktop/Footer";
import {CustomTabPanel} from "../../Mobile/MyJobs";
import HelpersSection from "./HelpersSection";
import {theme} from "../../../styles/theme";
import JobsSelection from "./JobsSection";

// ASSETS & ICONS
import {BLOBS_MY_JOBS} from "../../../assets/Images";
import {SyntheticEvent, useState} from "react";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyJobs = () => {
  const {t} = useTranslation();

  // TAB HANDLER
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Navbar />
      <Box
        sx={{
          paddingX: 10,
          paddingY: 8,
          backgroundImage: `url(${BLOBS_MY_JOBS})`,
          backgroundPositionX: "right",
          backgroundPositionY: "-120px",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#D2EBFF",
          backgroundSize: 200,
        }}>
        <Typography variant="h4" fontWeight={700}>
          {t("My Jobs")}
        </Typography>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          TabIndicatorProps={{sx: {display: "none"}}}
          sx={{borderBottom: "none"}}>
          <Tab
            label={t("CLIENT")}
            {...a11yProps(0)}
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              opacity: value === 0 ? 1 : 0.5,
            }}
            disableRipple
          />
          <Tab
            label={t("HELPER")}
            {...a11yProps(1)}
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              opacity: value === 1 ? 1 : 0.5,
            }}
            disableRipple
          />
        </Tabs>

        {/* JOBS UNDER OWNERSHIP */}
        <CustomTabPanel value={value} index={0}>
          <JobsSelection />
        </CustomTabPanel>
        {/* HELPERS SECTION */}
        <CustomTabPanel value={value} index={1}>
          <HelpersSection />
        </CustomTabPanel>
      </Box>

      {/* FOOTER */}
      <Footer />
    </>
  );
};

export default MyJobs;
