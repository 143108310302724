// HOOKS & ROUTER DOM
import {Navigate, Outlet} from "react-router-dom";

// UTILS
import {isLoggedIn} from "../../utils/AuthUtils";
const PrivateRoutes = () => {
  if (isLoggedIn()) {
    return <Outlet />;
  }
  return <Navigate to={"/"} />;
};

const MPrivateRoutes = () => {
  if (isLoggedIn()) {
    return <Outlet />;
  }
  // return <></>;
  return <Navigate to={"/m/sign-in"} />;
};

export {PrivateRoutes, MPrivateRoutes};
