// HOOKS
import {useEffect, useState, useCallback, useMemo} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector, useAppDispatch} from "../../../store";

// UI
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";
import CounterOffer from "../../../components/Desktop/CounterOffer";
import {Avatar, Box, Button, Typography} from "@mui/material";
import Footer from "../../../components/Desktop/Footer";
import Navbar from "../../../components/Desktop/Navbar";
import CounterOfferModal from "./CounterOfferModal";
import ViewProfileModal from "./ViewProfileModal";
import SplitText from "../MyJobs/SplitText";
import {theme} from "../../../styles/theme";
import ActionButtons from "./ActionButtons";

// SERVICES & UTILS
import {updateRequest} from "../../../store/Slices/RequestsSlice";
import RequestServices from "../../../services/RequestServices";
import {updateJob} from "../../../store/Slices/JobsSlice";
import {calculateDuration} from "../../../utils/JobUtils";
import {IRequestAPI} from "../../../interfaces/IRequest";
import JobServices from "../../../services/JobServices";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import moment from "moment";

// ASSETS & ICONS
import {ReactComponent as HorizontalDotsIcon} from "../../../assets/Icons/horizontal-dots.svg";
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import {ReactComponent as PlusIcon} from "../../../assets/Icons/plus.svg";
import {BLOBS_MY_JOBS} from "../../../assets/Images";

const JobRequestDetails = () => {
  const [isLoading, setLoading] = useState({load: false, declineLoad: false, acceptLoad: false});
  const [isDecisionMade, setDecisionMade] = useState(false);
  const {t} = useTranslation();
  const {id} = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();

  // REQUEST FROM NAVIGATION
  const location = useLocation();
  let requestFromNavigation = location.state as IRequestAPI;

  // FIND REQUEST FOR HELPER FROM STORE
  const requests = useAppSelector(state => state.requests);
  const [searchParams] = useSearchParams();
  const requestFromStore = requests.find(item => {
    if (item.id === searchParams.get("request_id")) {
      return {_id: item.id, ...item};
    }
  }) as IRequestAPI | undefined;

  const [jobRequestDetails, setJobRequestDetails] = useState<IRequestAPI | undefined>();

  function getJobRequestDetails(jobRequest?: IRequestAPI) {
    if (jobRequest) {
      setJobRequestDetails({
        _id: jobRequest._id,
        client: jobRequest.client,
        counterOffers: jobRequest.counterOffers,
        createdAt: jobRequest.createdAt,
        helper: jobRequest.helper,
        job: jobRequest.job,
        note: jobRequest.note,
        status: jobRequest.status,
        updatedAt: jobRequest.updatedAt,
      });
      return;
    } else if (requestFromStore) {
      setJobRequestDetails(requestFromStore);
    } else if (requestFromNavigation) {
      setJobRequestDetails(requestFromNavigation);
    }
  }

  // UPDATE REQUEST
  async function updateThisRequest(status: "APPROVED" | "DECLINED") {
    try {
      if (status === "APPROVED") {
        setLoading({load: true, acceptLoad: true, declineLoad: false});
      } else if (status === "DECLINED") {
        setLoading({load: true, acceptLoad: false, declineLoad: true});
      }
      const resp = await RequestServices.UpdateRequest(jobRequestDetails?._id, {status});

      // SUCCESS
      if (resp.status === 200) {
        // APPROVE REQUEST & APPROVE JOB
        if (status === "APPROVED") {
          enqueueSnackbar(t("Request is accepted."), {variant: "success"});
          await JobServices.UpdateJob(jobRequestDetails?.job._id, {
            status: "APPROVED",
            helper: jobRequestDetails?.helper._id,
          });
          dispatch(updateRequest({id: jobRequestDetails?._id, status}));
          dispatch(
            updateJob({
              id: jobRequestDetails?.job._id,
              status: "APPROVED",
              helper: jobRequestDetails?.helper._id,
            })
          );
        }

        // DECLINED REQUEST
        if (status === "DECLINED") {
          enqueueSnackbar(t("Request is declined."), {variant: "success"});
        }
        setDecisionMade(true);
        setLoading({load: false, acceptLoad: false, declineLoad: false});
      }
    } catch (error) {
      setLoading({load: false, acceptLoad: false, declineLoad: false});
      const err = error as AxiosError;
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      }
    }
  }

  // MODAL HANDERS - JOB DETAILS
  const [showCounterOffer, setShowCounterOffer] = useState(false);
  const handleShowCounterOffer = () => setShowCounterOffer(true);
  const handleCloseCounterOffer = () => setShowCounterOffer(false);

  // MODAL HANDERS - JOB DETAILS
  const [showProfile, setShowProfile] = useState(false);
  const handleShowProfile = () => setShowProfile(true);
  const handleCloseProfile = () => setShowProfile(false);

  useEffect(() => {
    getJobRequestDetails();
  }, [requestFromStore]);

  return (
    <>
      <Navbar />

      <Box
        sx={{
          paddingX: 10,
          paddingY: 8,
          backgroundImage: `url(${BLOBS_MY_JOBS})`,
          backgroundPositionX: "right",
          backgroundPositionY: "-120px",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#D2EBFF",
          backgroundSize: 200,
        }}>
        {/* JOB TITLE & DATE */}
        <Box sx={{borderRadius: 2.5, overflow: "hidden", marginBottom: 3}}>
          <BoxPrimaryGradient>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2}}>
              <Box>
                <Typography mb={0.5} color={theme.palette.common.white}>
                  {jobRequestDetails?.job?.title}
                </Typography>
                <Typography
                  fontSize={12}
                  variant="body2"
                  color={theme.palette.common.white}
                  sx={{display: "flex"}}>
                  <ClockIcon style={{width: 16, height: 16, fill: "white", marginRight: 4}} />
                  {moment(jobRequestDetails?.job?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </Box>
          </BoxPrimaryGradient>
        </Box>

        {/* REQUEST NOTE */}
        {jobRequestDetails?.note ? (
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey[300]}`,
              marginY: 3,
              borderRadius: 2.5,
              padding: 2,
              backgroundColor: theme.palette.common.white,
            }}>
            <Typography>{t("Request Note")}</Typography>
            <Typography fontSize={14} whiteSpace={"pre-wrap"}>
              {jobRequestDetails?.note}
            </Typography>
          </Box>
        ) : null}

        {/* JOB INFO */}
        <Box
          sx={{
            padding: 2,
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.common.white,
            borderRadius: 2.5,
            marginBottom: 3,
          }}>
          {jobRequestDetails?.job?.startOn && jobRequestDetails?.job?.endOn ? (
            <SplitText
              title={t("Timeline")}
              value={calculateDuration(jobRequestDetails?.job?.startOn, jobRequestDetails?.job?.endOn)}
            />
          ) : null}
          <SplitText title={t("Bugdet")} value={jobRequestDetails?.job?.budget?.toLocaleString()} />
          <SplitText title={t("Experience")} value={jobRequestDetails?.job?.experience} />
          <SplitText title={t("Required Tools")} value={jobRequestDetails?.job?.tools} />
        </Box>

        {/* JOB DESCRIPTION */}
        <Box
          sx={{
            padding: 2,
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.common.white,
            borderRadius: 2.5,
            marginBottom: 3,
          }}>
          <Typography variant="h5" fontWeight={600} mb={1}>
            {t("Description")}
          </Typography>
          <Typography variant="body1" whiteSpace={"pre-wrap"}>
            {jobRequestDetails?.job?.description}
          </Typography>
        </Box>

        {/* I'M CLIENT HERE */}
        {jobRequestDetails?.client?._id === id ? (
          <>
            <Box
              sx={{
                padding: 3,
                border: `1px solid ${theme.palette.grey[300]}`,
                backgroundColor: theme.palette.common.white,
                borderRadius: 2.5,
                marginBottom: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Avatar sx={{height: 100, width: 100}} src={jobRequestDetails?.helper?.profile}>
                {jobRequestDetails?.helper?.name?.split("")[0]}
              </Avatar>
              <Box sx={{paddingLeft: 2, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Typography variant="body1" fontWeight={600}>
                  {t("Helper")}:
                </Typography>
                <Typography variant="h5" fontWeight={600} mb={1}>
                  {jobRequestDetails?.helper?.name}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{borderRadius: 2.5, marginLeft: "auto"}}
                onClick={handleShowProfile}>
                View Profile
              </Button>
            </Box>
          </>
        ) : null}

        {/* I'M HELPER HERE */}
        {jobRequestDetails?.helper?._id === id ? (
          <>
            <Box
              sx={{
                padding: 3,
                border: `1px solid ${theme.palette.grey[300]}`,
                backgroundColor: theme.palette.common.white,
                borderRadius: 2.5,
                marginBottom: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Avatar sx={{height: 100, width: 100}} src={jobRequestDetails?.client?.profile}>
                {jobRequestDetails?.client?.name?.split("")[0]}
              </Avatar>
              <Box sx={{paddingLeft: 2, display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <Typography variant="body1" fontWeight={600}>
                  {t("Client")}:
                </Typography>
                <Typography variant="h5" fontWeight={600} mb={1}>
                  {jobRequestDetails?.client?.name}
                </Typography>
              </Box>
              <Button
                variant="outlined"
                sx={{borderRadius: 2.5, marginLeft: "auto"}}
                onClick={handleShowProfile}>
                View Profile
              </Button>
            </Box>
          </>
        ) : null}

        {/* COUNTER OFFERS */}
        {jobRequestDetails?.counterOffers?.length ? (
          <>
            <Typography color={theme.palette.primary.main} variant="h4" fontWeight={600} mb={1}>
              {t("Counter Offers")}:
            </Typography>
            {jobRequestDetails?.counterOffers?.map(item => (
              <CounterOffer key={item._id} counterOffer={item} isMe={item?.offeredBy === id} />
            ))}
          </>
        ) : null}

        {/* ADD COUNTER OFFER BUTTON */}
        {jobRequestDetails?.status === "PENDING" &&
          jobRequestDetails?.job?.status === "PENDING" &&
          !isDecisionMade && (
            <Box sx={{display: "flex", flexDirection: "column"}}>
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  borderRadius: 2.5,
                  paddingX: 2,
                  paddingY: 1,
                  marginLeft: "auto",
                }}
                onClick={handleShowCounterOffer}>
                <PlusIcon style={{fill: theme.palette.primary.main, marginRight: 4}} />
                {t("Add your Counter Offer")}
              </Button>
            </Box>
          )}
        {/* ACTION BUTTONS */}
        {jobRequestDetails?.client?._id === id &&
        jobRequestDetails?.status === "PENDING" &&
        jobRequestDetails?.job?.status === "PENDING" &&
        !isDecisionMade ? (
          <ActionButtons
            isLoading={isLoading.load}
            acceptLoad={isLoading.acceptLoad}
            declineLoad={isLoading.declineLoad}
            onAccept={() => updateThisRequest("APPROVED")}
            onDecline={() => updateThisRequest("DECLINED")}
          />
        ) : null}
      </Box>

      {/* FOOTER */}
      <Footer />

      {/* COUNTER OFFER MODAL */}
      <CounterOfferModal
        request={jobRequestDetails}
        open={showCounterOffer}
        handleClose={handleCloseCounterOffer}
        onAddOffer={(val: IRequestAPI) => getJobRequestDetails(val)}
      />

      {/* USER DETAILS */}
      <ViewProfileModal
        open={showProfile}
        closeModal={handleCloseProfile}
        user={jobRequestDetails?.client?._id === id ? jobRequestDetails?.helper : jobRequestDetails?.client}
      />
    </>
  );
};

export default JobRequestDetails;
