import {FC} from "react";
import {IRequest} from "../../../interfaces/IRequest";
import {Avatar, Box, Container, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import moment from "moment";

// HELPER CARD COMPONENT
interface HelperCardProps {
  request: IRequest;
  onClickHelper: () => void;
}
const HelperCard: FC<HelperCardProps> = ({request, onClickHelper}) => {
  return (
    <>
      <Container
        disableGutters
        sx={{
          padding: 2,
          borderRadius: 2.5,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
        onClick={onClickHelper}>
        <Avatar sx={{height: 60, width: 60, textTransform: "uppercase"}} src={request?.client?.profile}>
          {request?.client?.name.split("")[0]}
        </Avatar>
        <Box sx={{marginLeft: 2}}>
          <Typography variant="h6">{request?.client?.name}</Typography>
          <Typography variant="body2" className="truncate-1">
            {request?.job?.description}
          </Typography>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", position: "absolute", top: 10, right: 10}}>
          <ClockIcon style={{width: 16, height: 16}} fill="#fff" />
          <Typography variant="body2" sx={{marginLeft: 0.5, fontSize: 12}}>
            {moment(request.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default HelperCard;
