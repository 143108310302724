import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';

// SLICES
import UserSlice from './Slices/UserSlice';
import JobsSlice from './Slices/JobsSlice';
import RequestsSlice from './Slices/RequestsSlice';

export const store = configureStore({
  reducer: {
    user: UserSlice.reducer,
    jobs: JobsSlice.reducer,
    requests: RequestsSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
});


export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;