import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {FC} from "react";
import {theme} from "../../../styles/theme";
import {ReactComponent as AccordianArrow} from "../../../assets/Icons/chevron-down.svg";
import {checkDeviceType} from "../../../utils/AppUtils";

interface Props {
  id: string;
  question: string;
  answer: string;
  expanded: string | false;
  setExpanded: (n: string | false) => void;
}

const CustomAccordion: FC<Props> = ({id, question, answer, expanded, setExpanded}) => {
  const handleExpand = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === id}
        onChange={handleExpand(id)}
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          borderRadius: 0,
          "::before": {
            display: "none",
          },
        }}>
        <AccordionSummary
          sx={{borderRadius: 0}}
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<AccordianArrow width={16} />}>
          <Typography fontSize={checkDeviceType() === "D" ? 26 : 12} color={theme.palette.primary.main}>
            {question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography fontSize={checkDeviceType() === "D" ? 20 : 12} color={theme.palette.primary.main}>
            {answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CustomAccordion;
