import {FC} from "react";
import {IJob} from "../../../interfaces/IJob";
import {Box, Container, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";
import moment from "moment";
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";

// CLIENT CARD COMPONENT
interface CleintCardProps {
  job: IJob;
  onClickClient: () => void;
}
const ClientCard: FC<CleintCardProps> = ({job, onClickClient}) => {
  return (
    <>
      <Container
        disableGutters
        sx={{
          padding: 3,
          borderRadius: 2.5,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
        onClick={onClickClient}>
        <Box>
          <Typography variant="h6" fontSize={16} className={"truncate-1"}>
            {job.title}
          </Typography>
          <Typography variant="body2" fontWeight={300} className={"truncate-2"}>
            {job.description}
          </Typography>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", position: "absolute", top: 10, right: 10}}>
          <ClockIcon style={{width: 16, height: 16}} fill="#fff" />
          <Typography variant="body2" sx={{marginLeft: 0.5, fontSize: 12}}>
            {moment(job.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
        {!job.isNotified && (
          <span
            style={{
              position: "absolute",
              backgroundColor: theme.palette.error.main,
              top: -4,
              right: -4,
              width: 12,
              height: 12,
              borderRadius: 6,
            }}
          />
        )}
      </Container>
    </>
  );
};

export default ClientCard;
