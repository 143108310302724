import { FC, ReactNode } from "react";
import { Box } from "@mui/material"

interface BoxPrimaryGradientProps {
  children: ReactNode;
}

const BoxPrimaryGradient: FC<BoxPrimaryGradientProps> = ({ children }) => {
  return <>
    <Box sx={{ background: `linear-gradient(116deg, #48BEDE 0%, #092C48 100%)` }}>
      {children}
    </Box>
  </>
}

export default BoxPrimaryGradient;