// UI
import {Box, Typography} from "@mui/material";
import {FC} from "react";
import {theme} from "../../../styles/theme";

interface Props {
  title: string;
  value?: string;
}

const MSplitText: FC<Props> = ({title, value}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 1.5,
          ":last-child": {marginBottom: 0},
        }}>
        <Typography color={theme.palette.primary.main} fontWeight={500} fontSize={14}>
          {title}
        </Typography>
        <Typography color={theme.palette.common.black} fontWeight={500} fontSize={14} textAlign={"right"}>
          {value}
        </Typography>
      </Box>
    </>
  );
};

export default MSplitText;
