// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import {useAppSelector} from "../../../store";
import {useTranslation} from "react-i18next";

// UI
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import AuthHeader from "../Register/AuthHeader";

// SERVICES & UTILES
import AuthServices from "../../../services/AuthServices";
import {verifyEmail} from "../../Mobile/VerifyEmail";
import {theme} from "../../../styles/theme";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

// ASSETS
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as EmailIcon} from "../../../assets/Icons/email.svg";
import {NEARLY_THERE_AUTH, TOP_RIGHT_BLOB} from "../../../assets/Images";

interface FormData {
  email: string;
  agreedToTC: boolean;
}

const VerifyEmail = () => {
  const user = useAppSelector(state => state.user);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors, isDirty, isValid},
    control,
  } = useForm({
    defaultValues: {
      email: user.email || "",
      agreedToTC: false,
    },
    mode: "all",
    resolver: joiResolver(verifyEmail),
  });

  // SUBMIT FOUND
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {email} = formData;

    try {
      const resp = await AuthServices.SendOTP("email", {email});

      // SUCCESS
      if (resp.status === 200) {
        setLoading(false);
        enqueueSnackbar(t("OTP is sent to your email."), {variant: "info"});
        navigate("/verify-otp?verification_channel=email&verification_path=account");
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;

      // EMAIL NOT FOUND
      if (err.response?.status === 404) {
        enqueueSnackbar(t("This email does not exist."), {variant: "error"});
      }

      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <AuthHeader title={t("Verify your Email to continue")} headline={NEARLY_THERE_AUTH} />

          <Box py={6} px={10} pr={20}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* EMAIL */}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="email"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="email"
                        size="medium"
                        variant="outlined"
                        type="email"
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailIcon fill={theme.palette.grey[400]} />
                            </InputAdornment>
                          ),
                        }}
                        error={errors?.email ? true : false}
                        helperText={t(errors?.email?.message || "")}
                        placeholder={t("Email")}
                        sx={{mb: 2}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* AGREED TO T&C */}
              <FormControl>
                <FormControlLabel
                  label={t("By entering my email, I agree to Helper 2 Go’s Terms & Conditions")}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: 14,
                    },
                  }}
                  control={
                    <Controller
                      name="agreedToTC"
                      control={control}
                      render={({field}) => (
                        <Checkbox
                          {...field}
                          color="primary"
                          icon={<CheckBoxIcon />}
                          checkedIcon={<CheckBoxCheckedIcon />}
                        />
                      )}
                    />
                  }
                />
              </FormControl>

              {/* SUBMIT BUTTON */}
              <FormControl sx={{display: "block", marginTop: 15}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                  disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                  {t("Continue")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>
          </Box>
          {/* GREY TEXT */}
          <Box mt={14} mb={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              px={8}
              variant="body2"
              fontWeight={500}
              color={theme.palette.grey[400]}
              mr={1}
              textAlign={"center"}>
              {t(
                "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae quaerat maiores consequuntur nostrum ex aut debitis saepe provident soluta a!"
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyEmail;
