// HOOKS & ROUTER
import {useEffect, useState} from "react";
import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "./store";
import {useSnackbar} from "notistack";
import Home from "./pages/Desktop/Home";

// AUTH PAGES - PUBLIC ROUTES
import Register from "./pages/Desktop/Register";
import VerifyPhone from "./pages/Desktop/VerifyPhone";
import Login from "./pages/Desktop/Login";
import ForgotPassword from "./pages/Desktop/ForgotPassword";
import VerifyOTP from "./pages/Desktop/VerifyOTP";
import ResetPassword from "./pages/Desktop/ResetPassword";
import VerifyEmail from "./pages/Desktop/VerifyEmail";
import PrivacyPolicy from "./pages/Desktop/PrivacyPolicy/PrivacyPolicy";

// JOB SELLER PAGES - PRIVATE ROUTS
import MyJobs from "./pages/Desktop/MyJobs";
import NewJob from "./pages/Desktop/NewJob";
import Search from "./pages/Desktop/Search";
import JobRequestDetails from "./pages/Desktop/JobRequestDetails";
import Profile from "./pages/Desktop/Profile";
import TermsNConditions from "./pages/Desktop/TermsNConditions";

// ROUTES
import {MPubliceRoutes, PubliceRoutes} from "./routes/Public";
import {MPrivateRoutes, PrivateRoutes} from "./routes/Private";
import MobileView from "./pages/Mobile";

// MOBILE VIEW - AUTH FLOW
// import Onboarding from "./pages/Mobile/Onboarding";
import MSignIn from "./pages/Mobile/SignIn";
import MSignUp from "./pages/Mobile/SignUp";
import MVerifyEmail from "./pages/Mobile/VerifyEmail";
import MVerifyPhone from "./pages/Mobile/VerifyPhone";
import MVerifyOTP from "./pages/Mobile/VerifyOTP";
import MForgotPassword from "./pages/Mobile/ForgotPassword";
import MNewPassword from "./pages/Mobile/NewPassword";

// MOBILE VIEW - HOME FLOW
import MHome from "./pages/Mobile/Home";
import MNotifications from "./pages/Mobile/Notifications";
import MMyJobs from "./pages/Mobile/MyJobs";
import MJobDetails from "./pages/Mobile/JobDetails";
import MJobRequestDetails from "./pages/Mobile/JobRequestDetails";
import MCounterOffers from "./pages/Mobile/CounterOffers";
import MSearch from "./pages/Mobile/Search";
import MApplyAsHelper from "./pages/Mobile/ApplyAsHelper";
import MPostNewJob from "./pages/Mobile/PostNewJob";
import MUserProfile from "./pages/Mobile/MUserProfile";
import MProfile from "./pages/Mobile/Profile";
import MEditProfile from "./pages/Mobile/EditProfile";
import MTermsNConditions from "./pages/Mobile/TermsNConditions";
import MLanguages from "./pages/Mobile/Languages";
import MFAQs from "./pages/Desktop/Home/FAQs";
import MAboutUs from "./pages/Mobile/AboutUs/AboutUs";

// SERVICES & UTILS
import {setMyJobs} from "./store/Slices/JobsSlice";
import AuthServices from "./services/AuthServices";
import {checkDeviceType} from "./utils/AppUtils";
import {setUser} from "./store/Slices/UserSlice";
import JobServices from "./services/JobServices";
import {IJob, IJobAPI} from "./interfaces/IJob";
import {isLoggedIn} from "./utils/AuthUtils";
import {AxiosError} from "axios";
import RequestServices from "./services/RequestServices";
import {IRequest, IRequestAPI} from "./interfaces/IRequest";
import {setMyRequests} from "./store/Slices/RequestsSlice";
import {useNotification} from "./hooks/useNotifications";
import {CircularProgress} from "@mui/material";
import MPrivacyPolicy from "./pages/Mobile/PrivacyPolicy";

const App = () => {
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {i18n} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const {id} = useAppSelector(state => state.user);
  const {addNotification, clearNotification} = useNotification();

  // GET MY JOBS
  async function getMyJobs() {
    if (id) {
      try {
        const resp = await JobServices.GetJobsFiltered({client: id});

        // SUCCESS
        if (resp.status === 200) {
          const arrOfJobs = resp.data as IJobAPI[];
          const allMyJobs: IJob[] = arrOfJobs.map((item: IJobAPI) => ({
            id: item._id,
            budget: item.budget,
            client: item.client._id,
            description: item.description,
            experience: item.experience,
            helper: item.helper?._id,
            status: item.status,
            isNotified: item.isNotified,
            startOn: item.startOn,
            endOn: item.endOn,
            title: item.title,
            tools: item.tools,
            createdAt: new Date(item.createdAt),
            updatedAt: new Date(item.updatedAt),
          }));
          dispatch(setMyJobs(allMyJobs));
          if (allMyJobs.length) {
            const jobsNotNotifiedYet = allMyJobs.filter(item => !item.isNotified);
            if (jobsNotNotifiedYet.length) {
              addNotification();
            } else {
              clearNotification();
            }
          }
        }
      } catch (error) {}
    }
  }
  // GET MY REQUESTS
  async function getMyRequests() {
    if (id) {
      try {
        const resp = await RequestServices.GetRequestsWithFilters({helper: id});

        // SUCCESS
        if (resp.status === 200) {
          const arrOfRequests = resp.data as IRequestAPI[];
          const allMyRequests: IRequest[] = arrOfRequests.map((item: IRequestAPI) => ({
            id: item._id,
            client: item.client,
            counterOffers: item.counterOffers,
            createdAt: item.createdAt,
            helper: item.helper,
            job: item.job,
            note: item.note,
            status: item.status,
            updatedAt: item.updatedAt,
          }));
          dispatch(setMyRequests(allMyRequests));
        }
      } catch (error) {}
    }
  }
  // WHEN BROWSER IS MINIMIZED
  window.onfocus = () => {
    let access_token = localStorage.getItem("access_token");

    if (access_token && access_token !== "guest") {
      getMyJobs();
      getMyRequests();
    }
  };

  async function persistUser() {
    setLoading(true);
    const access_token = localStorage.getItem("access_token");
    // USER SIGNED IN ALREADY
    if (access_token && access_token !== "guest") {
      try {
        const resp = await AuthServices.GetMe();

        // SUCCESS
        if (resp.status === 200) {
          dispatch(setUser({...resp.data, access_token, id: resp.data?._id}));
        }
      } catch (error) {
        const err = error as AxiosError;
        console.log(err);
        // USER NOT FOUND
        if (err.response?.status === 404) {
          enqueueSnackbar("User does not exist.", {variant: "error"});
        }
        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar("Make sure your internet is working.", {variant: "error"});
        } else {
          enqueueSnackbar("Something went wrong.", {variant: "error"});
        }
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    // setLoading(true);
    // MOBILE DEVICE
    if (checkDeviceType() === "M") {
      const isOnboarded = localStorage.getItem("onboard") as string;
      // ONBOARDED ALREADY & LOGGED
      if (isLoggedIn()) {
        navigate("/m/home");
      }
      // ONBOARDED BUT NOT LOGGED IN
      else {
        navigate("/m/sign-in");
      }
      // NEITHER ONBOARDED, NOR LOGGED IN
      // else {
      //   navigate("/m/onboarding");
      // }
    }
    // setLoading(false);
  }, []);

  useEffect(() => {
    persistUser();
  }, []);

  // SET APP LANGUANGE
  function appLanguage() {
    // i18n.changeLanguage("de");
    const lang = localStorage.getItem("lang");
    // NOT SET YET
    if (!lang) {
      localStorage.setItem("lang", "en");
      i18n.changeLanguage("en");
    }
    // LANGUAGE SET ALREADY
    else if (lang) {
      i18n.changeLanguage(lang);
    }
  }

  useEffect(() => {
    appLanguage();
  }, []);

  if (isLoading)
    return (
      <div style={{width: "100vw", height: "100vh", display: "grid", placeItems: "center"}}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      <Routes>
        {/* COMMON ROUTES */}
        <Route path="/" element={checkDeviceType() === "D" ? <Home /> : <Navigate to={"/m/sign-in"} />} />
        <Route path="/search" element={<Search />} />
        <Route path="/terms-and-conditions" element={<TermsNConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/m/terms-and-conditions" element={<MTermsNConditions />} />
        <Route path="/m/privacy-policy" element={<MPrivacyPolicy />} />

        {/* DESKTOP PUBLIC ROUTES */}
        <Route element={<PubliceRoutes />}>
          {/* DESKTOP */}
          <Route path="/register" element={<Register />} />
          <Route path="/verify-phone" element={<VerifyPhone />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Route>

        {/* DESKTOP PRIVATE ROUTES */}
        <Route element={<PrivateRoutes />}>
          <Route path="/my-jobs" element={<MyJobs />} />
          <Route path="/my-jobs/new-job" element={<NewJob />} />
          <Route path="/job-request-details" element={<JobRequestDetails />} />
          <Route path="/profile" element={<Profile />} />
        </Route>

        {/* MOBILE PUBLIC ROUTES */}
        <Route element={<MPubliceRoutes />}>
          <Route element={<MobileView />} path="/m">
            {/* <Route path="/m/onboarding" element={<Onboarding />} /> */}
            <Route path="/m/sign-in" element={<MSignIn />} />
            <Route path="/m/sign-up" element={<MSignUp />} />
            <Route path="/m/verify-email" element={<MVerifyEmail />} />
            <Route path="/m/verify-phone" element={<MVerifyPhone />} />
            <Route path="/m/verify-otp" element={<MVerifyOTP />} />
            <Route path="/m/forgot-password" element={<MForgotPassword />} />
            <Route path="/m/new-password" element={<MNewPassword />} />
          </Route>
        </Route>

        {/* MOBILE PRIVATE ROUTES */}
        <Route element={<MPrivateRoutes />}>
          <Route element={<MobileView />} path="/m">
            <Route path="/m/home" element={<MHome />} />
            <Route path="/m/notifications" element={<MNotifications />} />
            <Route path="/m/search" element={<MSearch />} />
            <Route path="/m/my-jobs" element={<MMyJobs />} />
            <Route path="/m/job-details" element={<MJobDetails />} />
            <Route path="/m/job-request-details" element={<MJobRequestDetails />} />
            <Route path="/m/helper-profile" element={<MUserProfile />} />
            <Route path="/m/client-profile" element={<MUserProfile />} />
            <Route path="/m/counter-offers" element={<MCounterOffers />} />
            <Route path="/m/apply-job" element={<MApplyAsHelper />} />
            <Route path="/m/post-job" element={<MPostNewJob />} />
            <Route path="/m/profile" element={<MProfile />} />
            <Route path="/m/edit-profile" element={<MEditProfile />} />
            <Route path="/m/languages" element={<MLanguages />} />
            <Route path="/m/faqs" element={<MFAQs />} />
            <Route path="/m/about-us" element={<MAboutUs />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
