import Joi from "joi";

export const newJobForm = Joi.object({
  title: Joi.string().empty().required().messages({
    "string.base": "Title is required.",
    "string.required": "Title is required.",
    "string.empty": "Title is required.",
  }),
  startOn: Joi.optional(),
  endOn: Joi.optional(),
  budget: Joi.number().empty().min(1).required().messages({
    "number.base": "Budget is required.",
    "number.required": "Budget is required.",
    "number.empty": "Budget is required.",
    "number.min": "Budget can't be zero.",
  }),
  experience: Joi.string().empty().required().messages({
    "string.base": "Experience is required.",
    "string.required": "Experience is required.",
    "string.empty": "Experience is required.",
  }),
  tools: Joi.string().empty().required().messages({
    "string.base": "Tools are required.",
    "string.required": "Tools are required.",
    "string.empty": "Tools are required.",
  }),
  description: Joi.string().empty().required().messages({
    "string.base": "Description is required.",
    "string.required": "Description is required.",
    "string.empty": "Description is required.",
  }),
  agreedToTC: Joi.boolean().valid(true).required().messages({
    'any.only': 'You must agree to the Terms & Conditions.',
    'any.required': 'You must agree to the Terms & Conditions.'
  }),
  agreedToFeeDeduction: Joi.boolean().valid(true).required().messages({
    'any.only': 'You must agree to 5% fee of the order.',
    'any.required': 'You must agree to 5% fee of the order.'
  })
});
