import "./TnC.css";

const TermsNConditionsText = () => {
  return (
    <div>
      <div id="tnc">
        <h1
          style={{
            paddingTop: "2pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "163%",
            textAlign: "left",
          }}>
          Helper2Go Nutzungsbedingungen{" "}
          <span className="p">Letztes Update: Januar 2024 Willkommen bei Helper2Go</span>
        </h1>
        <p
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          <a href="http://www.Helper2Go.ch/" className="a" target="_blank">
            Die folgenden Nutzungsbedingungen regeln Ihren Zugriff und Ihre Nutzung der Helper2Go- Website und
            der mobilen Anwendung, einschließlich aller Inhalte, Funktionen und Dienste, die auf oder über
          </a>
          www.Helper2Go.ch oder die mobile Helper2Go-Anwendung angeboten werden ( die "Site") von Helper2Go
          werden hier zusammenfassend als "H2G", "wir" oder "uns" bezeichnet, und "Sie" oder "Benutzer"
          bezeichnet Sie als Benutzer der Website.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}>
          Bitte lesen Sie die Nutzungsbedingungen sorgfältig durch, bevor Sie die Website nutzen.
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          Durch die Nutzung der Website, die Eröffnung eines Kontos oder durch ein Anklicken akzeptieren Sie
          die Nutzungsbedingungen. Sie können diese im eigenen Namen oder im Namen Ihres Arbeitgebers oder
          einer anderen Einrichtung akzeptieren, sofern Sie dazu intern berechtigt sind.
        </p>
        <p style={{textIndent: "0pt", textAlign: "left"}}>
          <br />
        </p>
        <p
          style={{
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          Mit der Zustimmung zu den Nutzungsbedingungen akzeptieren Sie gleichzeitig auch die hier
          aufgeführten <b>H2G-Zahlungsbedingungen</b>
          („Zahlungsbedingungen“) und unsere hier enthaltenen <u>Datenschutzrichtlinien</u>. Wenn Sie diesen
          Nutzungsbedingungen oder den Datenschutzrichtlinien nicht zustimmen möchten, dürfen Sie nicht auf
          die Website zugreifen oder diese nutzen. Für detailliertere Richtlinien bezüglich Aktivität und
          Nutzung der Website lesen Sie bitte weiter.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          Diese Website wird Benutzern angeboten und steht diesen zur Verfügung, die *18 Jahre oder älter
          sind. Wenn Sie unter 18 Jahre alt sind, dürfen Sie diese Website oder die H2G- Dienste nicht nutzen.
          Durch die Nutzung dieser Website versichern Sie, dass Sie *volljährig sind, um einen verbindlichen
          Vertrag abschließen zu können und alle oben genannten Zulassungsvoraussetzungen auch erfüllen. Wenn
          Sie nicht *alle diese Anforderungen erfüllen, dürfen Sie nicht auf die Site zugreifen oder diese
          nutzen.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          *In einigen Ländern können Ausnahmen gelten, wenn die offiziellen Gesetze die Durchführung des
          miniJobs in Verbindung mit dem Alter des Helpers erlauben.
        </p>
        <p
          style={{
            paddingTop: "7pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "justify",
          }}>
          <a href="mailto:info@helper2go.ch" className="a" target="_blank">
            Unser Kundensupport-Team steht Ihnen zur Verfügung, wenn Sie Fragen zur Website oder zu den
            Nutzungsbedingungen haben. Die Kontaktaufnahme mit unserem Kundensupport- Team kann durch Senden
            einer Anfrage an{" "}
          </a>
          info@helper2go.ch erfolgen.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          Die Originalsprache dieser Nutzungsbedingungen sowie aller anderen Texte auf der Website ist
          Deutsch. H2G stellt diese Übersetzung nur zur Vereinfachung zur Verfügung. Bei Konflikten zwischen
          der deutschen Originalversion und einer Übersetzung hat die deutsche Version Vorrang.
        </p>
        <p
          className="s1"
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}>
          Schlüsselbegriffe
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "162%",
            textAlign: "left",
          }}>
          Clients sind Benutzer, die Dienste auf H2G inserieren. Helpers sind Benutzer, die Dienste auf H2G
          erledigen.
        </p>
        <p
          style={{
            paddingTop: "2pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "108%",
            textAlign: "left",
          }}>
          Benutzerdefinierte Angebote sind Vorschläge eines Kunden, in Verbindung mit einem Budget (Offerte).
          Auf dieses benutzerdefinierte Angebot kann der Helper sich bewerben oder ein Gegenangebot
          platzieren.
        </p>
        <p style={{textIndent: "0pt", textAlign: "left"}}>
          <br />
        </p>
        <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
          miniJob® / miniJobs® sind Dienste, die auf H2G angeboten werden.
        </p>
        <p
          style={{
            paddingTop: "9pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            lineHeight: "106%",
            textAlign: "left",
          }}>
          Bestellungen sind die formellen Vereinbarungen zwischen einem Client und einem Helper, nachdem ein
          Kauf über die miniJob®-Seite des Verkäufers getätigt wurde.
        </p>
        <p
          style={{
            paddingTop: "8pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}>
          Übersicht (Hauptbegriffe auf den Punkt gebracht)
        </p>
        <ul id="l1">
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Nur registrierte Benutzer dürfen auf H2G kaufen und verkaufen. Die Registrierung ist kostenlos.
              Bei der Registrierung für ein Konto erklären Sie sich damit einverstanden, uns genaue,
              vollständige und aktualisierte Informationen zur Verfügung zu stellen und dürfen kein Konto für
              betrügerische oder irreführende Zwecke erstellen. Sie sind allein verantwortlich für alle
              Aktivitäten auf Ihrem Konto und für die Wahrung der Vertraulichkeit und Sicherheit Ihres
              Passworts. Wir haften nicht für Handlungen oder Unterlassungen von Ihnen im Zusammenhang mit
              Ihrem Konto.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              miniJobs auf H2G können zu einem Fixpreis von 10 % (5 % Helper / 5 % Client) den bestätigten
              Kaufpreis erledigt werden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify",
              }}>
              Die Bezahlung der Minijobs obliegt dem Client und dem Helper eigenverantwortlich. H2G übernimmt
              lediglich die Vermittlung der Parteien durch die Bereitstellung der Möglichkeit, Minijobs zu
              inserieren und zu finden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify",
              }}>
              Sobald sich ein Helper bewirbt, wird eine Bestellung ausgelöst. Der Client kann dann dieses
              Angebot annehmen und die Bestellung abschließen. Anschließend werden automatisch 10 % des
              bestätigten Kaufpreises in Rechnung gestellt (5 % für den Helper und 5 % für den Client).
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Verkäufer müssen ihre Bestellungen erfüllen und dürfen Bestellungen nicht regelmäßig oder ohne
              Grund stornieren. Das Stornieren von Bestellungen wirkt sich auf den Ruf und den Status des
              Verkäufers aus.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Beim Kauf eines miniJobs erhalten Käufer alle Rechte für das gelieferte Werk oder die erfüllte
              Dienstleistung sofern der Verkäufer auf seiner Gig-Seite nichts anderes angibt.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Wir kümmern uns um Ihre Privatsphäre. Wir verweisen dazu auf unsere Datenschutzrichtlinien.
              Diese sind integrierender Bestandteil dieser Nutzungsbedingungen und es wird hiermit
              ausdrücklich darauf verwiesen.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Benutzer verpflichten sich, die Community-Standards von H2G einzuhalten, die eine Reihe von
              Verhaltensregeln und -richtlinien darstellen, die für die H2G-Community zusätzlich zu diesen
              Nutzungsbedingungen gelten und von Zeit zu Zeit aktualisiert werden.
            </p>
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Diese Standarts beinhalten einen respektvollen und fördernden Umgang. Ehrlich und transparent.
              Respekt als Grundlage zum Erfolg.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <h1 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>Grundlagen</h1>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left"}}>
              Clients erstellen miniJobs auf H2G, damit Helpers ihre Dienstleistungen erwerben können.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "14pt",
                textIndent: "-8pt",
                textAlign: "left",
              }}>
              Helpers können Clients auf jeden miniJob eine Gegenofferte anbieten.
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              H2G akkreditiert Verkäufe, sobald ein miniJob angenommen ist. In unserem Abschnitt
              "Bestellungen" unten finden Sie eine Definition einer abgeschlossenen Bestellung.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Weitere Informationen zum Erhalt von Zahlungen, Gebühren und Steuern finden Sie in den
              Zahlungsbedingungen.
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Aus Sicherheitsgründen behält sich H2G das Recht vor, vorübergehend die Fähigkeit eines Client,
              Helper oder miniJob zu deaktivieren, um betrügerische oder illegale Aktivitäten zu verhindern.
              Dies kann auf Sicherheitsprobleme, unangemessenes Verhalten, das von anderen Benutzern gemeldet
              wurde, oder die Verknüpfung mehrerer H2G-Konten mit einem einzelnen Auszahlungsanbieter
              zurückzuführen sein.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Clients und Helpers sind dafür verantwortlich, eine allgemeine <u>Haftpflichtversicherung </u>
              mit Deckungssummen abzuschließen, die ausreichen, um alle mit der Erbringung ihrer
              Dienstleistungen verbundenen Risiken abzudecken.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Auftritte und / oder Benutzer können von H2G wegen Verstößen gegen diese Nutzungsbedingungen und
              / oder unsere Community-Standards von der Website entfernt werden. Diese können die folgenden
              Verstöße und / oder Materialien umfassen (Aufzählung im Folgenden nicht abschliessend):
            </p>
            <ul id="l2">
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Illegale oder betrügerische Dienstleistungen
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}>
                  Urheberrechtsverletzung, Markenverletzung und Verletzung der Nutzungsbedingungen Dritter,
                  die im Rahmen unserer hier aufgeführten Richtlinie zu Ansprüchen auf geistiges Eigentum
                  gemeldet werden
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "8pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Erwachsenenorientierte Dienstleistungen, pornografisch, unangemessen / obszön
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Absichtliche Kopien von miniJobs
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Spam, Unsinn oder gewalttätige oder irreführende Auftritte
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Auftritte, die Käufer oder andere irreführen
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Weiterverkauf regulierter Waren
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Angebot, akademische Arbeiten im Auftrag des Käufers vorzubereiten (sog. Ghostwriting)
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "14pt",
                    textIndent: "-8pt",
                    textAlign: "left",
                  }}>
                  Services, die länger als 30 Tage dauern
                </p>
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left",
                  }}>
                  o miniJobs von überaus geringer Qualität
                </p>
              </li>
              <li data-list-text="o">
                <p
                  style={{
                    paddingTop: "9pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    lineHeight: "108%",
                    textAlign: "left",
                  }}>
                  Förderung von H2G- und / oder H2G-Auftritten durch Aktivitäten, die durch Gesetze,
                  Vorschriften und / oder Nutzungsbedingungen Dritter verboten sind, sowie durch
                  Marketingaktivitäten, die sich negativ auf unsere Beziehungen zu unseren Benutzern oder
                  Partnern auswirken.
                </p>
              </li>
            </ul>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Auftritte, die wegen der oben genannten Verstöße entfernt werden, können zur Sperrung des
              Benutzerkontos führen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Auftritte, die wegen Verstößen entfernt wurden, können nicht wiederhergestellt oder bearbeitet
              werden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Auftritte können aufgrund schlechter Leistung und / oder Fehlverhalten der Benutzer aus unserer
              Suchfunktion entfernt werden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              miniJobs können vorab genehmigte Website-URLs enthalten, die im Feld H2G- Beschreibung und
              -Anforderungen enthalten sind. Auftritte mit Websites, auf denen für Inhalte geworben wird, die
              gegen die Nutzungsbedingungen von H2G und / oder unsere Community-Standards verstoßen, werden
              entfernt.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Aussagen auf der miniJob-Seite, die diese Nutzungsbedingungen untergraben oder umgehen, sind
              verboten.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              miniJob-Extras sind zusätzliche Services, die zusätzlich zum miniJob des Clients zu einem vom
              Client festgelegten oder bestätigten zusätzlichen Preis angeboten werden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Feedback-Bewertungen von Käufern während des Abschlusses einer Bestellung sind ein wesentlicher
              Bestandteil des Bewertungssystems von H2G. Bewertungen belegen die allgemeine Erfahrung des
              Clients und des Helpers mit den Verkäufern und deren Service. Käufer werden aufgefordert, dem
              Verkäufer alle Bedenken mitzuteilen, die während ihrer aktiven Bestellung in Bezug auf die vom
              Verkäufer erbrachte Dienstleistung aufgetreten sind.
            </p>
            <p style={{paddingTop: "9pt", textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <h1 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Verhalten und Schutz der Benutzer
            </h1>
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Mit H2G können Menschen auf der ganzen Welt nahezu jeden Service, den sie benötigen, zu einem
              unschlagbaren Preis anbieten, teilen, verkaufen und kaufen. Die auf H2G angebotenen Dienste
              spiegeln die Vielfalt einer expandierenden miniJob-Wirtschaft wider. Mitglieder der
              miniJob-Community kommunizieren und engagieren sich über Bestellungen, soziale Medien und in den
              Community-Foren von H2G.
            </p>
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              H2G pflegt ein freundliches, gemeinschaftsorientiertes und professionelles Umfeld. Benutzer
              sollten sich an diesen Kodex halten, während sie an Aktivitäten oder Erweiterungen von H2G
              teilnehmen. Dieser Kodex bezieht sich auf das Verhalten, welches wir von den Benutzern erwarten
              und von ihnen eingehalten werden soll.
            </p>
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Um einen Verstoß gegen unsere Nutzungsbedingungen und / oder unsere Community- Standards,
              Fehlverhalten der Benutzer oder Anfragen bezüglich Ihres Kontos zu melden, wenden Sie sich bitte
              hier an unser Kundensupport-Team.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <p className="s1" style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Grundlagen
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Das Anfordern oder Bereitstellen von E-Mail-Adressen, Skype / IM-Benutzernamen, Telefonnummern
              oder anderen persönlichen Kontaktdaten zur Kommunikation außerhalb von H2G zur Umgehung oder zum
              Missbrauch des H2G-Nachrichtensystems oder der H2G- Plattform ist bis zur Bestätigung des
              Auftrags (inklusive definierter reellen Bezahlung) nicht gestattet.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Jeder notwendige Austausch persönlicher Informationen, der zur Fortsetzung eines Dienstes
              erforderlich ist, kann innerhalb der Bestellseite ausgetauscht werden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "7pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              H2G übernimmt keine Garantie für das Serviceniveau, das den Käufern angeboten wird. Sie können
              die Tools zur Streitbeilegung verwenden, die Ihnen auf der Bestellseite zur Verfügung gestellt
              werden.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "14pt",
                textIndent: "-8pt",
                textAlign: "left",
              }}>
              H2G bietet keinen Schutz für Benutzer, die außerhalb der H2G-Plattform interagieren.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "9pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "justify",
              }}>
              Alle Informationen und der Austausch von Dateien dürfen ausschließlich auf der Plattform von H2G
              durchgeführt werden (bis zur Bestätigung des Auftrags (inklusive definierter reellen
              Bezahlung)).
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Unhöfliche, missbräuchliche, unangemessene Sprache oder gewalttätige Nachrichten werden nicht
              toleriert und können zu einer Kontowarnung oder zur Sperrung / Entfernung des Kontos führen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              H2G steht allen offen. Eine Diskriminierung eines Community-Mitglieds aufgrund von Geschlecht,
              Rasse, Alter, religiöser Zugehörigkeit, sexueller Präferenz oder auf andere Weise ist nicht
              akzeptabel und kann zur Sperrung / Entfernung des Kontos führen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Benutzer dürfen keine Vorschläge einreichen oder über H2G eingeführte Parteien auffordern,
              Verträge mit H2G abzuschließen, mit ihnen zusammenzuarbeiten oder außerhalb von H2G zu zahlen.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <h2 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>Aufträge</h2>
          </li>
          <li data-list-text="•">
            <p
              className="s2"
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "2pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Benu
              <span className="s3">
                tzer mit der Absicht, konkurrierende Verkäufer durch Bestellung bei konkurrierenden Diensten
                zu diffamieren, werden ihre Bewertungen entfernt oder weitere Aktionen im Zusammenhang mit dem
                Kontostatus durch Überprüfung durch unser Trust- und Safetyteam festgelegt.
              </span>
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Benutzer dürfen keine früheren Käufer oder Verkäufer spammen oder auffordern, Bewertungen zu
              entfernen / zu ändern oder Bestellungen zu stornieren, die nicht den Richtlinien für die
              Stornierung oder das Feedback von Bestellungen entsprechen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Benutzer können Auftritte dem Kundensupport melden, die möglicherweise gegen die
              Nutzungsbedingungen von H2G verstoßen, basierend auf der replizierten Ähnlichkeit des gemeldeten
              Auftritts mit bereits vorhandenen Diensten (Nachahmer-Auftritte).
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "8pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              Helpers und Clients garantieren, dass alle in ihren Auftritten enthaltenen Inhalte
              Originalarbeiten sind, die von den Verkäufern konzipiert wurden und keine Rechte Dritter
              verletzen, einschließlich, ohne Einschränkung, Urheberrechte, Marken oder Dienstleistungsmarken.
              Für den Fall, dass bestimmte Musik- oder Stock-Footage-Medien in die miniJobs aufgenommen
              werden, versichern und garantieren die Helpers und Clients, dass sie über eine gültige Lizenz
              zur Nutzung dieser Musik und / oder des Filmmaterials verfügen und diese in die Beschreibung /
              miniJobs aufnehmen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                lineHeight: "108%",
                textAlign: "left",
              }}>
              H2G reagiert auf klare und vollständige Hinweise auf mutmaßliche Urheberrechts- oder
              Markenrechtsverletzungen. Unsere Verfahren zur Inanspruchnahme von geistigem Eigentum können
              hier eingesehen werden. Verstöße melden: Wenn Sie auf Inhalte stoßen, die möglicherweise gegen
              unsere Nutzungsbedingungen und / oder unsere Community- Standards verstoßen, sollten Sie uns
              dies über die entsprechenden Kanäle melden, die zur Behandlung dieser in unseren
              Nutzungsbedingungen beschriebenen Probleme eingerichtet wurden. Alle Fälle werden von unserem
              Trust- und Safetyteam geprüft. Zum Schutz der Privatsphäre des Einzelnen werden die Ergebnisse
              der Untersuchung nicht weitergegeben. Weitere Informationen finden Sie in unseren
              Datenschutzbestimmungen. Verstöße von Benutzern haben möglicherweise eine Warnung zur Folge.
              Ebenfalls Verstöße gegen unsere Nutzungsbedingungen und / oder unsere Community-Standards oder
              wegen Fehlverhaltens der Benutzer, das unserem Trust and Safety-Team gemeldet wurde, haben eine
              Warnung zur Folge. Eine Warnung wird an die E-Mail-Adresse des Benutzers gesendet und für diesen
              Benutzer auf der Site angezeigt. Warnungen schränken die Kontoaktivität nicht ein, können jedoch
              dazu führen, dass Ihr Konto aufgrund der Schwere des Verstoßes dauerhaft deaktiviert wird. Dazu
              gehören etwa unzulässige Dienstleistungen und Pornografie für Erwachsene – H2G erlaubt keinen
              Austausch von Materialien und Dienstleistungen für Erwachsene oder pornografische Materialien
              und Dienstleistungen.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <h1 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Unangemessenes Verhalten und Sprache
            </h1>
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Die Kommunikation auf H2G sollte freundlich, konstruktiv und professionell sein. H2G verurteilt
              Mobbing, Belästigung und Hassreden gegenüber anderen. Wir bieten Benutzern ein Medium, für das
              Nachrichten zwischen Einzelpersonen ausgetauscht werden, ein System, um Bestellungen zu bewerten
              und sich auf größeren Plattformen wie unserem Community-Forum und den Social Media-Seiten zu
              engagieren. Phishing und Spam
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Die Sicherheit der Mitglieder hat höchste Priorität. Alle Versuche, schädliche Inhalte zu
              veröffentlichen oder zu senden, um das Konto oder die Computerumgebung eines anderen Mitglieds
              zu gefährden, sind strengstens untersagt. Bitte respektieren Sie die Privatsphäre unserer
              Mitglieder, indem Sie sie nicht mit Angeboten, Fragen, Vorschlägen oder anderen Dingen
              kontaktieren, die nicht in direktem Zusammenhang mit ihren Auftritten oder Bestellungen stehen.
              Datenschutz und Identität
            </p>
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Sie dürfen keine privaten und vertraulichen Informationen anderer Personen veröffentlichen oder
              veröffentlichen. Jeder Austausch persönlicher Daten, der für die Erbringung eines Service
              erforderlich ist, muss auf der Bestellseite angegeben werden. Die Verkäufer bestätigen ferner,
              dass alle Informationen, die sie vom Käufer erhalten und die nicht gemeinfrei sind, für keinen
              anderen Zweck als für die Übergabe der Arbeit an den Käufer verwendet werden dürfen. Benutzer,
              die sich mit H2G beschäftigen und von dort aus kommunizieren, sind nicht durch unsere
              Nutzungsbedingungen geschützt. Authentisches H2G-Profil.
            </p>
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Sie dürfen auf H2G keine falsche Identität erstellen, Ihre Identität nicht falsch darstellen,
              kein H2G-Profil für andere Personen als Sie selbst (eine reale Person) erstellen oder das Konto
              oder die Informationen eines anderen Benutzers verwenden oder versuchen, diese zu verwenden.
              Ihre Profilinformationen, einschließlich Ihrer Beschreibung, Fähigkeiten, Ihres
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Standorts usw., sind zwar anonym, müssen jedoch korrekt und vollständig sein und dürfen nicht
              irreführend, illegal, anstößig oder auf andere Weise schädlich sein. H2G behält sich das Recht
              vor, von Benutzern zu verlangen, dass sie einen Überprüfungsprozess durchlaufen, um die Website
              zu nutzen (ob mit ID, Telefon, Kamera usw.).
            </p>
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Ansprüche auf geistiges Eigentum – H2G reagiert auf klare und vollständige Hinweise auf
              mutmaßliche Urheberrechts- oder Markenrechtsverletzungen und / oder Verstöße gegen die
              Nutzungsbedingungen Dritter. Unsere Verfahren zur Inanspruchnahme von geistigem Eigentum können
              hier eingesehen werden. Betrug / rechtswidrige Verwendung - Sie dürfen H2G nicht für
              rechtswidrige Zwecke oder zur Durchführung illegaler Aktivitäten verwenden.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Missbrauch und Spam, mehrere Konten - Um Betrug und Missbrauch zu verhindern, sind Benutzer auf
              ein aktives H2G-Konto beschränkt. Jedes zusätzliche Konto, das erstellt werden soll, um
              Richtlinien zu umgehen, Wettbewerbsvorteile zu fördern oder die H2G-Community irrezuführen, wird
              deaktiviert. Die Erstellung eines Massenkontos kann dazu führen, dass alle zugehörigen Konten
              deaktiviert werden. Hinweis: Verstöße gegen die Nutzungsbedingungen von H2G und / oder unsere
              Community-Standards führen zur dauerhaften Sperrung aller Konten.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Gezielter Missbrauch: Wir tolerieren keine Benutzer, die gezielt andere Benutzer auf H2G
              missbrauchen oder belästigen. Dies umfasst das Erstellen neuer Mehrfachkonten, um Mitglieder
              über unser Nachrichten- oder Bestellsystem zu belästigen. Verkauf von Konten - Sie dürfen keine
              H2G-Konten kaufen oder verkaufen.
            </p>
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Proprietäre Einschränkungen: Die Website, einschließlich ihres allgemeinen Layouts, Designs und
              Inhalts, ist ausschließlich Eigentum von H2G und durch Urheber- und Markenrecht geschützt.
              Helper2GO®, miniJob® und miniJobs® sind eingetragene Marken, die ausschließlich H2G gehören.
              Benutzer haben kein Recht und erklären sich ausdrücklich damit einverstanden, in Bezug auf die
              Website oder Teile, Komponenten oder Erweiterungen der Website (einschließlich ihrer mobilen
              Anwendungen) Folgendes nicht zu tun: (i) Kopieren, Übertragen, Anpassen, Ändern, Verteilen oder
              Reproduzieren , auf jede Art und Weise; (ii) Reverse Assemblieren, Dekompilieren, Reverse
              Engineering oder anderweitiger Versuch, den Quellcode, die zugrunde liegenden Ideen,
              Algorithmen, die Struktur oder die Organisation abzuleiten; (iii) abgeleitete Werke modifizieren
              oder erstellen; (iv) Entfernen von Urheberrechtshinweisen, Identifikationen oder anderen
              Eigentumsvermerken oder (v) die Website auf eine Weise nutzen, die die Website beschädigen,
              deaktivieren, überlasten oder beeinträchtigen oder die Nutzung der Website durch andere Benutzer
              beeinträchtigen könnte. Die Benutzer erklären sich auch damit einverstanden, niemandem zu
              gestatten oder zu autorisieren, die oben genannten Schritte auszuführen. Feedback-Rechte Soweit
              Sie H2G Kommentare, Vorschläge oder sonstiges Feedback zur H2G-Plattform oder zur gesamten
              Website sowie zu anderen H2G-Produkten oder -Dienstleistungen (kollektiv das „Feedback“) geben,
              gilt dies als gewährt H2G ein exklusives, lizenzgebührenfreies, voll bezahltes, unbefristetes,
              unwiderrufliches, weltweites Eigentumsrecht am Feedback. H2G ist nicht verpflichtet, Feedback
              von Benutzern zu implementieren. Vertraulich; Helper und Client sollten sich darüber im Klaren
              sein, dass die Gegenpartei möglicherweise bestimmte vertrauliche Informationen offenlegen
              müssen, die von der Gegenpartei zum Zwecke der Lieferung der bestellten Arbeit verwendet werden
              sollen, und diese vertraulichen Informationen vor unbefugter Verwendung und Offenlegung schützen
              müssen. Daher
            </p>
            <p
              style={{
                paddingTop: "2pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              erklären sich Helper und Client damit einverstanden, alle von der Gegenpartei erhaltenen
              Informationen als hochsensibles, streng geheimes und klassifiziertes Material zu behandeln. Ohne
              von der Allgemeinheit des Vorstehenden abzuweichen, erklären sich die Verkäufer ausdrücklich
              damit einverstanden, (i) alle diese Informationen streng vertraulich zu behandeln; (ii) die
              Informationen nicht an Dritte weitergeben; (iii) die Informationen nur für die Lieferung der
              bestellten Arbeit verwenden; und (vi) keine der Informationen ohne die Erlaubnis des Käufers zu
              kopieren oder zu reproduzieren.
            </p>
            <h1
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Allgemeine Geschäftsbedingungen
            </h1>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              H2G behält sich das Recht vor, Konten aufgrund von Verstößen gegen diese Nutzungsbedingungen und
              / oder unsere Community-Standards oder aufgrund einer illegalen oder unangemessenen Nutzung der
              Website oder der Dienste zu sperren oder Konten dauerhaft zu deaktivieren.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Verstöße gegen die Nutzungsbedingungen von H2G und / oder unsere Community- Standards können
              dazu führen, dass Ihr Konto dauerhaft deaktiviert wird.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "14pt", textIndent: "-8pt", textAlign: "left"}}>
              Benutzer mit deaktivierten Konten können auf H2G weder inserieren noch annehmen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Benutzer, die gegen unsere Nutzungsbedingungen und / oder unsere Community- Standards verstoßen
              haben und deren Konto deaktiviert wurde, können sich an unser Kundensupport-Team wenden, um
              weitere Informationen zu Verstößen und dem Status des Kontos zu erhalten.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Benutzer haben die Möglichkeit, Kontosicherheitsfunktionen zu aktivieren, um ihr Konto vor
              unbefugter Verwendung zu schützen.
            </p>
            <p style={{textIndent: "0pt", textAlign: "left"}}>
              <br />
            </p>
          </li>
          <li data-list-text="•">
            <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
              Benutzer müssen in der Lage sein, ihren Kontoinhaber durch den Kundensupport zu überprüfen,
              indem sie Materialien bereitstellen, die den Besitz dieses Kontos belegen.
            </p>
          </li>
          <li data-list-text="•">
            <p
              style={{
                paddingTop: "14pt",
                paddingLeft: "5pt",
                textIndent: "0pt",
                textAlign: "left",
              }}>
              Streitigkeiten sollten mit den Streitbeilegungstools von H2G ('Beilegungscenter' auf der
              Bestellseite) oder durch Kontaktaufnahme mit dem H2G-Kundendienst behandelt werden.
            </p>
          </li>
        </ul>
        <p style={{textIndent: "0pt", textAlign: "left"}}>
          <br />
        </p>
        <h1 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>Nutzergenerierte Inhalte</h1>
        <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
          Benutzergenerierter Inhalt ("UGC") bezieht sich auf den von Benutzern hinzugefügten Inhalt im
          Gegensatz zu dem von der Site erstellten Inhalt. Alle von unseren Benutzern (Helper und Client) auf
          H2G hochgeladenen Inhalte sind benutzergenerierte Inhalte. H2G überprüft die vom Benutzer
          hochgeladenen / erstellten Inhalte nicht auf Angemessenheit, Verstöße gegen Urheberrechte, Marken,
          andere Rechte oder Verstöße, und der Benutzer, der solche Inhalte hochlädt / erstellt, ist allein
          dafür verantwortlich und für die Folgen der Verwendung, Offenlegung, Speicherung oder Übertragung.
          Durch das Hochladen oder Erstellen von Inhalten auf der H2G-Plattform erklären Sie und garantieren,
          dass Sie alle Rechte, Lizenzen, Zustimmungen, Berechtigungen, Befugnisse und / oder Befugnisse
          besitzen oder erhalten haben, die zur Verwendung und / oder zum Hochladen solcher Inhalte und
          dergleichen erforderlich sind Inhalte oder deren Verwendung auf der Website verletzen und verletzen
          (a) kein geistiges Eigentum, Eigentums- oder Datenschutz-, Datenschutz- oder Publizitätsrechte
          Dritter; (b) gegen geltende lokale, staatliche, föderale und internationale Gesetze,
        </p>
        <p
          style={{
            paddingTop: "2pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}>
          Vorschriften und Konventionen verstoßen; und / oder (c) gegen Richtlinien und / oder
          Nutzungsbedingungen Ihrer oder Dritter verstoßen. Wir laden alle ein, Verstöße zusammen mit dem
          entsprechenden Eigentumsnachweis zu melden. Gemeldete Verstöße können entfernt oder deaktiviert
          werden. Darüber hinaus ist H2G nicht verantwortlich für den Inhalt, die Qualität oder das
          Serviceniveau der Client und Helper. Wir geben keine Garantie in Bezug auf den miniJob, deren
          Lieferung, jegliche Kommunikation zwischen Helper und Client. Wir ermutigen Benutzer, unsere
          Community und unseren gesunden Menschenverstand bei der Auswahl geeigneter Dienste zu nutzen. Durch
          das Anbieten eines Dienstes verpflichtet sich der Verkäufer, über ausreichende Berechtigungen,
          Rechte und / oder Lizenzen zu verfügen, um den auf H2G angebotenen Dienst bereitzustellen, zu
          verkaufen oder weiterzuverkaufen.
        </p>
        <p style={{textIndent: "0pt", textAlign: "left"}}>
          <br />
        </p>
        <h1 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>Gewährleistungsausschluss</h1>
        <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
          DIE NUTZUNG DER WEBSITE, IHRES INHALTS UND DER ÜBER DIE WEBSITE ERHALTENEN DIENSTLEISTUNGEN ODER
          ARTIKEL ERFOLGT AUF EIGENES RISIKO. DIE WEBSITE, IHR INHALT UND JEGLICHE DIENSTLEISTUNGEN ODER
          ARTIKEL, DIE ÜBER DIE WEBSITE ERHALTEN WERDEN, WERDEN "WIE BESEHEN" UND "WIE VERFÜGBAR" ZUR
          VERFÜGUNG GESTELLT, OHNE JEGLICHE AUSDRÜCKLICHE ODER STILLSCHWEIGENDE GEWÄHRLEISTUNGEN. WEDER H2G
          NOCH EINE MIT H2G VERBUNDENE PERSON ÜBERNIMMT EINE GARANTIE ODER ZUSICHERUNG IN BEZUG AUF
          VOLLSTÄNDIGKEIT, SICHERHEIT, ZUVERLÄSSIGKEIT, QUALITÄT, GENAUIGKEIT ODER VERFÜGBARKEIT DER WEBSITE.
        </p>
        <p
          style={{
            paddingTop: "14pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}>
          Das Vorstehende berührt keine Garantien, die nach geltendem Recht nicht ausgeschlossen oder
          eingeschränkt werden können. Maschinenübersetzung: Bestimmte benutzergenerierte Inhalte auf der
          Website wurden zu Ihrer Bequemlichkeit mit einer von Amazon Translate betriebenen
          Übersetzungssoftware übersetzt. Es wurden angemessene Anstrengungen unternommen, um eine genaue
          Übersetzung bereitzustellen. Keine automatisierte Übersetzung ist perfekt und soll auch keine
          menschlichen Übersetzer ersetzen. Solche Übersetzungen werden als Service für Benutzer der Website
          bereitgestellt und "wie sie sind" bereitgestellt. Es wird keine ausdrückliche oder stillschweigende
          Garantie in Bezug auf die Richtigkeit, Zuverlässigkeit oder Richtigkeit solcher Übersetzungen aus
          dem Englischen in eine andere Sprache übernommen. Einige benutzergenerierte Inhalte (wie Bilder,
          Videos, Flash usw.) werden aufgrund der Einschränkungen der Übersetzungssoftware möglicherweise
          nicht genau übersetzt oder überhaupt nicht übersetzt. Der offizielle Text ist die deutsche Version
          der Site. In der Übersetzung entstandene Unstimmigkeiten oder Unterschiede sind unverbindlich und
          haben keine rechtlichen Auswirkungen auf die Einhaltung oder Durchsetzung. Wenn Sie Fragen zur
          Richtigkeit, der im übersetzten Inhalt enthaltenen Informationen haben, lesen Sie bitte die
          englische Version des Inhalts, bei der es sich um die offizielle Version handelt.
        </p>
        <p style={{textIndent: "0pt", textAlign: "left"}}>
          <br />
        </p>
        <h1 style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>Haftungsbeschränkung</h1>
        <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
          H2G, SEINE MITGLIEDER ODER IHRE LIZENZGEBER, DIENSTLEISTER, MITARBEITER, VERTRETER, BEAMTEN,
          DIREKTOREN ODER DIREKTOREN HAFTEN IN KEINEM FALL FÜR SCHÄDEN JEGLICHER ART, DIE AUS EINER
          RECHTLICHEN THEORIE ENTSTEHEN DIE WEBSITE, JEGLICHE WEBSITES, DIE MIT IHM VERBUNDEN SIND, JEGLICHE
          INHALTE AUF DER WEBSITE ODER DIESE ANDEREN WEBSITES ODER DIENSTLEISTUNGEN ODER EINZELTEILE, DIE ÜBER
          DIE
        </p>
        <p
          style={{
            paddingTop: "2pt",
            paddingLeft: "5pt",
            textIndent: "0pt",
            textAlign: "left",
          }}>
          WEBSITE ODER DIESE ANDEREN WEBSITES ERHALTEN WERDEN BESCHRÄNKT AUF PERSÖNLICHE VERLETZUNGEN,
          SCHMERZEN UND LEIDEN, EMOTIONALE STÖRUNGEN, EINNAHMENVERLUSTE, GEWINNVERLUSTE, GESCHÄFTSVERLUSTE
          ODER VORAUSSICHTLICHE EINSPARUNGEN, VERLUST DER NUTZUNG, VERLUST DER GUTEN WILLEN, VERLUST DER DATEN
          UND OBEN VERTRAGSVERLETZUNG ODER ANDERWEITIG, AUCH WENN VORHERSAGBAR.
        </p>
        <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left"}}>
          DAS VORANGEGANGENE BEEINTRÄCHTIGT KEINE HAFTUNG, DIE NACH ANWENDBAREM RECHT NICHT AUSGESCHLOSSEN
          ODER BESCHRÄNKT WERDEN KANN.
        </p>
        <p style={{textIndent: "0pt", textAlign: "left"}}>
          <br />
        </p>
        <p style={{paddingLeft: "5pt", textIndent: "0pt", textAlign: "left", marginBottom: "20px"}}>
          Der Begriff „verbundenes Unternehmen“, auf den hier Bezug genommen wird, ist ein Unternehmen, das
          direkt oder indirekt H2G kontrolliert oder unter dessen Kontrolle steht oder unter gemeinsamer
          Kontrolle mit H2G steht, wobei Kontrolle bedeutet, mehr als fünfzig Prozent (50%) Stimmrechte zu
          haben oder sonstige Eigentumsanteile oder die Mehrheit der Stimmrechte eines solchen Unternehmens.
        </p>
      </div>
    </div>
  );
};

export default TermsNConditionsText;
