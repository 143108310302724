// HOOKS
import {SyntheticEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store";
import {useTranslation} from "react-i18next";

// UI
import {Box, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import {CustomTabPanel} from "../../Mobile/MyJobs";
import {theme} from "../../../styles/theme";
import JobRequest from "./JobRequest";

// SERVICES & UTILS
import {IRequest, IRequestAPI} from "../../../interfaces/IRequest";
import {setMyRequests} from "../../../store/Slices/RequestsSlice";
import RequestServices from "../../../services/RequestServices";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HelpersSection = () => {
  const [isLoading, setLoading] = useState(false);
  const {t} = useTranslation();

  // TAB HANDLERS
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {id} = useAppSelector(state => state.user);
  const requests = useAppSelector(state => state.requests);
  const dispatch = useAppDispatch();

  // GET MY REQUESTS
  async function getMyRequests() {
    setLoading(true);
    try {
      const resp = await RequestServices.GetRequestsWithFilters({helper: id});

      // SUCCESS
      if (resp.status === 200) {
        const arrOfRequests = resp.data as IRequestAPI[];
        const allMyRequests: IRequest[] = arrOfRequests.map((item: IRequestAPI) => ({
          id: item._id,
          client: item.client,
          counterOffers: item.counterOffers,
          createdAt: item.createdAt,
          helper: item.helper,
          job: item.job,
          note: item.note,
          status: item.status,
          updatedAt: item.updatedAt,
        }));
        dispatch(setMyRequests(allMyRequests));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    if (id) {
      getMyRequests();
    }
  }, [id]);
  return (
    <>
      <Box sx={{padding: 3, borderRadius: 4, filter: "blur(40)", backgroundColor: "rgba(9, 44, 72, 0.08)"}}>
        {/* TABS FOR REQUESTS PENDING / APPROVED / DECLINED */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          sx={{borderBottom: `1px solid ${theme.palette.primary.main}`}}>
          <Tab label={t("Pending Requests")} {...a11yProps(0)} />
          <Tab label={t("Approved Requests")} {...a11yProps(1)} />
        </Tabs>

        {/* PENDING REQUESTS */}
        <CustomTabPanel value={value} index={0}>
          {/* LOADER */}
          {isLoading ? (
            <>
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
            </>
          ) : null}

          {/* MAPPING ARRAY PENDING REQUESTS */}
          {!isLoading &&
            requests
              .filter(item => item.status === "PENDING")
              .map(request => <JobRequest key={request.id} request={request} />)}
          {!isLoading && !requests.length && (
            <Typography textAlign={"center"}>{t("No pending request yet.")}</Typography>
          )}
        </CustomTabPanel>

        {/* APPROVED REQUESTS */}
        <CustomTabPanel value={value} index={1}>
          {/* LOADER */}
          {isLoading ? (
            <>
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
            </>
          ) : null}

          {/* MAPPING ARRAY APPROVED REQUESTS */}
          {!isLoading &&
            requests
              .filter(item => item.status === "APPROVED")
              .map(request => <JobRequest key={request.id} request={request} />)}
          {!isLoading && !requests.length && (
            <Typography textAlign={"center"}>{t("No approved request yet.")}</Typography>
          )}
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default HelpersSection;
