// UI
import {Box} from "@mui/material";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />
        <LoginForm />
      </Box>
    </>
  );
};

export default Login;
