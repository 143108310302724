// HOOOKS
import {useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector, useAppDispatch} from "../../../store";

// UI
import MCounterOfferActionButtons from "../../../components/Mobile/MCounterOfferActions";
import MAddCounterOfferModal from "../../../components/Mobile/MAddCounterOfferModal";
import {ReactComponent as PlusIcon} from "../../../assets/Icons/plus.svg";
import CounterOffer from "../../../components/Desktop/CounterOffer";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import {Avatar, Box, Button, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import {updateRequest} from "../../../store/Slices/RequestsSlice";
import RequestServices from "../../../services/RequestServices";
import {IRequestAPI} from "../../../interfaces/IRequest";
import {updateJob} from "../../../store/Slices/JobsSlice";
import JobServices from "../../../services/JobServices";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

const MCounterOffers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const {id} = useAppSelector(state => state.user);
  const [isLoading, setLoading] = useState({load: false, declineLoad: false, acceptLoad: false});

  // REQUEST PASS THROUGH NAVIGATION, FOR CLIENT
  const location = useLocation();
  const requestFromNavigation: IRequestAPI = location.state;

  // FIND REQUEST FROM STORE, FOR HELPER
  const requests = useAppSelector(state => state.requests);
  const [searchParams] = useSearchParams();
  const requestFromStore = requests.find(item => {
    if (item.id === searchParams.get("request_id")) {
      return {_id: item.id, ...item};
    }
  }) as IRequestAPI | undefined;

  // MODAL HANDLERS - ADD COUNTER OFFER
  const [openCounterOffer, setOpenCounterOffer] = useState(false);
  const handleShowCounterOffer = () => setOpenCounterOffer(true);
  const handleCloseCounterOffer = () => setOpenCounterOffer(false);

  function jobRequestDetails() {
    if (requestFromStore) {
      return requestFromStore;
    }
    if (requestFromNavigation) {
      return requestFromNavigation;
    }
  }

  // UPDATE REQUEST
  async function updateThisRequest(status: "APPROVED" | "DECLINED") {
    try {
      if (status === "APPROVED") {
        setLoading({load: true, acceptLoad: true, declineLoad: false});
      } else if (status === "DECLINED") {
        setLoading({load: true, acceptLoad: false, declineLoad: true});
      }
      const resp = await RequestServices.UpdateRequest(jobRequestDetails()?._id, {status});

      // SUCCESS
      if (resp.status === 200) {
        // APPROVE REQUEST & APPROVE JOB
        if (status === "APPROVED") {
          enqueueSnackbar(t("Request is accepted."), {variant: "success"});
          await JobServices.UpdateJob(jobRequestDetails()?.job._id, {
            status: "APPROVED",
            helper: jobRequestDetails()?.helper._id,
          });
          dispatch(updateRequest({id: jobRequestDetails()?._id, status}));
          dispatch(
            updateJob({
              id: jobRequestDetails()?.job._id,
              status: "APPROVED",
              helper: jobRequestDetails()?.helper._id,
            })
          );
        }

        // DECLINED REQUEST
        if (status === "DECLINED") {
          enqueueSnackbar(t("Request is declined."), {variant: "success"});
        }
        setLoading({load: false, acceptLoad: false, declineLoad: false});
      }
    } catch (error) {
      setLoading({load: false, acceptLoad: false, declineLoad: false});
      const err = error as AxiosError;
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("Counter Offers")} />

      {/* USER INFO */}

      {/* I'M CLIENT HERE */}
      {jobRequestDetails()?.client._id === id ? (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey[300]}`,
            marginBottom: 3,
            borderRadius: 2.5,
            padding: 2,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => navigate("/m/helper-profile", {state: jobRequestDetails()?.helper})}>
          <Avatar
            sx={{width: 48, height: 48, textTransform: "uppercase"}}
            src={jobRequestDetails()?.helper.profile}>
            {jobRequestDetails()?.helper.name.split("")[0]}
          </Avatar>
          <Box sx={{marginLeft: 2}}>
            <Typography variant="caption" color={theme.palette.primary.main}>
              Helper:
            </Typography>
            <Typography fontWeight={600}>{jobRequestDetails()?.helper.name}</Typography>
          </Box>
        </Box>
      ) : null}

      {/* I'M HERLPER HERE */}
      {jobRequestDetails()?.helper._id === id ? (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey[300]}`,
            marginBottom: 3,
            borderRadius: 2.5,
            padding: 2,
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => navigate("/m/client-profile", {state: jobRequestDetails()?.client})}>
          <Avatar
            sx={{width: 48, height: 48, textTransform: "uppercase"}}
            src={jobRequestDetails()?.client.profile}>
            {jobRequestDetails()?.client.name.split("")[0]}
          </Avatar>
          <Box sx={{marginLeft: 2}}>
            <Typography variant="caption" color={theme.palette.primary.main}>
              Client:
            </Typography>
            <Typography fontWeight={600}>{jobRequestDetails()?.client.name}</Typography>
          </Box>
        </Box>
      ) : null}

      {/* ALL COUNTER OFFERS */}
      {jobRequestDetails()?.counterOffers.map(counterOffer => (
        <CounterOffer
          key={counterOffer._id}
          counterOffer={counterOffer}
          isMe={counterOffer.offeredBy === id}
        />
      ))}

      <Box sx={{textAlign: "right"}}>
        <Button
          variant="text"
          sx={{marginLeft: "auto", textTransform: "none", fontSize: 16}}
          onClick={handleShowCounterOffer}>
          <PlusIcon style={{marginRight: 8, height: 16, width: 16}} />
          {t("Add your Counter offer")}
        </Button>

        {/* ACTION BUTTONS */}
        {jobRequestDetails()?.client._id === id ? (
          <MCounterOfferActionButtons
            isLoading={isLoading.load}
            acceptLoad={isLoading.acceptLoad}
            declineLoad={isLoading.declineLoad}
            onAccept={() => updateThisRequest("APPROVED")}
            onDecline={() => updateThisRequest("DECLINED")}
          />
        ) : null}
      </Box>

      {/* ADD COUNTER OFFER */}
      <MAddCounterOfferModal
        requestId={jobRequestDetails()?._id}
        open={openCounterOffer}
        closeModal={handleCloseCounterOffer}
      />
    </>
  );
};

export default MCounterOffers;
