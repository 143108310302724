// HOOKS
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// MUI
import {Box, Button, Grid, Typography} from "@mui/material";

// ASSETS
import {HELPER_EVERYWHERE_HOME, JOBS_EVERYWHERE_HOME} from "../../../../assets/Images";
import BoxPrimaryGradient from "../../../../components/Desktop/BoxPrimaryGradient";
import {theme} from "../../../../styles/theme";
import ZigzagHeading from "../ZigzagHeading";
import {isLoggedIn} from "../../../../utils/AuthUtils";
import LoginPopup from "../../../../components/Desktop/LoginPopup";

const HowItWorks = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  // MODAL HANLDERS - SIGN IN POP
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <div id="HowItWorks">
        <ZigzagHeading title={t("How It Works")} />
      </div>

      {/* CARD ONE */}
      <Grid container sx={{paddingX: 10, textAlign: "center"}} gap={6}>
        <Grid item sm={6} sx={{flexGrow: 1, flexShrink: 1, flexBasis: "calc(50% - 24px) !important"}}>
          <Box sx={{borderRadius: 4.5, overflow: "hidden"}}>
            <BoxPrimaryGradient>
              <Box sx={{padding: 3}}>
                <img src={JOBS_EVERYWHERE_HOME} height={290} alt="jobs-everywhere" />
                <Typography variant="h6" color={theme.palette.common.white} textTransform={"uppercase"}>
                  {t("Find Your miniJob")}
                </Typography>
              </Box>
            </BoxPrimaryGradient>
          </Box>
          <Typography variant="h6" my={2} textAlign={"center"} fontWeight={600}>
            {t("Find your miniJob today and use your skills, earn something on the side or be a hero.")}
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{borderRadius: 2.5, fontSize: 20, paddingX: 4}}
            onClick={() => navigate("/search")}>
            {t("Find Your miniJob")}
          </Button>
        </Grid>

        {/* CARD TWO */}
        <Grid item sm={6} sx={{flexGrow: 1, flexShrink: 1, flexBasis: "calc(50% - 24px) !important"}}>
          <Box sx={{borderRadius: 4.5, overflow: "hidden"}}>
            <BoxPrimaryGradient>
              <Box sx={{padding: 3}}>
                <img src={HELPER_EVERYWHERE_HOME} height={290} alt="helpers-everywhere" />
                <Typography variant="h6" color={theme.palette.common.white} textTransform={"uppercase"}>
                  {t("Advertise Your miniJob")}
                </Typography>
              </Box>
            </BoxPrimaryGradient>
          </Box>
          <Typography variant="h6" my={2} fontWeight={600}>
            {t(
              "Advertise your miniJob and find the right helper. As diverse as the tasks are, so are we people. The perfect match is just a click away."
            )}
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{borderRadius: 2.5, fontSize: 20, paddingX: 4}}
            onClick={() => {
              if (isLoggedIn()) {
                navigate("/my-jobs/new-job");
              } else {
                handleShow();
              }
            }}>
            {t("Advertise Your miniJob")}
          </Button>
        </Grid>
      </Grid>

      <LoginPopup open={open} closeModal={handleClose} />
    </>
  );
};

export default HowItWorks;
