// HOOKS
import {FC, useState} from "react";
import {useSpring, animated} from "@react-spring/web";
import {useSwipeable} from "react-swipeable";

// UI
import {Box, Button, Typography} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";

// ASSETS & ICONS
import {ReactComponent as NotificationStarIcon} from "../../../assets/Icons/nofication-star.svg";
import {ReactComponent as TrashIcon} from "../../../assets/Icons/trash.svg";
import {theme} from "../../../styles/theme";

const MNotifications = () => {
  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel="Notifications" />

      <Box>
        <Typography fontSize={18} marginBottom={1}>
          Today
        </Typography>
        <NotificationCard title="The Client rejected you for job" subtitle="See Details" />
        <Typography fontSize={18} marginY={1}>
          Yesterday
        </Typography>
        <NotificationCard title="The Client rejected you for job" subtitle="See Details" />
        <Typography fontSize={18} marginY={1}>
          May 4th,2023
        </Typography>
        <NotificationCard title="The Client rejected you for job" subtitle="See Details" />
      </Box>
    </>
  );
};

export default MNotifications;

interface NotificationProps {
  title: string;
  subtitle: string;
}

const NotificationCard: FC<NotificationProps> = ({title, subtitle}) => {
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const springProps = useSpring({
    left: showDeleteButton ? -65 : 0, // Show delete button when true
  });

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setShowDeleteButton(false),
    onSwipedLeft: () => setShowDeleteButton(true),
    preventScrollOnSwipe: true,
  });
  return (
    <>
      <div style={{display: "flex", position: "relative"}}>
        <animated.div
          {...swipeHandlers}
          style={{
            padding: 8,
            backgroundColor: theme.palette.grey[100],
            borderRadius: 10,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            position: "relative",
            left: springProps.left,
            width: "100%",
            zIndex: 100,
          }}>
          <NotificationStarIcon />
          <Box sx={{marginLeft: 2}}>
            <Typography variant="body2" fontWeight={600}>
              {title}
            </Typography>
            <Typography variant="caption" color={theme.palette.grey[500]}>
              {subtitle}
            </Typography>
          </Box>
        </animated.div>
        <Button
          color="error"
          variant="contained"
          sx={{position: "absolute", top: 0, right: 0, height: "100%", boxShadow: "none", width: 80}}>
          <TrashIcon />
        </Button>
      </div>
    </>
  );
};
