import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import PrivacyPolicyText from "../../../components/Desktop/PrivacyPolicyText/PrivacyPolicyText";

const MPrivacyPolicy = () => {
  const {t} = useTranslation();

  return (
    <>
      <Box sx={{paddingX: 2}}>
        <HomeNavigationHeader isPrimary={false} screenLabel={t("Privacy Policy")} />

        <PrivacyPolicyText />
      </Box>
    </>
  );
};

export default MPrivacyPolicy;
