// HOOKS
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

// UI
import {Box} from "@mui/material";

// LAYOUT
import Footer from "../../../components/Desktop/Footer";
import Navbar from "../../../components/Desktop/Navbar";
import HeroSection from "./HeroSection";
import HowItWorks from "./HowItWorks";
import FAQs from "./FAQs";

// ASSETS
import {UI_RECTAGLES_HOME} from "../../../assets/Images";
import AboutUs from "./AboutUs";

// UTILS
import {checkDeviceType} from "../../../utils/AppUtils";
import {isLoggedIn} from "../../../utils/AuthUtils";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // MOBILE DEVICE
    if (checkDeviceType() === "M") {
      const isOnboarded: string | null = localStorage.getItem("onboard");
      // ONBOARDED ALREADY & LOGGED
      if (Boolean(isOnboarded) && isLoggedIn()) {
        navigate("/m/home");
      }
      // ONBOARDED BUT NOT LOGGED IN
      else if (Boolean(isOnboarded)) {
        navigate("/m/sign-in");
      }
      // NEITHER ONBOARDED, NOR LOGGED IN
      else {
        navigate("/m/onboarding");
      }
    } else if (checkDeviceType() === "D") {
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#D2EBFF",
          backgroundImage: `url(${UI_RECTAGLES_HOME})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "500px",
        }}>
        <Navbar />
        <HeroSection />
        <HowItWorks />
        <AboutUs />
        <FAQs />
        <Footer />
      </Box>
    </>
  );
};

export default Home;
