// HOOKS
import {FC} from "react";
import {useTranslation} from "react-i18next";

// UI
import {Avatar, Box, Modal, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";

// ASSETS & ICONS
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";
import {IUserInJobSearch} from "../../../interfaces/IJob";

interface Props {
  open: boolean;
  closeModal: () => void;
  user?: IUserInJobSearch;
}

const ViewProfileModal: FC<Props> = ({open, closeModal, user}) => {
  const {t} = useTranslation();

  return (
    <Modal
      open={open}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      sx={{
        height: "100vh",
        overflowY: "scroll",
        display: "grid",
        placeItems: "center",
      }}>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          marginY: 3,
          width: "40%",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}>
        {/* HEADER */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography variant="h5" fontWeight={600} color={theme.palette.primary.main}>
            {t("User Profile")}
          </Typography>
          <CloseIcon style={{cursor: "pointer"}} onClick={closeModal} />
        </Box>

        <>
          <Box
            sx={{
              backgroundColor: theme.palette.common.white,
              borderRadius: 2.5,
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
              paddingTop: 1,
            }}>
            <Avatar sx={{height: 40, width: 40}} src={user?.profile}>
              {user?.name?.split("")[0]}
            </Avatar>
            <Typography variant="body1" fontWeight={600} ml={1}>
              {user?.name}
            </Typography>
          </Box>
          <Typography marginTop={2} fontWeight={600}>
            {t("Description")}
          </Typography>
          <Typography marginLeft={2} whiteSpace={"pre-wrap"}>
            {user?.description}
          </Typography>
        </>
      </Box>
    </Modal>
  );
};

export default ViewProfileModal;
