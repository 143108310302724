// HOOKS
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useAppSelector, useAppDispatch} from "../../../store";

// UI
import {Button, CircularProgress, Container, FormControl, FormLabel, TextField} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import {Controller, useForm} from "react-hook-form";

// SERVICES, UTILS & TYPES
import {updateUser} from "../../../store/Slices/UserSlice";
import AuthServices from "../../../services/AuthServices";
import {userSchema} from "./schema";
import {AxiosError} from "axios";

export interface IUserEditForm {
  name: string;
  email: string;
  phone: string;
  address: string;
  description: string;
  profile?: string;
}

const MEditProfile = () => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const {id, name, email, phone, address, description} = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors},
    control,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      address: "",
      description: "",
    },
    resolver: joiResolver(userSchema),
  });

  async function submitSignUp(formData: IUserEditForm) {
    setLoading(true);
    const {name, address, description} = formData;
    try {
      const resp = await AuthServices.UpdateMe({name, address, description});

      // SUCCESS
      if (resp.status === 200) {
        enqueueSnackbar(t("Profile updated successfully."), {variant: "success"});
        dispatch(updateUser({name, address, description}));
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      const message = (err.response?.data as {message: string})?.message;
      console.log(err);
      // EMAIL ALREADY REGISTERED
      if (err.response?.status === 400 && message === "Email is registered already.") {
        enqueueSnackbar(t("This email is already registered."), {variant: "error"});
      }
      // PHONE ALREADY REGISTERED
      else if (err.response?.status === 400 && message === "Phone is already registered.") {
        enqueueSnackbar(t("This phone number is already registered."), {variant: "error"});
      }
      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    setValue("name", name);
    setValue("email", email);
    setValue("phone", phone);
    setValue("address", address);
    setValue("description", description);
  }, []);
  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("Edit Profile")} />

      {/* FORM */}
      <Container disableGutters sx={{paddingBottom: 0}}>
        <form onSubmit={handleSubmit(submitSignUp)}>
          {/* NAME */}
          <FormControl fullWidth margin="dense">
            <FormLabel sx={{fontSize: 16, fontWeight: 600, marginBottom: 1}} htmlFor="name">
              {t("Name")}
            </FormLabel>
            <Controller
              name="name"
              control={control}
              render={({field}) => {
                return (
                  <TextField
                    id="name"
                    size="medium"
                    variant="outlined"
                    type="text"
                    {...field}
                    error={errors?.name ? true : false}
                    helperText={t(errors?.name?.message as string)}
                    placeholder={t("Name")}
                  />
                );
              }}
            />
          </FormControl>

          {/* EMAIL */}
          <FormControl fullWidth margin="dense">
            <FormLabel sx={{fontSize: 16, fontWeight: 600, marginBottom: 1}} htmlFor="email">
              {t("Email")}
            </FormLabel>
            <Controller
              name="email"
              control={control}
              render={({field}) => {
                return (
                  <TextField
                    id="email"
                    size="medium"
                    variant="outlined"
                    type="email"
                    disabled
                    defaultValue={email}
                    error={errors?.email ? true : false}
                    helperText={t(errors?.email?.message as string)}
                    placeholder="Email"
                  />
                );
              }}
            />
          </FormControl>

          {/* PHONE NUMBER */}
          <FormControl fullWidth margin="dense">
            <FormLabel sx={{fontSize: 16, fontWeight: 600, marginBottom: 1}} htmlFor="phone">
              {"Phone"}
            </FormLabel>
            <Controller
              name="phone"
              control={control}
              render={({field}) => {
                return (
                  <TextField
                    id="phone"
                    size="medium"
                    variant="outlined"
                    disabled
                    defaultValue={phone}
                    type={"tel"}
                    error={errors?.phone ? true : false}
                    helperText={t(errors?.phone?.message as string)}
                    placeholder="+1 000 000 000"
                  />
                );
              }}
            />
          </FormControl>

          {/* LOCATION */}
          <FormControl fullWidth margin="dense">
            <FormLabel sx={{fontSize: 16, fontWeight: 600, marginBottom: 1}} htmlFor="location">
              {t("Location")}
            </FormLabel>
            <Controller
              name="address"
              control={control}
              render={({field}) => {
                return (
                  <TextField
                    id="location"
                    size="medium"
                    variant="outlined"
                    type={"text"}
                    {...field}
                    error={errors?.address ? true : false}
                    helperText={t(errors?.address?.message as string)}
                    placeholder={t("Location here")}
                  />
                );
              }}
            />
          </FormControl>

          {/* DESCRIPTION */}
          <FormControl fullWidth margin="none" sx={{marginY: 1}}>
            <FormLabel sx={{fontSize: 16, fontWeight: 600, marginBottom: 1}} htmlFor="description">
              {t("Description")}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({field}) => {
                return (
                  <TextField
                    multiline
                    id="description"
                    size="medium"
                    variant="outlined"
                    type="text"
                    {...field}
                    error={errors?.description ? true : false}
                    helperText={t(errors?.description?.message as string)}
                    placeholder={t("Description here")}
                    rows={6}
                    sx={{
                      mb: 2,
                      "& .MuiInputBase-input": {
                        backgroundColor: "white",
                        borderRadius: 2.5,
                        padding: 2,
                      },
                      "& .MuiInputBase-root": {
                        padding: 0,
                      },
                    }}
                  />
                );
              }}
            />
          </FormControl>

          {/* SUBMIT BUTTON */}
          <FormControl sx={{display: "block", marginBottom: 4}}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={isLoading}
              sx={{
                fontSize: 16,
                borderRadius: 2.5,
                py: 1.5,
                textTransform: "none",
                fontWeight: 600,
                boxShadow: "4px 8px 24px 0px rgba(0, 0, 0, 0.25)",
              }}>
              {t("Save")}
              {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
            </Button>
          </FormControl>
        </form>
      </Container>
    </>
  );
};

export default MEditProfile;
