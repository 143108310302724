// HOOKS
import {FC, useState} from "react";
import {useAppSelector, useAppDispatch} from "../../../store";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import Joi from "joi";

// UI
import {theme} from "../../../styles/theme";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

// ASSETS & ICONS
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";
import {ReactComponent as NotesIcon} from "../../../assets/Icons/notes.svg";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import RequestServices from "../../../services/RequestServices";
import {AxiosError} from "axios";
import {updateCounterOffers} from "../../../store/Slices/RequestsSlice";

interface Props {
  requestId?: string;
  open: boolean;
  closeModal: () => void;
}

interface FormData {
  budget: number;
  budgetNote: string;
}

export const counterOfferForm = Joi.object({
  budget: Joi.number().empty().min(1).required().messages({
    "number.base": "Budget is required.",
    "number.required": "Budget is required.",
    "number.empty": "Budget is required.",
    "number.min": "Budget can't be zero.",
  }),
  budgetNote: Joi.string().empty().required().messages({
    "string.base": "Notes are required.",
    "string.required": "Notes are required.",
    "string.empty": "Notes are required.",
  }),
});

const MAddCounterOfferModal: FC<Props> = ({open, closeModal, requestId}) => {
  const [isLoading, setLoading] = useState(false);
  const {id} = useAppSelector(state => state.user);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm<FormData>({
    defaultValues: {
      budget: 0,
      budgetNote: "",
    },
    resolver: joiResolver(counterOfferForm),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {budget, budgetNote} = formData;

    try {
      const resp = await RequestServices.AddCounterOffer(requestId, {budget, budgetNote, offeredBy: id});

      // SUCCESS
      if (resp.status === 201) {
        console.log(resp);
        dispatch(updateCounterOffers(resp.data));
        enqueueSnackbar(t("Counter offer added sucessfully."), {variant: "success"});
        setLoading(false);
        closeModal();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      const err = error as AxiosError;
      console.log(err.response);
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            boxShadow: 24,
            p: 2,
            borderRadius: 2,
          }}>
          {/* HEADER */}
          <Box
            sx={{top: 10, right: 10, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <Typography fontWeight={600}>{t("Counter Offer")}</Typography>
            <CloseIcon style={{cursor: "pointer", height: 24, width: 24}} onClick={closeModal} />
          </Box>

          <Box>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* NEW BUDGET */}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="budget"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="budget"
                        size="medium"
                        variant="outlined"
                        type="number"
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NotesIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={errors?.budget ? true : false}
                        helperText={t(errors?.budget?.message as string)}
                        placeholder={t("Your Budget")}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* BUDGET NOTES */}
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="budgetNote"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        multiline
                        id="budgetNote"
                        size="medium"
                        variant="outlined"
                        type="text"
                        {...field}
                        error={errors?.budgetNote ? true : false}
                        helperText={t(errors?.budgetNote?.message as string)}
                        placeholder={t("Budget notes")}
                        rows={4}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* SUBMIT BUTTON */}
              <FormControl sx={{display: "block", marginTop: 3, textAlign: "center"}}>
                <Button
                  variant="contained"
                  sx={{textTransform: "none", borderRadius: 2.5, paddingX: 4}}
                  disabled={isLoading}
                  type="submit">
                  {t("Send Offer")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MAddCounterOfferModal;
