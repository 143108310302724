// HOOKS
import {FC, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

// UI
import {Box, Typography} from "@mui/material";

// ASSETS & ICONS
import {LOGO_HANDS} from "../../../assets/Images";
import {ReactComponent as NotificationIcon} from "../../../assets/Icons/notification.svg";
import {ReactComponent as GoBackIcon} from "../../../assets/Icons/go-back-arrow.svg";
import {theme} from "../../../styles/theme";

interface Props {
  primaryText?: string;
  screenLabel?: string;
  isPrimary: boolean;
  showNotification?: boolean;
  CustomHeaderBtn?: ReactNode;
}

const HomeNavigationHeader: FC<Props> = ({
  isPrimary,
  primaryText,
  showNotification,
  screenLabel,
  CustomHeaderBtn,
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {/* HEADER WITH LOGO */}
      {isPrimary ? (
        <Box sx={{display: "flex", alignItems: "center", marginY: 2}}>
          <img src={LOGO_HANDS} alt="logo-hands" style={{height: 60, width: 60, marginRight: 10}} />
          {primaryText ? (
            <Typography variant="h5" color={theme.palette.primary.main} fontWeight={600}>
              {t(primaryText)}
            </Typography>
          ) : null}
          {CustomHeaderBtn ? CustomHeaderBtn : null}
        </Box>
      ) : null}

      {/* HEADER WITHOUT LOGO */}
      {!isPrimary ? (
        <Box sx={{display: "flex", alignItems: "center", marginY: 2, height: 60}}>
          <GoBackIcon style={{height: 20, width: 20, marginRight: 16}} onClick={() => navigate(-1)} />
          {screenLabel ? (
            <Typography variant="h6" color={theme.palette.common.black} fontWeight={600}>
              {screenLabel}
            </Typography>
          ) : null}
          {CustomHeaderBtn ? CustomHeaderBtn : null}
        </Box>
      ) : null}
    </>
  );
};

export default HomeNavigationHeader;
