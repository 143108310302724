// HOOKS
import {FC, SVGProps, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// UI
import {Container, Link, Typography} from "@mui/material";
import SignInPopup from "./SignInPopup";

// ASSETS & ICONS
import {ReactComponent as HomeIcon} from "../../../assets/Icons/home.svg";
import {ReactComponent as MyJobsIcon} from "../../../assets/Icons/my-jobs.svg";
import {ReactComponent as SearchIcon} from "../../../assets/Icons/search.svg";
import {ReactComponent as ProfileIcon} from "../../../assets/Icons/profile.svg";
import {theme} from "../../../styles/theme";

const HomeBottomNavigation = () => {
  const {t} = useTranslation();

  // MODAL HANLDERS - SIGN IN POP
  const [open, setOpen] = useState(false);
  const handleShow = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Container
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          borderTop: "1px solid #B9B9B9",
          borderRadius: "24px 24px 0px 0px",
          zIndex: 100,
          backgroundColor: theme.palette.common.white,
          maxWidth: 600,
        }}>
        <NavItem label={t("Home")} Icon={HomeIcon} link="/m/home" showSignInPopup={handleShow} />
        <NavItem label={t("My Jobs")} Icon={MyJobsIcon} link="/m/my-jobs" showSignInPopup={handleShow} />
        <NavItem label={t("Search")} Icon={SearchIcon} link="/m/search" showSignInPopup={handleShow} />
        <NavItem label={t("Profile")} Icon={ProfileIcon} link="/m/profile" showSignInPopup={handleShow} />
      </Container>

      {/* SIGN IN POPUP */}
      <SignInPopup open={open} closeModal={handleClose} />
    </>
  );
};

export default HomeBottomNavigation;

interface NavItemProps {
  label: string;
  Icon: FC<SVGProps<SVGSVGElement>>;
  link: "/m/home" | "/m/my-jobs" | "/m/search" | "/m/profile";
  showSignInPopup: () => void;
}

const NavItem: FC<NavItemProps> = ({label, Icon, link, showSignInPopup}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();

  // CURRENT ACTIVE ICON
  const activeIconColor = () => {
    if (pathname === link) return theme.palette.primary.main;
    else return theme.palette.grey[400];
  };

  // CURRENT ACTIVE LABEL
  const activeLabel = () => {
    if (pathname === link) return 600;
    else return 600;
  };

  // CHECK GUEST MODE
  function hanldeGuestModeNavigation() {
    const access_token = localStorage.getItem("access_token");
    if ((link === "/m/my-jobs" || link === "/m/profile") && access_token === "guest") {
      showSignInPopup();
    } else {
      navigate(link);
    }
  }

  return (
    <>
      <Link
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: activeIconColor(),
          cursor: "pointer",
          transition: "all 0.3s",
          textDecoration: "none",
          userSelect: "none",
        }}
        onClick={hanldeGuestModeNavigation}>
        <Icon fill={activeIconColor()} />
        <Typography fontWeight={activeLabel()} mt={0.5} fontSize={12}>
          {label}
        </Typography>
      </Link>
    </>
  );
};
