// HOOKS
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import {useTranslation} from "react-i18next";

// UI
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MAuthBanner from "../../../components/Mobile/MAuthBanner";

// SERVICES & UTILES
import {theme} from "../../../styles/theme";
import Joi from "joi";

// ASSETS
import {ReactComponent as USFlagIcon} from "../../../assets/Icons/us-flag.svg";
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";

export const verifyPhone = Joi.object({
  phone: Joi.string().empty().required().messages({
    "string.base": "Phone is required.",
    "string.required": "Phone is required.",
    "string.empty": "Phone is required.",
  }),
  agreedToTC: Joi.boolean().valid(true).required().messages({
    "any.only": "You must agreed to the Terms & Conditions.",
  }),
});

const MVerifyPhone = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      phone: "",
      agreedToTC: "",
    },
    resolver: joiResolver(verifyPhone),
  });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          marginX: "auto",
        }}>
        {/* HEADER */}
        <MAuthBanner />

        <Box>
          <Typography
            textAlign={"center"}
            fontWeight={600}
            color={theme.palette.primary.main}
            marginTop={3}
            marginBottom={4}>
            {t("Verify your number to continue")}
          </Typography>
          <form onSubmit={handleSubmit(() => navigate("/m/verify-otp"))}>
            {/* PHONE NUMBER */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="phone"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="phone"
                      size="medium"
                      variant="outlined"
                      type={"tel"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" sx={{marginRight: 1.5}}>
                            <USFlagIcon />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.phone ? true : false}
                      helperText={errors?.phone ? errors?.phone?.message : null}
                      placeholder="+1 000 000 000"
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* AGREED TO T&C */}
            <FormControl>
              <FormControlLabel
                label={t("By entering my number, I agree to Helper 2 Go’s Terms & Conditions")}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                  },
                }}
                control={
                  <Controller
                    name="agreedToTC"
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        color="primary"
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxCheckedIcon />}
                      />
                    )}
                  />
                }
              />
            </FormControl>
            <Typography variant="body2" color="error">
              {errors.agreedToTC?.message}
            </Typography>

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 15}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{
                  fontSize: 16,
                  borderRadius: 2.5,
                  py: 1.5,
                  boxShadow: "4px 8px 24px 0px rgba(0, 0, 0, 0.25)",
                  textTransform: "none",
                }}>
                Next
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default MVerifyPhone;
