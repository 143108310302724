// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {useAppSelector} from "../../../store";

// UI
import {Box, Button, CircularProgress, FormControl, FormHelperText, Typography} from "@mui/material";
import {MuiOtpInput} from "mui-one-time-password-input";
import MAuthBanner from "../../../components/Mobile/MAuthBanner";

// SERVICES & UTILS
import AuthServices from "../../../services/AuthServices";
import {AxiosError} from "axios";

// ASSETS
import {theme} from "../../../styles/theme";
import MVerifiedModal from "../../../components/Mobile/VerifiedModal";

interface FormData {
  otp: string;
}

const MVerifyOTP = () => {
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const location = useLocation();
  const userFromNavigation = location.state as {email?: string};
  const navigate = useNavigate();
  const {t} = useTranslation();
  const user = useAppSelector(state => state.user);

  const [searchParams] = useSearchParams();
  const verification_channel = searchParams.get("verification_channel") as null | "email" | "phone";
  const verification_path = searchParams.get("verification_path") as null | "resetPassword" | "account";

  // MODAL HANDLERS - VERIFIED MODAL
  const [openVerified, setOpenVerified] = useState(false);
  const handleShowVerified = () => setOpenVerified(true);
  const handleCloseVerified = () => {
    setOpenVerified(false);
    navigate("/m/home");
  };

  const {
    control,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);

    // VERIFY OTP FOR EMAIL VERIFICATION OF THE ACCOUNT
    if (verification_path === "account") {
      try {
        const resp = await AuthServices.VerifyOTP("email", {email: user.email, otp: formData.otp});

        // SUCCESS
        if (resp.status === 200) {
          localStorage.setItem("access_token", user.access_token);
          enqueueSnackbar(t("Signed up successfully."), {variant: "success"});
          handleShowVerified();
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        const message = (err.response?.data as {message: string})?.message;

        // INVALID OTP
        if (err.response?.status === 400 && message === "Invalid OTP") {
          enqueueSnackbar(t("Invalid OTP."), {variant: "error"});
        }
        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
      }
    }

    // VERIFY OTP FOR RESET PASSWORD
    if (verification_path === "resetPassword") {
      try {
        const resp = await AuthServices.VerifyOTP("email", {
          email: userFromNavigation.email,
          otp: formData.otp,
        });

        // SUCCESS
        if (resp.status === 200) {
          navigate("/m/new-password", {state: userFromNavigation});
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        const message = (err.response?.data as {message: string})?.message;

        // INVALID OTP
        if (err.response?.status === 400 && message === "Invalid OTP") {
          enqueueSnackbar(t("Invalid OTP."), {variant: "error"});
        }
        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
      }
    }
  }

  // RESEND OTP
  async function resendOTP() {
    if (verification_channel === "email") {
      setLoading2(true);
      reset();

      try {
        const resp = await AuthServices.SendOTP("email", {email: userFromNavigation?.email || user.email});

        // SUCCESS
        if (resp.status === 200) {
          setLoading2(false);
          enqueueSnackbar(t("New OTP has been sent."), {variant: "info"});
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;

        // EMAIL NOT FOUND
        if (err.response?.status === 404) {
          enqueueSnackbar(t("This email does not exist."), {variant: "error"});
        }

        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          marginX: "auto",
        }}>
        {/* HEADER */}
        <MAuthBanner />

        {/* FORM */}
        <Box>
          <Typography
            textAlign={"center"}
            fontWeight={600}
            color={theme.palette.primary.main}
            marginTop={3}
            marginBottom={4}>
            {t("Enter the 4-digit OTP sent to you")}
          </Typography>
          <form onSubmit={handleSubmit(submitForm)}>
            {/* OTP FIELD */}
            <Controller
              name="otp"
              control={control}
              rules={{validate: value => value.length === 4}}
              render={({field}) => (
                <Box sx={{display: "grid", placeItems: "center"}}>
                  <MuiOtpInput
                    {...field}
                    inputMode="tel"
                    length={4}
                    sx={{
                      "& .MuiOtpInput-TextField": {
                        width: 55,
                        height: 55,
                      },
                      gap: 3,
                    }}
                  />
                </Box>
              )}
            />
            {errors.otp ? (
              <FormHelperText sx={{marginTop: 2, "& .MuiFormHelperText-root": {textAlign: "left"}}} error>
                {t("The OTP passcode you’ve entered is incorrect.")}
              </FormHelperText>
            ) : null}

            <Button
              sx={{textTransform: "none", marginTop: 15, marginX: "auto", display: "block"}}
              onClick={resendOTP}
              disabled={isLoading2}>
              {t("I haven’t received the code, RESEND.")}
              {isLoading2 ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
            </Button>

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 4}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{fontSize: 16, borderRadius: 2.5, py: 1}}>
                {t("Continue")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>

      {/* VERIFIED MODAL */}
      <MVerifiedModal
        open={openVerified}
        handleClose={handleCloseVerified}
        verificationType="otp"
        btnText="Redirect to Home"
      />
    </>
  );
};

export default MVerifyOTP;
