// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../store";

// SERVICES & UTILS
import AuthServices from "../../../services/AuthServices";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

// UI
import {Box, Button, CircularProgress, FormControl, FormHelperText, Typography} from "@mui/material";
import VerifiedModal from "../../../components/Desktop/VerifiedModal";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import {MuiOtpInput} from "mui-one-time-password-input";
import AuthHeader from "../Register/AuthHeader";
import {theme} from "../../../styles/theme";

// ASSETS
import {ALRIGHT_AUTH, TOP_RIGHT_BLOB} from "../../../assets/Images";

interface FormData {
  otp: string;
}

const VerifyOTP = () => {
  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);

  const location = useLocation();
  const userFromNavigation = location.state as {email?: string};
  const navigate = useNavigate();
  const {t} = useTranslation();
  const user = useAppSelector(state => state.user);

  const [searchParams] = useSearchParams();
  const verification_channel = searchParams.get("verification_channel") as null | "email" | "phone";
  const verification_path = searchParams.get("verification_path") as null | "resetPassword" | "account";

  const {
    control,
    handleSubmit,
    formState: {errors, isDirty, isValid},
    reset,
  } = useForm({
    defaultValues: {
      otp: "",
    },
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);

    // VERIFY OTP FOR EMAIL VERIFICATION OF THE ACCOUNT
    if (verification_path === "account") {
      try {
        const resp = await AuthServices.VerifyOTP("email", {email: user.email, otp: formData.otp});

        // SUCCESS
        if (resp.status === 200) {
          localStorage.setItem("access_token", user.access_token);
          navigate("/");
          enqueueSnackbar(t("Signed up successfully."), {variant: "success"});
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        const message = (err.response?.data as {message: string})?.message;

        // INVALID OTP
        if (err.response?.status === 400 && message === "Invalid OTP") {
          enqueueSnackbar(t("Invalid OTP."), {variant: "error"});
        }
        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
      }
    }

    // VERIFY OTP FOR RESET PASSWORD
    if (verification_path === "resetPassword") {
      try {
        const resp = await AuthServices.VerifyOTP("email", {
          email: userFromNavigation.email,
          otp: formData.otp,
        });

        // SUCCESS
        if (resp.status === 200) {
          navigate("/reset-password", {state: userFromNavigation});
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        console.log("----err----", err.response?.data);
        const message = (err.response?.data as {message: string})?.message;

        // INVALID OTP
        if (err.response?.status === 400 && message === "Invalid OTP") {
          enqueueSnackbar(t("Invalid OTP."), {variant: "error"});
        }
        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
      }
    }
  }

  // RESEND OTP
  async function resendOTP() {
    if (verification_channel === "email") {
      setLoading2(true);
      reset();

      try {
        const resp = await AuthServices.SendOTP("email", {email: userFromNavigation?.email || user.email});

        // SUCCESS
        if (resp.status === 200) {
          setLoading2(false);
          enqueueSnackbar(t("New OTP has been sent."), {variant: "info"});
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;

        // EMAIL NOT FOUND
        if (err.response?.status === 404) {
          enqueueSnackbar(t("This email does not exist."), {variant: "error"});
        }

        // INTERNET ISSUE
        else if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
      }
    }
  }

  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <AuthHeader title={t("Enter the 4-digit OTP sent to you")} headline={ALRIGHT_AUTH} />

          <Box py={6} px={10} pr={20} display={"flex"} justifyContent={"center"}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* OTP FIELD */}
              <Controller
                name="otp"
                control={control}
                rules={{validate: value => value.length === 4}}
                render={({field, fieldState}) => (
                  <Box>
                    <MuiOtpInput
                      {...field}
                      length={4}
                      sx={{
                        "& .MuiOtpInput-TextField": {
                          width: 60,
                          height: 60,
                        },
                        gap: 8,
                      }}
                    />
                    {fieldState.invalid ? <FormHelperText error>{t("Inavlid OTP.")}</FormHelperText> : null}
                  </Box>
                )}
              />

              {/* SUBMIT BUTTON */}
              <FormControl sx={{display: "block", marginTop: 15}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                  disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                  {t("Continue")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>
          </Box>

          {/* ALREADY HAVE AN ACCOUNT */}
          <Box mt={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="body2" fontWeight={500} color={theme.palette.grey[400]} mr={1}>
              {t("I haven't recieved the code.")}
            </Typography>
            <Button
              variant="outlined"
              sx={{px: 2, borderRadius: 2.5}}
              onClick={resendOTP}
              disabled={isLoading2}>
              {t("Resend")}
              {isLoading2 ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
            </Button>
          </Box>

          {/* GREY TEXT */}
          <Box my={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              px={8}
              variant="body2"
              fontWeight={500}
              color={theme.palette.grey[400]}
              mr={1}
              textAlign={"center"}>
              {t(`Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae quaerat maiores consequuntur
              nostrum ex aut debitis saepe provident soluta a!`)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyOTP;
