// HOOKS
import {ChangeEvent, FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useAppDispatch} from "../../../store";

// UI
import {Box, Button, CircularProgress, FormControl, Modal, TextField, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import {deleteThisImage, uploadThisImage} from "../../../utils/FirebaseUtil";
import {updateProfileSchema} from "../../Mobile/EditProfile/schema";
import {updateUser} from "../../../store/Slices/UserSlice";
import AuthServices from "../../../services/AuthServices";
import {IUserData} from "../../../interfaces/IUser";
import {joiResolver} from "@hookform/resolvers/joi";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

// ASSETS & ICONS
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";

interface Props {
  user: IUserData;
  open: boolean;
  handleClose: () => void;
  isMobileView?: boolean;
}

interface FormData {
  profile: string;
}

const UpdateProfileModal: FC<Props> = ({handleClose, open, user, isMobileView}) => {
  const [isLoading, setLoading] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    setValue,
    formState: {errors},
    reset,
    watch,
  } = useForm({
    defaultValues: {
      profile: "",
    },
    resolver: joiResolver(updateProfileSchema),
  });

  async function handleChangeProfileInput(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = async e => {
        const dataUrl = e.target?.result;
        if (dataUrl) {
          setValue("profile", dataUrl.toString());
        }
      };
      reader.readAsDataURL(file);
    }
  }

  // SUBMIT FORM TO UPDATE PROFILE PICTURE
  async function submitForm(formData: FormData) {
    setLoading(true);
    try {
      const uploadedImageURL = await uploadThisImage(formData.profile, "Profile");

      const resp = await AuthServices.UpdateMe({profile: uploadedImageURL});

      // SUCCESS
      if (resp.status === 200) {
        await deleteThisImage(user.profile);
        enqueueSnackbar(t("Profile updated successfully."), {variant: "success"});
        dispatch(updateUser({profile: uploadedImageURL}));
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableAutoFocus
      sx={{
        height: "100vh",
        overflowY: "scroll",
        display: "grid",
        placeItems: "center",
      }}>
      <Box
        sx={{
          backgroundColor: theme.palette.common.white,
          marginY: 3,
          width: isMobileView ? "70%" : "40%",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}>
        {/* HEADER */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Typography
            variant={isMobileView ? "h6" : "h5"}
            fontWeight={600}
            color={theme.palette.primary.main}>
            {t("Upload Profile Picture")}
          </Typography>
          <CloseIcon
            onClick={() => {
              handleClose();
              reset();
            }}
          />
        </Box>

        {/* BODY */}
        <Box
          sx={{
            marginTop: 2,
          }}>
          <form onSubmit={handleSubmit(submitForm)}>
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <TextField
                id="name"
                size="medium"
                variant="outlined"
                type="file"
                error={errors?.profile ? true : false}
                helperText={t(errors?.profile?.message || "")}
                placeholder="Image"
                sx={{
                  mb: 2,
                  "& .MuiInputBase-input": {
                    backgroundColor: "white",
                    borderRadius: 2.5,
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: "#D2EBFF !important",
                  },
                }}
                inputProps={{accept: "image/*"}}
                onChange={handleChangeProfileInput}
              />
            </FormControl>

            {watch("profile") && (
              <Box sx={{height: 130, width: 130, borderRadius: "50%", overflow: "hidden", margin: "auto"}}>
                <img
                  src={watch("profile")}
                  alt="image-selected"
                  width={"100%"}
                  height={"100%"}
                  style={{objectFit: "cover"}}
                />
              </Box>
            )}

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 4}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{fontSize: 18, borderRadius: 2.5, py: 1}}
                disabled={isLoading || !watch("profile")}>
                {t("Save")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateProfileModal;
