// HOOKS & ROUTER DOM
import {Navigate, Outlet} from "react-router-dom";

// UTILS
import {isLoggedIn} from "../../utils/AuthUtils";

const PubliceRoutes = () => {
  if (isLoggedIn()) {
    return <Navigate to={"/"} />;
  }
  return <Outlet />;
};
const MPubliceRoutes = () => {
  if (isLoggedIn()) {
    return <Navigate to={"/m/home"} />;
  }
  return <Outlet />;
};

export {PubliceRoutes, MPubliceRoutes};
