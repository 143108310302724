// HOOKS
import {useState} from "react";
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAppSelector, useAppDispatch} from "../../../store";

// UI
import MCounterOfferActionButtons from "../../../components/Mobile/MCounterOfferActions";
import MAddCounterOfferModal from "../../../components/Mobile/MAddCounterOfferModal";
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";
import {Avatar, Box, Button, Container, Typography, responsiveFontSizes} from "@mui/material";
import CounterOffer from "../../../components/Desktop/CounterOffer";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import MSplitText from "../../../components/Mobile/MSplitText";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import RequestServices from "../../../services/RequestServices";
import JobServices from "../../../services/JobServices";
import {enqueueSnackbar} from "notistack";
import moment from "moment";

//ASSETS & ICONS
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import {ReactComponent as PlusIcon} from "../../../assets/Icons/plus.svg";
import {calculateDuration} from "../../../utils/JobUtils";
import {AxiosError} from "axios";
import {updateJob} from "../../../store/Slices/JobsSlice";
import {updateRequest} from "../../../store/Slices/RequestsSlice";
import {IRequestAPI} from "../../../interfaces/IRequest";

const MJobRequestDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState({load: false, declineLoad: false, acceptLoad: false});
  const {id} = useAppSelector(state => state.user);
  const {t} = useTranslation();

  // REQUEST DETAILS FOR CLIENT, PASSED BY NAVIGATION
  const location = useLocation();
  const requestFromNavigation = location.state;

  // FIND REQUEST FOR HELPER FROM STORE
  const requests = useAppSelector(state => state.requests);
  const [searchParams] = useSearchParams();
  const requestFromStore = requests.find(item => {
    if (item.id === searchParams.get("request_id")) {
      return {_id: item.id, ...item};
    }
  }) as IRequestAPI | undefined;

  function jobRequestDetails() {
    if (requestFromStore) {
      return requestFromStore as IRequestAPI | undefined;
    }
    if (requestFromNavigation) {
      return requestFromNavigation as IRequestAPI | undefined;
    }
  }

  // MODAL HANDLERS - ADD COUNTER OFFER
  const [openCounterOffer, setOpenCounterOffer] = useState(false);
  const handleShowCounterOffer = () => setOpenCounterOffer(true);
  const handleCloseCounterOffer = () => setOpenCounterOffer(false);

  // UPDATE REQUEST
  async function updateThisRequest(status: "APPROVED" | "DECLINED") {
    try {
      if (status === "APPROVED") {
        setLoading({load: true, acceptLoad: true, declineLoad: false});
      } else if (status === "DECLINED") {
        setLoading({load: true, acceptLoad: false, declineLoad: true});
      }
      const resp = await RequestServices.UpdateRequest(jobRequestDetails()?._id, {status});

      // SUCCESS
      if (resp.status === 200) {
        // APPROVE REQUEST & APPROVE JOB
        if (status === "APPROVED") {
          enqueueSnackbar("Request is accepted.", {variant: "success"});
          await JobServices.UpdateJob(jobRequestDetails()?.job._id, {
            status: "APPROVED",
            helper: jobRequestDetails()?.helper._id,
          });
          dispatch(updateRequest({id: jobRequestDetails()?._id, status}));
          dispatch(
            updateJob({
              id: jobRequestDetails()?.job._id,
              status: "APPROVED",
              helper: jobRequestDetails()?.helper._id,
            })
          );
        }

        // DECLINED REQUEST
        if (status === "DECLINED") {
          enqueueSnackbar("Request is declined.", {variant: "success"});
        }
        setLoading({load: false, acceptLoad: false, declineLoad: false});
      }
    } catch (error) {
      setLoading({load: false, acceptLoad: false, declineLoad: false});
      const err = error as AxiosError;
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar("Make sure your internet is working.", {variant: "error"});
      } else {
        enqueueSnackbar("Something went wrong.", {variant: "error"});
      }
    }
  }

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("Job Request")} />

      {jobRequestDetails() ? (
        <>
          {/* JOB TITLE */}
          <Box
            sx={{
              borderRadius: 2.5,
              overflow: "hidden",
              color: theme.palette.common.white,
              position: "relative",
            }}>
            <BoxPrimaryGradient>
              <Box sx={{padding: 2}}>
                <Typography marginY={1}>{jobRequestDetails()?.job?.title}</Typography>
              </Box>
              <Box sx={{display: "flex", alignItems: "center", position: "absolute", top: 10, right: 10}}>
                <ClockIcon style={{width: 12, height: 12}} fill="#fff" />
                <Typography variant="caption" sx={{marginLeft: 0.5, fontSize: 10}}>
                  {moment(jobRequestDetails()?.job?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Box>
            </BoxPrimaryGradient>
          </Box>

          {/* REQUEST NOTE */}
          {jobRequestDetails()?.note ? (
            <Box
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                marginTop: 3,
                borderRadius: 2.5,
                padding: 2,
              }}>
              <Typography>{t("Request Note")}</Typography>
              <Typography fontSize={14} whiteSpace={"pre-wrap"}>
                {jobRequestDetails()?.note}
              </Typography>
            </Box>
          ) : null}

          {/* JOB DETAILS */}
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey[300]}`,
              marginTop: 3,
              borderRadius: 2.5,
              padding: 2,
            }}>
            {jobRequestDetails()?.job?.startOn && jobRequestDetails()?.job?.endOn ? (
              <MSplitText
                title={t("Timeline")}
                value={calculateDuration(jobRequestDetails()?.job?.startOn, jobRequestDetails()?.job?.endOn)}
              />
            ) : null}
            <MSplitText title={t("Budget")} value={jobRequestDetails()?.job?.budget?.toLocaleString()} />
            <MSplitText title={t("Experience")} value={jobRequestDetails()?.job?.experience} />
            <MSplitText title={t("Required Tools")} value={jobRequestDetails()?.job?.tools} />
          </Box>

          {/* JOB DESCRIPTION */}
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey[300]}`,
              marginTop: 3,
              borderRadius: 2.5,
              padding: 2,
            }}>
            <Typography>{t("Description")}</Typography>
            <Typography fontSize={14} whiteSpace={"pre-wrap"}>
              {jobRequestDetails()?.job?.description}
            </Typography>
          </Box>

          {/* USER INFO */}
          {/* I'M CLIENT HERE */}
          {jobRequestDetails()?.client?._id === id ? (
            <Box
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                marginTop: 3,
                borderRadius: 2.5,
                padding: 2,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigate("/m/helper-profile", {state: jobRequestDetails()?.helper})}>
              <Avatar
                sx={{width: 48, height: 48, textTransform: "uppercase"}}
                src={jobRequestDetails()?.helper?.profile}>
                {jobRequestDetails()?.helper?.name.split("")[0]}
              </Avatar>
              <Box sx={{marginLeft: 2}}>
                <Typography variant="caption" color={theme.palette.primary.main}>
                  Helper:
                </Typography>
                <Typography fontWeight={600}>{jobRequestDetails()?.helper?.name}</Typography>
              </Box>
            </Box>
          ) : null}

          {/* I'M HELPER HERE */}
          {jobRequestDetails()?.helper?._id === id ? (
            <Box
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                marginTop: 3,
                borderRadius: 2.5,
                padding: 2,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigate("/m/client-profile", {state: jobRequestDetails()?.client})}>
              <Avatar
                sx={{width: 48, height: 48, textTransform: "uppercase"}}
                src={jobRequestDetails()?.client?.profile}>
                {jobRequestDetails()?.client?.name.split("")[0]}
              </Avatar>
              <Box sx={{marginLeft: 2}}>
                <Typography variant="caption" color={theme.palette.primary.main}>
                  Client:
                </Typography>
                <Typography fontWeight={600}>{jobRequestDetails()?.client?.name}</Typography>
              </Box>
            </Box>
          ) : null}

          {/* COUNTER OFFERS */}
          <Container disableGutters sx={{marginTop: 3}}>
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", marginY: 2}}>
              <Typography fontWeight={600}>{t("Counter Offers")}</Typography>
              <Typography
                fontWeight={400}
                sx={{":active": {textDecoration: "underline"}}}
                onClick={() =>
                  navigate(`/m/counter-offers?request_id=${jobRequestDetails()?._id}`, {
                    state: jobRequestDetails(),
                  })
                }>
                {t("View All")}
              </Typography>
            </Box>
            <CounterOffer
              counterOffer={jobRequestDetails()?.counterOffers[0]}
              isMe={jobRequestDetails()?.counterOffers[0]?.offeredBy === id}
            />
            <Box sx={{textAlign: "right"}}>
              <Button
                variant="text"
                sx={{marginLeft: "auto", textTransform: "none"}}
                onClick={handleShowCounterOffer}>
                <PlusIcon style={{marginRight: 8, height: 16, width: 16}} />
                {t("Add your Counter offer")}
              </Button>
            </Box>
          </Container>

          {/* ACTION BUTTONS */}
          {jobRequestDetails()?.client?._id === id && jobRequestDetails()?.status === "PENDING" ? (
            <MCounterOfferActionButtons
              isLoading={isLoading.load}
              acceptLoad={isLoading.acceptLoad}
              declineLoad={isLoading.declineLoad}
              onAccept={() => updateThisRequest("APPROVED")}
              onDecline={() => updateThisRequest("DECLINED")}
            />
          ) : null}

          {/* ADD COUNTER OFFER */}
          <MAddCounterOfferModal
            requestId={jobRequestDetails()?._id}
            open={openCounterOffer}
            closeModal={handleCloseCounterOffer}
          />
        </>
      ) : null}
    </>
  );
};

export default MJobRequestDetails;
