// HOOKS
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate} from "react-router";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../store";

// SERVICES & UTILS
import {ILoginForm, ISignUpResponse} from "../../../interfaces/IUser";
import {loginUser} from "../../Desktop/Login/LoginForm/schema";
import {theme} from "../../../styles/theme";
import {setUser} from "../../../store/Slices/UserSlice";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MAuthBanner from "../../../components/Mobile/MAuthBanner";

// ASSETS & ICONS
import {ReactComponent as EmailIcon} from "../../../assets/Icons/email.svg";
import {ReactComponent as LockIcon} from "../../../assets/Icons/lock.svg";
import {ReactComponent as VisibilityOff} from "../../../assets/Icons/hide-eye.svg";
import {ReactComponent as Visibility} from "../../../assets/Icons/eye.svg";
import AuthServices from "../../../services/AuthServices";
import {AxiosError} from "axios";
import {getMessaging, getToken, isSupported} from "firebase/messaging";
import {app, messaging} from "../../../config/global.firebase";

const MSignIn = () => {
  const [fcm, setFCM] = useState<string | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: joiResolver(loginUser),
  });

  // SING IN WITH EMAIL & PASSWORD
  async function sumbitLogin(formData: ILoginForm) {
    const {email, password} = formData;
    setLoading(true);
    try {
      const res = await AuthServices.SignIn({email, password, fcm});

      setLoading(false);
      // SUCCESS
      if (res.status === 200) {
        const userData = res.data as ISignUpResponse;
        // NEED EMAIL VERIFICATION
        if (!res.data?.isEmailVerified) {
          dispatch(setUser(userData));
          navigate("/m/verify-email?verification_channel=email&verification_path=account");
          return enqueueSnackbar("We need to verify your email.", {
            variant: "success",
          });
        }
        enqueueSnackbar(t("Signed in successfully."), {variant: "success"});
        localStorage.setItem("access_token", userData.access_token);
        dispatch(
          setUser({
            id: userData.id,
            access_token: userData.access_token,
            email: userData.email,
            fcm: userData.fcm,
            isEmailVerified: userData.isEmailVerified,
            isPhoneVerified: userData.isPhoneVerified,
            name: userData.name,
            phone: userData.phone,
            profile: userData.profile,
            address: userData.address,
          })
        );
        return navigate("/m/home");
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      console.log(error);
      // INVALID CREDENTIALS
      if (err.response?.status === 401) {
        enqueueSnackbar(t("Invalid email or password."), {variant: "error"});
      }
      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  // CONTINUE AS GUEST
  async function handleContinueAsGuest() {
    localStorage.setItem("access_token", "guest");
    dispatch(
      setUser({
        address: "",
        email: "",
        fcm: "",
        id: "",
        isEmailVerified: false,
        isPhoneVerified: false,
        name: "",
        phone: "",
        profile: "",
        access_token: "guest",
      })
    );
    navigate("/m/home");
  }

  async function getPermission() {
    if (await isSupported()) {
      const messaging = getMessaging(app);
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const tokenFCM = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH,
        });
        setFCM(tokenFCM);
      } else if (permission === "denied") {
        alert("You've denied the notifications.");
      }
    }
  }

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          marginX: "auto",
        }}>
        <MAuthBanner title={t("Welcome Back!")} headline={t("Sign In with your Account")} />

        {/* FORM */}
        <Box
          sx={{
            marginTop: 4,
          }}>
          <form onSubmit={handleSubmit(sumbitLogin)}>
            {/* EMAIL */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="email"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="email"
                      size="medium"
                      variant="outlined"
                      type="email"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fill={theme.palette.grey[500]} />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.email ? true : false}
                      helperText={errors?.email ? errors?.email?.message : null}
                      placeholder={t("Email")}
                    />
                  );
                }}
              />
            </FormControl>

            {/* PASSWORD */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="password"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="password"
                      size="medium"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fill={theme.palette.grey[500]} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.password ? true : false}
                      helperText={errors?.password ? errors?.password?.message : null}
                      placeholder={t("Password")}
                    />
                  );
                }}
              />
            </FormControl>

            {/* REMEMBER ME & FORGOT PASSWORD */}
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              {/* <FormControl>
                <FormControlLabel
                  label={t("Remember Me")}
                  control={
                    <Checkbox color="primary" icon={<CheckBoxIcon />} checkedIcon={<CheckBoxCheckedIcon />} />
                  }
                />
              </FormControl> */}
              <Typography
                onClick={() => navigate("/m/forgot-password")}
                sx={{cursor: "pointer", marginLeft: "auto"}}>
                {t("Forgot Password?")}
              </Typography>
            </Box>

            {/* SUBMIT BUTTON - SIGN IN */}
            <FormControl sx={{display: "block", marginTop: 4}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  fontSize: 16,
                  borderRadius: 2.5,
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: 600,
                  boxShadow: "4px 8px 24px 0px rgba(0, 0, 0, 0.25)",
                }}>
                {t("Sign In")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>

          {/* OTHERS LINKS */}
          <Box
            sx={{
              marginTop: 2,
              textAlign: "center",
            }}>
            <Typography
              mt={4}
              fontSize={24}
              fontWeight={700}
              color={theme.palette.primary.main}
              onClick={handleContinueAsGuest}>
              {t("Continue as Guest")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 8,
                marginBottom: 2,
              }}>
              <Typography fontSize={14} color={theme.palette.primary.main}>
                {t("Don’t have an account?")}
              </Typography>
              <Typography
                ml={0.5}
                fontWeight={600}
                fontSize={14}
                color={theme.palette.primary.main}
                onClick={() => navigate("/m/sign-up")}>
                {t("Sign Up")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MSignIn;
