// HOOKS
import {FC} from "react";
import {useTranslation} from "react-i18next";

// UI
import {Box, Button, Modal, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";
import {ReactComponent as OTPVerified} from "../../../assets/Icons/otp-verified.svg";
import {ReactComponent as PasswordVerified} from "../../../assets/Icons/verified-password.svg";

interface Props {
  open: boolean;
  handleClose: () => void;
  verificationType: "password" | "otp";
  btnText: string;
}

const MVerifiedModal: FC<Props> = ({open, handleClose, verificationType, btnText}) => {
  const {t} = useTranslation();
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            boxShadow: 24,
            p: 2,
            textAlign: "center",
            borderRadius: 2,
          }}>
          <Box>
            {verificationType === "otp" ? <OTPVerified /> : null}
            {verificationType === "password" ? <PasswordVerified /> : null}
          </Box>
          <Typography fontSize={24} my={2} whiteSpace={"nowrap"}>
            {t("Verification Successful!")}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            sx={{borderRadius: 2.5, textTransform: "none"}}
            onClick={handleClose}>
            {btnText}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default MVerifiedModal;
