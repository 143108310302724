// HOOKS
import {useTranslation} from "react-i18next";

// UI
import {Box, Checkbox, FormLabel} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";

// SERVICES & UTILS
import {i18next} from "../../../i18n/i18n";
// ASSETS & ICONS
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";
import {theme} from "../../../styles/theme";
import {useEffect, useState} from "react";

const MLanguages = () => {
  const {t} = useTranslation();
  const [currentLang, setCurrentLang] = useState<"en" | "de">(localStorage.getItem("lang") as "en" | "de");

  function handleLang(lang: "en" | "de") {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    setCurrentLang(lang);
  }
  function getCurrentLang() {
    let selectedLang = localStorage.getItem("lang") as "en" | "de";
    setCurrentLang(selectedLang);
  }

  useEffect(() => {
    getCurrentLang();
  }, []);

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("Languages")} />

      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <FormLabel htmlFor="en" sx={{fontSize: 18, fontWeight: 600, color: theme.palette.common.black}}>
          English
        </FormLabel>
        <Checkbox
          id="en"
          checked={currentLang === "en" ? true : false}
          disabled={currentLang === "en" ? true : false}
          color="primary"
          icon={<CheckBoxIcon />}
          checkedIcon={<CheckBoxCheckedIcon />}
          onChange={() => handleLang("en")}
        />
      </Box>
      <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <FormLabel htmlFor="de" sx={{fontSize: 18, fontWeight: 600, color: theme.palette.common.black}}>
          German
        </FormLabel>
        <Checkbox
          id="de"
          checked={currentLang === "de" ? true : false}
          disabled={currentLang === "de" ? true : false}
          color="primary"
          icon={<CheckBoxIcon />}
          checkedIcon={<CheckBoxCheckedIcon />}
          onChange={() => handleLang("de")}
        />
      </Box>
    </>
  );
};

export default MLanguages;
