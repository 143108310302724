import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage, ref } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAnABWw_AyQ5ymKBJSb0mMorE7tIHOAStE",
  authDomain: "helper2go-b92ac.firebaseapp.com",
  projectId: "helper2go-b92ac",
  storageBucket: "helper2go-b92ac.appspot.com",
  messagingSenderId: "539342557107",
  appId: "1:539342557107:web:ab0b3c332c1f7ad6a385c6"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const storageRef = ref(storage);
let messaging

try {
  messaging = getMessaging(app);
} catch (err) {
  console.error('Failed to initialize Firebase Messaging', err);
}

export { app, auth, db, storage, storageRef, messaging };
