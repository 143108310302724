import { uploadString, ref, getDownloadURL, deleteObject } from 'firebase/storage'
import { storage } from "../config/global.firebase";

// UPLOAD IMAGE
const uploadThisImage = async (image: string, folder: string) => {
  let str = (Math.random() + 1).toString(36).substring(7)
  const uploadRef = ref(storage, `${folder}/${str}`)
  const uploadedImageRef = await uploadString(uploadRef, image, 'data_url')
  const url = await getDownloadURL(uploadedImageRef.ref)
  return url
}
// DELETE IMAGE
const deleteThisImage = async (url?: string) => {
  if (url) {
    const oldImageRef = ref(storage, url);
    const isExist = await getDownloadURL(oldImageRef);
    if (isExist) {
      await deleteObject(oldImageRef);
    }
  }
}

export { uploadThisImage, deleteThisImage }