// HOOKS
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../store";
import {useTranslation} from "react-i18next";

// UI
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";
import {Avatar, Box, Button, Menu, MenuItem, Typography} from "@mui/material";
import EditProfileModal from "./EditProfileModal";
import Navbar from "../../../components/Desktop/Navbar";
import Footer from "../../../components/Desktop/Footer";
import {theme} from "../../../styles/theme";
import {i18next} from "../../../i18n/i18n";

// ASSETS & ICONS
import {ReactComponent as ChevronDown} from "../../../assets/Icons/chevron-down.svg";
import {ReactComponent as LocationIcon} from "../../../assets/Icons/location.svg";
import {ReactComponent as EditIcon} from "../../../assets/Icons/edit-m.svg";
import {BLOBS_MY_JOBS} from "../../../assets/Images";
import UpdateProfileModal from "./UpdateProfileModal";

const Profile = () => {
  const user = useAppSelector(state => state.user);

  // MODAL HANDERS - JOB DETAILS
  const [openEdit, setShowEdit] = useState(false);
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEdit = () => setShowEdit(false);

  // MODAL HANDERS - UPDATE PROFILE PICTURE
  const [openProfileEdit, setShowProfileEdit] = useState(false);
  const handleShowProfileEdit = () => setShowProfileEdit(true);
  const handleCloseProfileEdit = () => setShowProfileEdit(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {t} = useTranslation();
  const [currentLang, setCurrentLang] = useState<"en" | "de">(localStorage.getItem("lang") as "en" | "de");

  function handleLang(lang: "en" | "de") {
    i18next.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    setCurrentLang(lang);
  }
  function getCurrentLang() {
    let selectedLang = localStorage.getItem("lang") as "en" | "de";
    setCurrentLang(selectedLang);
  }

  useEffect(() => {
    getCurrentLang();
  }, []);

  return (
    <>
      <Navbar />

      <Box
        sx={{
          paddingX: 10,
          paddingY: 8,
          backgroundImage: `url(${BLOBS_MY_JOBS})`,
          backgroundPositionX: "right",
          backgroundPositionY: "-120px",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#D2EBFF",
          backgroundSize: 200,
          minHeight: "600px",
        }}>
        <Box sx={{borderRadius: 2.5, overflow: "hidden"}}>
          <BoxPrimaryGradient>
            <Box sx={{padding: 4}}>
              {/* HEADER */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 4,
                }}>
                <Typography
                  variant="h5"
                  textTransform={"uppercase"}
                  color={theme.palette.common.white}
                  fontWeight={700}>
                  {t("Profile")}
                </Typography>
                <Button
                  variant="outlined"
                  sx={{color: theme.palette.common.white, borderColor: theme.palette.common.white}}
                  onClick={handleClick}>
                  {currentLang === "de" && "GERMAN"}
                  {currentLang === "en" && "ENGLISH"}
                  <ChevronDown style={{fill: "white", marginLeft: 10, height: 10, width: 10}} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  sx={{
                    marginTop: 1,
                    "& .MuiPaper-root": {
                      borderRadius: 5,
                    },
                    textAlign: "center",
                  }}>
                  <MenuItem
                    onClick={() => handleLang("en")}
                    sx={{
                      width: 108,
                    }}>
                    English
                  </MenuItem>
                  <MenuItem onClick={() => handleLang("de")}>German</MenuItem>
                </Menu>
              </Box>

              {/* PROFILE */}
              <Box
                sx={{
                  filter: "blur(40)",
                  backgroundColor: "rgba(255, 255, 255, 0.08)",
                  borderRadius: 2.5,
                  paddingY: 3,
                  paddingX: 4,
                  display: "flex",
                  alignItems: "center",
                }}>
                {/* AVATAR */}
                <Box sx={{position: "relative"}}>
                  <Avatar sx={{width: 150, height: 150, textTransform: "uppercase"}} src={user.profile}>
                    {user.name.split("")[0]}
                  </Avatar>
                  <EditIcon
                    style={{
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                      height: 24,
                      width: 24,
                      cursor: "pointer",
                    }}
                    onClick={handleShowProfileEdit}
                  />
                </Box>

                {/* INFO */}
                <Box sx={{marginLeft: 4}}>
                  <Typography variant="h4" mb={1} fontWeight={600} color={theme.palette.common.white}>
                    {user.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    color={theme.palette.common.white}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                    }}>
                    <LocationIcon style={{height: 20, width: 20, marginRight: 10}} fill="white" />
                    {user.address}
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{color: theme.palette.common.white, borderColor: theme.palette.common.white}}
                    onClick={handleShowEdit}>
                    {t("Edit Details")}
                  </Button>
                </Box>
              </Box>

              <Box></Box>
            </Box>
          </BoxPrimaryGradient>
        </Box>

        <Box mt={14} mb={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Typography
            variant="body2"
            fontWeight={500}
            color={theme.palette.grey[600]}
            mr={1}
            whiteSpace={"pre-wrap"}>
            {user.description}
          </Typography>
        </Box>
      </Box>

      {/* EDIT HELPER DETAILS MODAL */}
      <EditProfileModal user={user} open={openEdit} handleClose={handleCloseEdit} />

      {/* UPDATE PROFILE PICTURE MODAL */}
      <UpdateProfileModal user={user} open={openProfileEdit} handleClose={handleCloseProfileEdit} />

      {/* FOOTER */}
      <Footer />
    </>
  );
};

export default Profile;
