// HOOKS
import {Outlet, useLocation} from "react-router-dom";

// UI
import {Box} from "@mui/material";
import HomeBottomNavigation from "./Home/HomeBottomNavigation";
import {isLoggedIn} from "../../utils/AuthUtils";

const MobileView = () => {
  const {pathname} = useLocation();

  // CHECK PATHTO SHOW BOTTOM NAVIGATION
  const checkPath = () => {
    if (Boolean(pathname === "/m/edit-profile")) {
      return false;
    } else if (Boolean(pathname === "/m/terms-and-conditions")) {
      return false;
    } else if (Boolean(pathname === "/m/languages")) {
      return false;
    } else if (Boolean(pathname === "/m/apply-job")) {
      return false;
    } else if (Boolean(pathname === "/m/notifications")) {
      return false;
    } else if (Boolean(pathname === "/m/post-job")) {
      return false;
    } else if (Boolean(pathname === "/m/job-details")) {
      return false;
    } else if (Boolean(pathname === "/m/job-requests")) {
      return false;
    } else if (Boolean(pathname === "/m/job-request-details")) {
      return false;
    } else if (Boolean(pathname === "/m/counter-offers")) {
      return false;
    } else if (Boolean(pathname === "/m/faqs")) {
      return false;
    } else if (Boolean(pathname === "/m/about-us")) {
      return false;
    } else return true;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          height: "100vh",
          marginX: "auto",
        }}>
        <Box sx={{paddingBottom: "80px"}}>
          <Outlet />
        </Box>
        {isLoggedIn() && checkPath() ? <HomeBottomNavigation /> : null}
      </Box>
    </>
  );
};

export default MobileView;
