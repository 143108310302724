// HOOKS
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

// UI
import {Avatar, Box, Typography} from "@mui/material";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import {theme} from "../../../styles/theme";

// ASSETS & ICONS
// import {ReactComponent as LocationIcon} from "../../../assets/Icons/location.svg";
// import {ReactComponent as PhoneIcon} from "../../../assets/Icons/call.svg";
// import {ReactComponent as EmailIcon} from "../../../assets/Icons/email.svg";
import {IUserInJobSearch} from "../../../interfaces/IJob";

const MUserProfile = () => {
  const location = useLocation();
  const userInfo = location.state as IUserInJobSearch;
  const {t} = useTranslation();

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("User Profile")} />

      {/* USER INFO */}
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          borderRadius: 2.5,
          padding: 2,
          display: "flex",
          alignItems: "center",
        }}>
        <Avatar sx={{height: 70, width: 70}} src={userInfo.profile}>
          {userInfo.name.split("")[0]}
        </Avatar>
        <Box sx={{marginLeft: 3}}>
          <Typography variant="h6">{userInfo.name}</Typography>
        </Box>
      </Box>

      <Typography variant="h6" fontWeight={600} marginY={2}>
        {t("Additional Info")}
      </Typography>

      {/* ADDITIONAL INFO */}
      <Box sx={{border: `1px solid ${theme.palette.grey[300]}`, borderRadius: 2.5, padding: 2}}>
        {userInfo.description && (
          <>
            <Typography>About</Typography>
            <Typography whiteSpace={"pre-wrap"}>{userInfo.description}</Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default MUserProfile;
