// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {useAppSelector} from "../../../store";

// UI
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MAuthBanner from "../../../components/Mobile/MAuthBanner";

// SERVICES & UTILES
import AuthServices from "../../../services/AuthServices";
import {theme} from "../../../styles/theme";
import {AxiosError} from "axios";
import Joi from "joi";

// ASSETS
import {ReactComponent as EmailIcon} from "../../../assets/Icons/email.svg";
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";

export const verifyEmail = Joi.object({
  email: Joi.string()
    .email({tlds: {allow: false}})
    .empty()
    .required()
    .messages({
      "string.base": "Email is required.",
      "string.required": "Email is required.",
      "string.empty": "Email is required.",
      "string.email": "Must be a valid email.",
    }),
  agreedToTC: Joi.boolean().valid(true).required().messages({
    "any.only": "You must agreed to the Terms & Conditions.",
  }),
});

interface FormData {
  email: string;
  agreedToTC: boolean;
}

const MVerifyEmail = () => {
  const user = useAppSelector(state => state.user);
  const [isLoading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      email: user.email || "",
      agreedToTC: false,
    },
    resolver: joiResolver(verifyEmail),
  });

  // SUBMIT FOUND
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {email} = formData;

    try {
      const resp = await AuthServices.SendOTP("email", {email});

      // SUCCESS
      if (resp.status === 200) {
        setLoading(false);
        enqueueSnackbar("OTP is sent to your email.", {variant: "info"});
        navigate("/m/verify-otp?verification_channel=email&verification_path=account");
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;

      // EMAIL NOT FOUND
      if (err.response?.status === 404) {
        enqueueSnackbar("This email does not exist.", {variant: "error"});
      }

      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar("Make sure your internet is working.", {variant: "error"});
      } else {
        enqueueSnackbar("Something went wrong.", {variant: "error"});
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          marginX: "auto",
        }}>
        {/* HEADER */}
        <MAuthBanner />

        <Box>
          <Typography
            textAlign={"center"}
            fontWeight={600}
            color={theme.palette.primary.main}
            marginTop={3}
            marginBottom={4}>
            {t("Verify your email to continue")}
          </Typography>
          <form onSubmit={handleSubmit(submitForm)}>
            {/* PHONE NUMBER */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="email"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="email"
                      size="medium"
                      variant="outlined"
                      type="email"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fill={theme.palette.grey[500]} />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.email ? true : false}
                      helperText={errors?.email ? errors?.email?.message : null}
                      placeholder={t("Email")}
                    />
                  );
                }}
              />
            </FormControl>

            {/* AGREED TO T&C */}
            <FormControl>
              <FormControlLabel
                label={t("By entering my email, I agree to Helper 2 Go’s Terms & Conditions")}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: 12,
                  },
                }}
                control={
                  <Controller
                    name="agreedToTC"
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        color="primary"
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxCheckedIcon />}
                      />
                    )}
                  />
                }
              />
            </FormControl>
            <Typography variant="body2" color="error">
              {errors.agreedToTC?.message}
            </Typography>

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 15}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  fontSize: 16,
                  borderRadius: 2.5,
                  py: 1.5,
                  boxShadow: "4px 8px 24px 0px rgba(0, 0, 0, 0.25)",
                  textTransform: "none",
                }}>
                Next
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default MVerifyEmail;
