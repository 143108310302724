// HOOKS
import {FC, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import {counterOfferForm} from "../../../components/Mobile/MAddCounterOfferModal";

// ASSETS & ICONS
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";
import RequestServices from "../../../services/RequestServices";
import {updateCounterOffers} from "../../../store/Slices/RequestsSlice";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import {IRequestAPI} from "../../../interfaces/IRequest";

interface Props {
  request?: IRequestAPI;
  open: boolean;
  handleClose: () => void;
  onAddOffer: (request: IRequestAPI) => void;
}

interface FormData {
  budget: number;
  budgetNote: string;
}

const CounterOfferModal: FC<Props> = ({open, handleClose, request, onAddOffer}) => {
  const [isLoading, setLoading] = useState(false);
  const {id} = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();
  const [searchParams] = useSearchParams();
  const request_id = searchParams.get("request_id") || undefined;

  const {
    handleSubmit,
    control,
    formState: {errors},
    reset,
  } = useForm<FormData>({
    defaultValues: {
      budget: 0,
      budgetNote: "",
    },
    resolver: joiResolver(counterOfferForm),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {budget, budgetNote} = formData;

    try {
      const resp = await RequestServices.AddCounterOffer(request_id, {budget, budgetNote, offeredBy: id});

      // SUCCESS
      if (resp.status === 201) {
        // WHEN I'M CLIENT
        if (request?.client._id === id) {
          onAddOffer(resp.data as IRequestAPI);
        }
        // WHEN I'M HELPER
        else if (request?.helper._id === id) {
          dispatch(updateCounterOffers(resp.data));
        }
        enqueueSnackbar(t("Counter offer added sucessfully."), {variant: "success"});
        setLoading(false);
        handleClose();
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      console.log(err);
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
          reset();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        sx={{
          height: "100vh",
          overflowY: "scroll",
          display: "grid",
          placeItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            marginY: 3,
            width: "40%",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}>
          {/* HEADER */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography variant="h5" fontWeight={600} color={theme.palette.primary.main}>
              {t("Counter Offer")}
            </Typography>
            <CloseIcon
              style={{cursor: "pointer"}}
              onClick={() => {
                handleClose();
                reset();
              }}
            />
          </Box>

          {/* BODY */}
          <Box
            sx={{
              marginTop: 2,
            }}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* NEW BUDGET */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, display: "block"}}
                htmlFor="newBudget">
                {t("New Budget")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="budget"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="budget"
                        size="medium"
                        variant="outlined"
                        type="number"
                        {...field}
                        error={errors?.budget ? true : false}
                        helperText={t(errors?.budget?.message || "")}
                        placeholder={t("Write here")}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                          },
                          "& .MuiInputBase-root": {
                            backgroundColor: "#D2EBFF !important",
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* BUDGET NOTES */}
              <FormLabel
                sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black}}
                htmlFor="budgetNotes">
                {t("Budget Note")}
              </FormLabel>
              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="budgetNote"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        multiline
                        id="budgetNote"
                        size="medium"
                        variant="outlined"
                        type="text"
                        {...field}
                        error={errors?.budgetNote ? true : false}
                        helperText={t(errors?.budgetNote?.message || "")}
                        placeholder={t("Description here")}
                        rows={6}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                            padding: 2,
                          },
                          "& .MuiInputBase-root": {
                            padding: 0,
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* SUBMIT BUTTON - CREATE ACCOUNT */}
              <FormControl sx={{display: "block", marginTop: 8}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                  disabled={isLoading}>
                  {t("SEND OFFER")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CounterOfferModal;
