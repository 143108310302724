import Joi from "joi";

export const loginUser = Joi.object({
  email: Joi.string().email({ tlds: { allow: false } }).empty().required().messages({
    "string.base": "Email is required.",
    "string.required": "Email is required.",
    "string.empty": "Email is required.",
    "string.email": "Must be a valid email.",
  }),
  password: Joi.string().empty().required().messages({
    "string.base": "Password is required.",
    "string.required": "Password is required.",
    "string.empty": "Password is required.",
  })
});