// HOOKS
import {FC, ReactNode} from "react";

// UI
import {Box, Container, Divider, Drawer, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";

interface Props {
  open: boolean;
  closeDrawer: () => void;
  HeaderText: () => ReactNode;
  Content: () => ReactNode;
}

const MDrawer: FC<Props> = ({open, closeDrawer, HeaderText, Content}) => {
  return (
    <>
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={closeDrawer}
        sx={{"& .MuiDrawer-paper": {borderRadius: "24px 24px 0 0"}}}>
        <Container
          disableGutters
          sx={{
            textAlign: "center",
            paddingY: 2,
          }}>
          {/* HEADER */}
          <Typography
            sx={{
              width: 40,
              height: 3,
              backgroundColor: theme.palette.common.black,
              marginBottom: 3,
              borderRadius: 3,
              marginX: "auto",
            }}
          />
          {HeaderText()}
          {/* <HeaderText /> */}
          <Divider variant="middle" sx={{marginY: 2}} />

          {/* FORM */}
          <Box sx={{paddingX: 1.8, textAlign: "left"}}>{Content()}</Box>
        </Container>
      </Drawer>
    </>
  );
};

export default MDrawer;
