// HOOKS
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

// SERVICES & UTILS
import AuthServices from "../../../services/AuthServices";
import {AxiosError} from "axios";
import Joi from "joi";

// UI
import NavigationHeader from "../../../components/Mobile/NavigationHeader";
import {Box, Button, CircularProgress, FormControl, InputAdornment, TextField} from "@mui/material";
import HeaderForgotPassword from "./HeaderForgotPassword";
import {theme} from "../../../styles/theme";

// ASSETS & ICONS
import {ReactComponent as EmailIcon} from "../../../assets/Icons/email.svg";

const verifyEmailSchema = Joi.object({
  email: Joi.string()
    .email({tlds: {allow: false}})
    .empty()
    .required()
    .messages({
      "string.base": "Email is required.",
      "string.required": "Email is required.",
      "string.empty": "Email is required.",
      "string.email": "Must be a valid email.",
    }),
});

interface FormData {
  email: string;
}

const MForgotPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: joiResolver(verifyEmailSchema),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {email} = formData;

    try {
      const resp = await AuthServices.SendOTP("email", {email});

      // SUCCESS
      if (resp.status === 200) {
        setLoading(false);
        enqueueSnackbar(t("OTP is sent to your email."), {variant: "info"});
        navigate("/m/verify-otp?verification_channel=email&verification_path=resetPassword", {
          state: {email},
        });
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;

      // EMAIL NOT FOUND
      if (err.response?.status === 404) {
        enqueueSnackbar(t("This email does not exist."), {variant: "error"});
      }

      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          marginX: "auto",
        }}>
        <NavigationHeader />

        {/* HEADING - FORGOT PASSWORD */}
        <HeaderForgotPassword label={t("Enter your details to continue")} />

        {/* FORM */}
        <Box marginTop={6}>
          <form onSubmit={handleSubmit(submitForm)}>
            {/* EMAIL */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="email"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="email"
                      size="medium"
                      variant="outlined"
                      type="email"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fill={theme.palette.grey[500]} />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.email?.message ? true : false}
                      helperText={t(errors?.email?.message || "")}
                      placeholder={t("Email")}
                    />
                  );
                }}
              />
            </FormControl>

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 4}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  fontSize: 16,
                  borderRadius: 2.5,
                  paddingY: 1.5,
                  textTransform: "none",
                  fontWeight: 600,
                  boxShadow: "4px 8px 24px 0px rgba(0, 0, 0, 0.25)",
                }}>
                {t("Continue")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default MForgotPassword;
