// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../store";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

// UI
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import MSplitText from "../../../components/Mobile/MSplitText";
import {theme} from "../../../styles/theme";

// SERIVCES & UTILS
import {counterOfferForm} from "../../Desktop/MyJobs/JobDetailsModal";
import RequestServices from "../../../services/RequestServices";
import {calculateDuration} from "../../../utils/JobUtils";
import {AxiosError} from "axios";

// ASSETS & ICONS
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as NotesIcon} from "../../../assets/Icons/notes.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";
import {ReactComponent as BudgetIcon} from "../../../assets/Icons/budget.svg";
import {IJobOnSearch} from "../../../interfaces/IJob";
import {Link} from "react-router-dom";

interface FormData {
  addCounterOffer: boolean;
  budget: number;
  budgetNote: string;
  note: string;
  agreedToTC: boolean;
}

const MApplyAsHelper = () => {
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();
  const job = location.state as IJobOnSearch;
  const navigate = useNavigate();

  const {id} = useAppSelector(state => state.user);
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  // HANDLE FORM
  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
    watch,
    reset,
  } = useForm({
    defaultValues: {
      addCounterOffer: false,
      budget: 0,
      budgetNote: "",
      note: "",
      agreedToTC: false,
    },
    resolver: joiResolver(counterOfferForm),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    setLoading(true);
    const {budgetNote, budget, note} = formData;
    try {
      const resp = await RequestServices.CreateRequest({
        budget: budget ? budget : job.budget,
        budgetNote,
        note,
        job: job._id,
        client: job.client?._id,
        helper: id,
      });
      // SUCCESS
      if (resp.status === 201) {
        enqueueSnackbar(t("Request created successfully."), {variant: "success"});
        setLoading(false);
        reset();
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  const TnCLink = () => {
    return (
      <Link to={"/m/terms-and-conditions"}>
        <Typography color={theme.palette.primary.light} fontSize={14}>
          {t("Terms & Conditions")}
        </Typography>
      </Link>
    );
  };

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("Apply as a Helper")} />

      {/* JOB INFO */}
      <Box
        sx={{
          padding: 1,
          paddingY: 2,
          marginBottom: 2,
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: 2.5,
        }}>
        <MSplitText title={t("miniJob")} value={job.title} />
        <MSplitText title="Budget" value={job.budget.toLocaleString()} />
        {job?.startOn && job?.endOn ? (
          <MSplitText title={t("Timeline")} value={calculateDuration(job?.startOn, job?.endOn)} />
        ) : null}
      </Box>

      {/* ADD NOTES */}
      <form onSubmit={handleSubmit(submitForm)}>
        <Controller
          name="note"
          control={control}
          render={({field}) => (
            <FormControl fullWidth margin="dense">
              <TextField
                id="add-notes"
                size="medium"
                variant="outlined"
                type="text"
                placeholder={t("Add Note")}
                error={errors?.note ? true : false}
                helperText={errors?.note ? errors?.note?.message : null}
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <NotesIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )}
        />

        {/* FORM */}
        <Box marginTop={2}>
          {/* ADD COUNTER OFFER */}
          <FormControl>
            <FormControlLabel
              label={
                <>
                  {t("Add Counter Offer")} <span style={{fontSize: 12, fontWeight: 400}}>(optional)</span>
                </>
              }
              control={
                <Controller
                  name="addCounterOffer"
                  control={control}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      color="primary"
                      icon={<CheckBoxIcon />}
                      checkedIcon={<CheckBoxCheckedIcon />}
                    />
                  )}
                />
              }
            />
          </FormControl>

          {watch("addCounterOffer") ? (
            <>
              <FormControl fullWidth margin="normal">
                <Controller
                  name="budget"
                  control={control}
                  disabled={!watch("addCounterOffer")}
                  render={({field}) => (
                    <TextField
                      id="budget"
                      size="medium"
                      variant="outlined"
                      type="number"
                      placeholder={t("Your Budget")}
                      error={errors?.budget ? true : false}
                      helperText={errors?.budget ? errors?.budget?.message : null}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BudgetIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                <Controller
                  name="budgetNote"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        multiline
                        id="budgetNote"
                        size="medium"
                        variant="outlined"
                        type="text"
                        {...field}
                        error={errors?.budgetNote ? true : false}
                        helperText={t(errors?.budgetNote?.message as string)}
                        placeholder={t("Budget Notes")}
                        disabled={!watch("addCounterOffer")}
                        rows={4}
                        sx={{
                          mb: 2,
                          "& .MuiInputBase-input": {
                            backgroundColor: "white",
                            borderRadius: 2.5,
                            padding: 2,
                          },
                          "& .MuiInputBase-root": {
                            padding: 0,
                          },
                        }}
                      />
                    );
                  }}
                />
              </FormControl>
            </>
          ) : null}

          {/* AGREED TO T&C */}
          <FormControl>
            <FormControlLabel
              label={
                <>
                  {t("I Agree to the Helper2Go’s")} {TnCLink()}
                </>
              }
              sx={{"& .MuiFormControlLabel-label": {fontSize: 14}}}
              control={
                <Controller
                  name="agreedToTC"
                  control={control}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      color="primary"
                      icon={<CheckBoxIcon />}
                      checkedIcon={<CheckBoxCheckedIcon />}
                    />
                  )}
                />
              }
            />
          </FormControl>

          {/* SUBMIT BUTTON */}
          <FormControl sx={{display: "block", marginTop: 3}}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
              disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
              {t("Send Offer")}
              {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
            </Button>
          </FormControl>
        </Box>
      </form>
    </>
  );
};

export default MApplyAsHelper;
