// HOOKS
import {SyntheticEvent, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

// UI
import {Box, Button, Skeleton, Tab, Tabs, Typography} from "@mui/material";
import {CustomTabPanel} from "../../Mobile/MyJobs";
import JobDetailsModal from "./JobDetailsModal";
import {theme} from "../../../styles/theme";
import Job from "./Job";

// SERVICES & UTILS
import {setMyJobs, updateJob} from "../../../store/Slices/JobsSlice";
import {IJob, IJobAPI} from "../../../interfaces/IJob";
import JobServices from "../../../services/JobServices";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import {ReactComponent as PlusIcon} from "../../../assets/Icons/plus.svg";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const JobsSelection = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const {t} = useTranslation();

  const {id} = useAppSelector(state => state.user);
  const jobs = useAppSelector(state => state.jobs);
  const dispatch = useAppDispatch();

  // MODAL HANDERS - JOB DETAILS
  const [jobDetails, setJobDetails] = useState<IJob | null>(null);
  const [openJobDetails, setOpenJobDetails] = useState(false);
  const handleShowJobDetails = async (job: IJob) => {
    setJobDetails(job);
    setOpenJobDetails(true);
    if (!job.isNotified) {
      dispatch(updateJob({id: job.id, isNotified: true}));
      await JobServices.UpdateJob(job.id, {isNotified: true});
    }
  };
  const handleCloseJobDetails = () => {
    setOpenJobDetails(false);
    setJobDetails(null);
  };

  // TAB HANDLER
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // GET MY JOBS
  async function getMyJobs() {
    setLoading(true);
    try {
      const resp = await JobServices.GetJobsFiltered({client: id});

      // SUCCESS
      if (resp.status === 200) {
        const arrOfJobs = resp.data as IJobAPI[];
        const allMyJobs: IJob[] = arrOfJobs.map((item: IJobAPI) => ({
          id: item._id,
          budget: item.budget,
          client: item.client._id,
          description: item.description,
          experience: item.experience,
          helper: item.helper?._id,
          status: item.status,
          isNotified: item.isNotified,
          startOn: item.startOn,
          endOn: item.endOn,
          title: item.title,
          tools: item.tools,
          createdAt: new Date(item.createdAt),
          updatedAt: new Date(item.updatedAt),
        }));
        dispatch(setMyJobs(allMyJobs));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    if (id) {
      getMyJobs();
    }
  }, [id]);

  return (
    <>
      <Box sx={{padding: 3, borderRadius: 4, filter: "blur(40)", backgroundColor: "rgba(9, 44, 72, 0.08)"}}>
        {/* HEADINGS */}
        <Box sx={{marginBottom: 2, display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          <Button
            variant="contained"
            sx={{borderRadius: 2.5, fontSize: 16}}
            onClick={() => navigate("/my-jobs/new-job")}>
            {t("Setup New Job")}
            <PlusIcon style={{marginLeft: 6}} height={16} width={16} />
          </Button>
        </Box>

        {/* TABS FOR JOBS POSTED/APPROVED */}
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
          sx={{borderBottom: `1px solid ${theme.palette.primary.main}`}}>
          <Tab label={t("Jobs Posted")} {...a11yProps(0)} />
          <Tab label={t("Jobs Approved")} {...a11yProps(1)} />
        </Tabs>

        {/* PENDING JOBS */}
        <CustomTabPanel value={value} index={0}>
          {/* LOADER */}
          {isLoading ? (
            <>
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
            </>
          ) : null}

          {/* MAPPING ARRAY FOR PENDING JOBS */}
          {!isLoading &&
            jobs
              .filter(item => item.status === "PENDING")
              .map(job => (
                <Job key={job.id} job={job} showDetails={() => handleShowJobDetails(job)} showNotification />
              ))}
          {!isLoading && !jobs.filter(item => item.status === "PENDING").length && (
            <Typography textAlign={"center"} mt={2}>
              {t("No job created yet.")}
            </Typography>
          )}
        </CustomTabPanel>

        {/* APPROVED JOBS */}
        <CustomTabPanel value={value} index={1}>
          {/* LOADER */}
          {isLoading ? (
            <>
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
              <Skeleton variant="rounded" height={108} sx={{borderRadius: 2.5, marginBottom: 2}} />
            </>
          ) : null}

          {/* MAPPING ARRAY FOR APPROVED JOBS */}
          {!isLoading &&
            jobs
              .filter(item => item.status === "APPROVED")
              .map(job => <Job key={job.id} job={job} showDetails={() => handleShowJobDetails(job)} />)}
          {!isLoading && !jobs.filter(item => item.status === "APPROVED").length && (
            <Typography textAlign={"center"} mt={2}>
              {t("No job approved yet.")}
            </Typography>
          )}
        </CustomTabPanel>
      </Box>

      {/* JOB DETAILS MODAL */}
      <JobDetailsModal
        setOpenJobDetails={setOpenJobDetails}
        job={jobDetails}
        open={openJobDetails}
        handleClose={handleCloseJobDetails}
      />
    </>
  );
};

export default JobsSelection;
