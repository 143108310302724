import Joi from "joi";

const userSchema = Joi.object({
  name: Joi.string().empty().min(3).required().messages({
    "string.base": "Name is required.",
    "string.required": "Name is required.",
    "string.empty": "Name is required.",
    "string.min": "Must have 3 letters.",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .empty()
    .required()
    .messages({
      "string.base": "Email is required.",
      "string.required": "Email is required.",
      "any.required": "Email is required.",
      "string.empty": "Email is required.",
      "string.email": "Must be a valid email.",
    }),
  phone: Joi.string().empty().required().messages({
    "string.base": "Phone is required.",
    "string.required": "Phone is required.",
    "any.required": "Phone is required.",
    "string.empty": "Phone is required.",
  }),
  address: Joi.string().empty().required().messages({
    "string.base": "Location is required.",
    "string.required": "Location is required.",
    "string.empty": "Location is required.",
  }),
  description: Joi.string().optional().allow('')
});

const updateProfileSchema = Joi.object({
  profile: Joi.string().empty().required().messages({
    "string.base": "Image is required.",
    "string.required": "Image is required.",
    "string.empty": "Image is required.",
  }),

});

export {
  userSchema,
  updateProfileSchema
}