import {FC} from "react";
import {Box, Typography} from "@mui/material";
import {ZIGZAG_HOME} from "../../../../assets/Images";

interface Props {
  title: string;
}

const ZigzagHeading: FC<Props> = ({title}) => {
  return (
    <>
      <Box my={16.5} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
        <img src={ZIGZAG_HOME} alt="design-element" />
        <Typography variant="h4" fontWeight={600} ml={6} textTransform={"uppercase"}>
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default ZigzagHeading;
