// HOOKS
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Box, Button, CircularProgress} from "@mui/material";
import {theme} from "../../../styles/theme";
import {ReactComponent as PlusIcon} from "../../../assets/Icons/plus.svg";

interface Props {
  isLoading: boolean;
  declineLoad: boolean;
  acceptLoad: boolean;
  onAccept: () => void;
  onDecline: () => void;
}

const ActionButtons: FC<Props> = ({acceptLoad, declineLoad, isLoading, onAccept, onDecline}) => {
  const {t} = useTranslation();

  return (
    <>
      <Box
        marginTop={5}
        sx={{
          display: "flex",
          gap: 6,
        }}>
        <Button
          variant="outlined"
          sx={{fontWeight: 600, borderRadius: 2.5, paddingX: 2, paddingY: 1, flexBasis: "50%", fontSize: 18}}
          disabled={isLoading}
          onClick={onDecline}>
          {t("Decline")}
          {declineLoad ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
        </Button>
        <Button
          variant="contained"
          sx={{fontWeight: 600, borderRadius: 2.5, paddingX: 2, paddingY: 1, flexBasis: "50%", fontSize: 18}}
          disabled={isLoading}
          onClick={onAccept}>
          {t("Accept")}
          {acceptLoad ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
        </Button>
      </Box>
    </>
  );
};

export default ActionButtons;
