// HOOKS
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../store";

// UI
import {Box, Typography, Avatar} from "@mui/material";
import {theme} from "../../../styles/theme";
import {IRequest} from "../../../interfaces/IRequest";
import moment from "moment";

interface Props {
  request: IRequest;
}

const JobRequest: FC<Props> = ({request}) => {
  const {id} = useAppSelector(state => state.user);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          backgroundColor: theme.palette.primary.main,
          padding: 2,
          borderRadius: 2.5,
          cursor: "pointer",
        }}
        onClick={() => navigate(`/job-request-details?request_id=${request.id}`, {state: request})}>
        {/* I AM HELPER HERE */}
        {request.helper._id === id ? (
          <Avatar sx={{height: 70, width: 70}} src={request.client.profile}>
            {request.client.name.split("")[0]}
          </Avatar>
        ) : null}

        {/* I AM CLIENT HERE */}
        {request.client._id === id ? (
          <Avatar sx={{height: 70, width: 70}} src={request.helper.profile}>
            {request.helper.name.split("")[0]}
          </Avatar>
        ) : null}
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            paddingX: 2,
            borderRadius: 2.5,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "80%",
          }}>
          {/* I AM CLIENT HERE */}
          {request.client._id === id ? (
            <>
              <Typography variant="h6" color={theme.palette.common.white}>
                {request.helper.name}
              </Typography>
              <Typography variant="body2" color={theme.palette.common.white} className="truncate-2">
                {request.note}
              </Typography>
            </>
          ) : null}

          {/* I AM HELPER HERE */}
          {request.helper._id === id ? (
            <>
              <Typography variant="h6" color={theme.palette.common.white}>
                {request.client.name}
              </Typography>
              <Typography variant="body2" color={theme.palette.common.white} className="truncate-2">
                {request.note}
              </Typography>
            </>
          ) : null}
        </Box>
        <Typography variant="body1" fontWeight={300} color={theme.palette.common.white} ml={"auto"}>
          {moment(request.createdAt).fromNow()}
        </Typography>
      </Box>
    </>
  );
};

export default JobRequest;
