import {FC} from "react";
import {Box} from "@mui/system";

// ASSETS
import {AUTH_BANNER} from "../../../assets/Images";

interface Props {
  imageOnTop?: boolean;
}

const LeftBanner: FC<Props> = ({imageOnTop}) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#D1EBFF",
          width: "45%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: imageOnTop ? "flex-start" : "center",
        }}>
        <img
          src={AUTH_BANNER}
          alt="auth-banner"
          style={{maxHeight: 500, minHeight: 300, marginTop: imageOnTop ? 100 : 0}}
        />
      </Box>
    </>
  );
};

export default LeftBanner;
