import {FC} from "react";
import {Box, Modal} from "@mui/material";
import {theme} from "../../../styles/theme";
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";
import TermsNConditionsText from "../TermsNConditionsText/TermsNConditionsText";

interface Props {
  open: boolean;
  closeModal: () => void;
}

const TncModal: FC<Props> = ({open, closeModal}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableRestoreFocus
        disableAutoFocus
        disableEnforceFocus
        sx={{
          height: "100vh",
          overflowY: "scroll",
          display: "grid",
          placeItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            marginY: 3,
            width: "80%",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}>
          <div style={{textAlign: "right"}}>
            <CloseIcon style={{cursor: "pointer"}} onClick={closeModal} />
          </div>
          <TermsNConditionsText />
        </Box>
      </Modal>
    </>
  );
};

export default TncModal;
