// HOOKS
import {FC} from "react";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

// UI
import {Box, Button, Container, Divider, Drawer, FormControl, FormLabel, Typography} from "@mui/material";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import {theme} from "../../../styles/theme";
import moment from "moment";

interface Props {
  open: boolean;
  closeDrawer: () => void;
  onChangeFilter: (formData: FormData) => void;
}

interface FormData {
  startOn: string;
  endOn: string;
}

const FilterDrawer: FC<Props> = ({open, closeDrawer, onChangeFilter}) => {
  const {t} = useTranslation();
  const {handleSubmit, control, setValue, reset} = useForm({
    defaultValues: {
      startOn: "",
      endOn: "",
    },
  });

  // SUBMIT FORM - FILTER
  async function submitForm(formData: FormData) {
    closeDrawer();
    onChangeFilter(formData);
  }

  // RESET FORM & HIDE FILTER DRAWER
  function handleResetForm() {
    closeDrawer();
    reset();
    onChangeFilter({startOn: "", endOn: ""});
  }

  return (
    <>
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={closeDrawer}
        sx={{"& .MuiDrawer-paper": {borderRadius: "24px 24px 0 0"}}}>
        <Container
          disableGutters
          sx={{
            textAlign: "center",
            paddingY: 2,
          }}>
          {/* HEADER */}
          <Box
            sx={{
              width: 40,
              height: 3,
              backgroundColor: theme.palette.common.black,
              marginBottom: 3,
              borderRadius: 3,
              marginX: "auto",
            }}
          />
          <Typography variant="h5" fontWeight={700}>
            Filter
          </Typography>
          <Divider variant="middle" sx={{marginY: 2}} />

          {/* FORM */}
          <Box sx={{paddingX: 1.8, textAlign: "left"}}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* JOB START */}
              <FormControl fullWidth margin="normal">
                <FormLabel
                  sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
                  htmlFor="startOn">
                  {t("Start job duration")}
                </FormLabel>
                <Controller
                  name="startOn"
                  control={control}
                  render={({field}) => {
                    return (
                      <MobileDateTimePicker
                        value={moment(field.value)}
                        onChange={value => {
                          if (value) {
                            setValue("startOn", value.toString());
                          }
                        }}
                        sx={{"& .MuiOutlinedInput-notchedOutline": {borderColor: "lightgrey !important"}}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* JOB END */}
              <FormControl fullWidth margin="normal">
                <FormLabel
                  sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
                  htmlFor="endOn">
                  {t("End job duration")}
                </FormLabel>
                <Controller
                  name="endOn"
                  control={control}
                  render={({field}) => {
                    return (
                      <MobileDateTimePicker
                        value={moment(field.value)}
                        onChange={value => {
                          if (value) {
                            setValue("endOn", value.toString());
                          }
                        }}
                        sx={{"& .MuiOutlinedInput-notchedOutline": {borderColor: "lightgrey !important"}}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* DIVIDER */}
              <Divider variant="fullWidth" sx={{my: 2}} />
              {/* SUBMIT BUTTON */}
              <Box sx={{display: "flex", gap: 4, marginTop: 4}}>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{fontSize: 14, borderRadius: 2.5, py: 2, flexBasis: "50%"}}
                  onClick={handleResetForm}>
                  {t("Reset")}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 14, borderRadius: 2.5, py: 2, flexBasis: "50%"}}>
                  {t("Save")}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
