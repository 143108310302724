import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#092C48',
      light: '#092C48',
    },
    success: {
      main: '#07BD74'
    },
    info: {
      main: '#246BFD'
    },
    warning: {
      main: '#FACC15'
    },
    error: {
      main: '#F75555'
    },
    secondary: {
      main: '#D8D8D8'
    },
    grey: {
      "900": "#212121",
      "800": "#424242",
      "700": "#616161",
      "600": "#757575",
      "500": "#9E9E9E",
      "400": "#BDBDBD",
      "300": "#E0E0E0",
      "200": "#EEEEEE",
      "100": "#F5F5F5",
      "50": "#FAFAFA",
    }
  },
  typography: {
    fontFamily: ['SF Pro Rounded', 'Helvetica', 'Arial', 'sans-serif'].join(',')
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          letterSpacing: 0.6,
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600, textTransform: "none", fontSize: 16
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          letterSpacing: '0.5px'
        }
      }
    }
  }
})

export {
  theme
}