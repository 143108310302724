// RETURNS TRUE IF USER IS LOGGED IN, OTHER WISE FALSE
function isLoggedIn() {
  const access_token = localStorage.getItem('access_token');
  // NOT FOUND
  if (!access_token) {
    return false;
  }
  // FOUNDED - USER IS LOGGED IN
  return true;
}

function isGuestMode() {
  const access_token = localStorage.getItem('access_token');
  if (access_token === "guest") {
    return true;
  }
  return false;
}

export {
  isLoggedIn,
  isGuestMode
}