// HOOKS
import {FC, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate, useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useAppDispatch, useAppSelector} from "../../../store";
import {useTranslation} from "react-i18next";

// UI
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import {theme} from "../../../styles/theme";

// SERVICES & UTILS
import JobServices from "../../../services/JobServices";
import {newJobForm} from "../../Desktop/NewJob/schema";
import {IJob} from "../../../interfaces/IJob";
import {AxiosError} from "axios";

// ASSETS & ICONS
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";
import {ReactComponent as SuccessfulIcon} from "../../../assets/Icons/successful-modal-m.svg";
import {addJob, updateJob} from "../../../store/Slices/JobsSlice";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment";

interface NewJobFormData {
  title: string;
  budget: number;
  experience: string;
  tools: string;
  description: string;
  startOn: string;
  endOn: string;
  agreedToTC: boolean;
  agreedToFeeDeduction: boolean;
}

const MPostNewJob = () => {
  const [isLoading, setLoading] = useState(false);
  const {id} = useAppSelector(state => state.user);
  const {enqueueSnackbar} = useSnackbar();

  const navigate = useNavigate();
  const location = useLocation();
  const jobState = location.state as IJob;

  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  // SUCCESS MODAL HANDLERS
  const [openSuccess, showSuccess] = useState(false);
  const handleShowSuccess = () => showSuccess(true);
  const handleCloseSuccess = () => showSuccess(false);

  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
    setValue,
    watch,
  } = useForm<NewJobFormData>({
    defaultValues: {
      title: "",
      budget: 0,
      experience: "",
      tools: "",
      description: "",
      startOn: "",
      endOn: "",
      agreedToTC: false,
      agreedToFeeDeduction: false,
    },
    resolver: joiResolver(newJobForm),
  });

  // SUBMIT FORM
  async function submitForm(formData: NewJobFormData) {
    setLoading(true);
    const {title, budget, experience, tools, description, startOn, endOn} = formData;

    // UPDATE EXISTING JOB
    if (jobState) {
      try {
        const resp = await JobServices.UpdateJob(jobState.id, {
          title,
          budget,
          experience,
          tools,
          description,
          startOn,
          endOn,
        });

        // SUCCESS UPDATE
        if (resp.status === 200) {
          enqueueSnackbar(t("Job updated successfully."), {variant: "success"});
          dispatch(
            updateJob({id: jobState.id, title, budget, experience, tools, description, startOn, endOn})
          );
          navigate("/m/my-jobs");
          return;
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        // INTERNET ISSUE
        if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
        return;
      }
    }

    // CREATE NEW JOB
    try {
      const resp = await JobServices.CreateJob({
        title,
        budget,
        experience,
        tools,
        description,
        startOn,
        endOn,
        client: id,
      });

      // SUCCESS
      if (resp.status === 201) {
        enqueueSnackbar(t("New job created successfully."), {variant: "success"});
        const {_id, createdAt, updatedAt, clientId} = resp.data;
        dispatch(
          addJob({
            id: _id,
            title,
            budget,
            experience,
            tools,
            startOn,
            endOn,
            description,
            clientId,
            createdAt,
            updatedAt,
          })
        );
        setLoading(false);
        navigate("/m/my-jobs");
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    if (jobState) {
      setValue("title", jobState.title);
      setValue("budget", jobState.budget);
      setValue("startOn", jobState.startOn);
      setValue("endOn", jobState.endOn);
      setValue("experience", jobState.experience);
      setValue("tools", jobState.tools);
      setValue("description", jobState.description);
    }
  }, []);

  return (
    <>
      <HomeNavigationHeader isPrimary={false} screenLabel={t("Post a miniJob")} />

      {/* FORM */}
      <Box sx={{textAlign: "left"}}>
        <form onSubmit={handleSubmit(submitForm)}>
          {/* JOB TITLE */}
          <FormControl fullWidth margin="none">
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="title">
              {t("Job Title")}
            </FormLabel>
            <Controller
              name="title"
              control={control}
              render={({field}) => {
                return (
                  <>
                    <TextField
                      id="title"
                      size="medium"
                      variant="outlined"
                      placeholder={t("Title here")}
                      type="text"
                      error={errors?.title ? true : false}
                      helperText={t(errors?.title?.message || "")}
                      {...field}
                      sx={{mb: 2}}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          {/* START ON */}
          <FormControl fullWidth margin="none" sx={{mb: 2}}>
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="startOn">
              {t("Start On")}
              <span style={{fontSize: 12, marginLeft: 4, fontWeight: 400}}>(optional)</span>
            </FormLabel>
            <Controller
              name="startOn"
              control={control}
              render={({field}) => {
                return (
                  <MobileDateTimePicker
                    value={moment(field.value)}
                    format="DD/MM/YYYY hh:mm A"
                    onChange={value => {
                      if (value) {
                        setValue("startOn", value.toString());
                        setValue("endOn", "");
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: errors.startOn ? "red !important" : "#0000003b !important",
                      },
                    }}
                  />
                );
              }}
            />
            <FormHelperText sx={{color: theme.palette.error.main}}>{errors.startOn?.message}</FormHelperText>
          </FormControl>

          {/* END ON */}
          <FormControl fullWidth margin="none" sx={{mb: 2}}>
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="endOn">
              {t("End On")}
              <span style={{fontSize: 12, marginLeft: 4, fontWeight: 400}}>(optional)</span>
            </FormLabel>
            <Controller
              name="endOn"
              control={control}
              render={({field}) => {
                return (
                  <MobileDateTimePicker
                    value={moment(field.value)}
                    disabled={!watch("startOn")}
                    minDateTime={moment(watch("startOn"))}
                    format="DD/MM/YYYY hh:mm A"
                    onChange={value => {
                      if (value) {
                        setValue("endOn", value.toString());
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: errors.endOn ? "red !important" : "#0000003b !important",
                      },
                    }}
                  />
                );
              }}
            />
            <FormHelperText sx={{color: theme.palette.error.main}}>{errors.endOn?.message}</FormHelperText>
          </FormControl>

          {/* YOUR BUDGET */}
          <FormControl fullWidth margin="none">
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="budget">
              {t("Your Budget")}
            </FormLabel>
            <Controller
              name="budget"
              control={control}
              render={({field}) => {
                return (
                  <>
                    <TextField
                      id="budget"
                      size="medium"
                      placeholder="CHF"
                      variant="outlined"
                      type="number"
                      error={errors?.budget ? true : false}
                      helperText={t(errors?.budget?.message || "")}
                      {...field}
                      sx={{mb: 2}}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          {/* REQURIED EXPERIENCE */}
          <FormControl fullWidth margin="none">
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="experience">
              {t("Required Exprience")}
            </FormLabel>
            <Controller
              name="experience"
              control={control}
              render={({field}) => {
                return (
                  <>
                    <TextField
                      id="experience"
                      size="medium"
                      placeholder={t("Required Experience")}
                      variant="outlined"
                      type="text"
                      error={errors?.experience ? true : false}
                      helperText={errors?.experience ? errors?.experience?.message : null}
                      {...field}
                      sx={{mb: 2}}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          {/* REQURIED TOOLS */}
          <FormControl fullWidth margin="none">
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="tools">
              {t("Required Tools")}
            </FormLabel>
            <Controller
              name="tools"
              control={control}
              render={({field}) => {
                return (
                  <>
                    <TextField
                      id="tools"
                      size="medium"
                      placeholder={t("Required Tools")}
                      variant="outlined"
                      type="text"
                      error={errors?.tools ? true : false}
                      helperText={t(errors?.tools?.message || "")}
                      {...field}
                      sx={{mb: 2}}
                    />
                  </>
                );
              }}
            />
          </FormControl>

          {/* DESCRIPTION */}
          <FormControl fullWidth margin="none" sx={{marginY: 1}}>
            <FormLabel
              sx={{fontSize: 16, fontWeight: 600, color: theme.palette.common.black, mb: 1}}
              htmlFor="description">
              {t("Description")}
            </FormLabel>
            <Controller
              name="description"
              control={control}
              render={({field}) => {
                return (
                  <TextField
                    multiline
                    id="description"
                    size="medium"
                    variant="outlined"
                    type="text"
                    {...field}
                    error={errors?.description ? true : false}
                    helperText={t(errors?.description?.message || "")}
                    placeholder={t("Description here")}
                    rows={6}
                  />
                );
              }}
            />
          </FormControl>

          {/* AGREED TO T&C */}
          <FormControl>
            <FormControlLabel
              label={t("I Agree to the Helper2Go’s Terms & Conditions")}
              sx={{"& .MuiFormControlLabel-label": {fontSize: 14}}}
              control={
                <Controller
                  name="agreedToTC"
                  control={control}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      color="primary"
                      icon={<CheckBoxIcon />}
                      checkedIcon={<CheckBoxCheckedIcon />}
                    />
                  )}
                />
              }
            />
          </FormControl>

          {/* AGREED TO FEE DEDUCTION */}
          <FormControl>
            <FormControlLabel
              label={t("I Agree to the Helper2Go’s 5% fees of the order")}
              sx={{"& .MuiFormControlLabel-label": {fontSize: 14}}}
              control={
                <Controller
                  name="agreedToFeeDeduction"
                  control={control}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      color="primary"
                      icon={<CheckBoxIcon />}
                      checkedIcon={<CheckBoxCheckedIcon />}
                    />
                  )}
                />
              }
            />
          </FormControl>

          {/* SUBMIT BUTTON */}
          <FormControl sx={{display: "block", marginTop: 4}}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}
              sx={{fontSize: 16, borderRadius: 2.5, py: 1.5, textTransform: "none"}}>
              Save
              {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
            </Button>
          </FormControl>
        </form>
      </Box>

      {/* SUCCESS MODAL */}
      <SuccessModal
        open={openSuccess}
        closeModal={handleCloseSuccess}
        onContinue={() => {
          handleCloseSuccess();
          navigate(-1);
        }}
      />
    </>
  );
};

export default MPostNewJob;

interface SuccessModalProps {
  open: boolean;
  closeModal: () => void;
  onContinue: () => void;
}

const SuccessModal: FC<SuccessModalProps> = ({open, closeModal, onContinue}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            boxShadow: 24,
            p: 2,
            textAlign: "center",
            borderRadius: 2,
            maxWidth: 300,
          }}>
          <SuccessfulIcon />
          <Typography variant="h5" marginY={2} fontWeight={500}>
            Successful!
          </Typography>
          <Button variant="contained" fullWidth sx={{borderRadius: 2.5, py: 1}} onClick={onContinue}>
            Continue
          </Button>
        </Box>
      </Modal>
    </>
  );
};
