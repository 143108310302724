import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequest, IRequestAPI } from "../../interfaces/IRequest";

const initialState = [] as Array<IRequest>;

const RequestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setMyRequests: (state, { payload }) => {
      return state = payload;
    },
    updateCounterOffers: (state, { payload }: PayloadAction<IRequestAPI>) => {
      let requestIndex = state.findIndex((item => item.id === payload._id));
      state[requestIndex].counterOffers = payload.counterOffers;
      return state;
    },
    updateRequest: (state, { payload }: PayloadAction<Partial<IRequest>>) => {
      let requestIndex = state.findIndex(item => item.id === payload.id);
      state[requestIndex] = { ...state[requestIndex], ...payload };
      return state;
    },
    addNewRequest: (state, { payload }) => {
      return [...state, payload];
    }
  }
});

// ACTIONS
export const { setMyRequests, updateCounterOffers, updateRequest, addNewRequest } = RequestsSlice.actions;

export default RequestsSlice;