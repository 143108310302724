import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IJob } from "../../interfaces/IJob";

const initialState = [] as Array<IJob>;

const JobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setMyJobs: (state, { payload }) => {
      return state = payload;
    },
    addJob: (state, { payload }) => {
      state.push(payload);
      return state;
    },
    updateJob: (state, { payload }: PayloadAction<Partial<IJob>>) => {
      let jobIndex = state.findIndex((item => item.id === payload.id));
      state[jobIndex] = { ...state[jobIndex], ...payload };
      return state;
    },
    removeJob: (state, { payload }: PayloadAction<Partial<IJob>>) => {
      state = state.filter((item) => item.id != payload.id);
      return state;
    }
  }
});

// ACTIONS
export const { addJob, setMyJobs, updateJob, removeJob } = JobsSlice.actions;

export default JobsSlice;