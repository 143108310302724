import {Box} from "@mui/material";
import PrivacyPolicyText from "../../../components/Desktop/PrivacyPolicyText/PrivacyPolicyText";
import LeftBanner from "../../../components/Desktop/LeftBanner";
import {TNC_AUTH, TOP_RIGHT_BLOB} from "../../../assets/Images";
import AuthHeader from "../Register/AuthHeader";

const PrivacyPolicy = () => {
  return (
    <>
      <Box sx={{display: "flex"}}>
        <LeftBanner imageOnTop />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <Box paddingX={10} marginTop={10}>
            <PrivacyPolicyText />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
