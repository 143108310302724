// HOOKS
import {useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import NavigationHeader from "../../../components/Mobile/NavigationHeader";
import HeaderForgotPassword from "../ForgotPassword/HeaderForgotPassword";
import MVerifiedModal from "../../../components/Mobile/VerifiedModal";
import {joiResolver} from "@hookform/resolvers/joi";
import {theme} from "../../../styles/theme";
import Joi from "joi";

// ASSETS & ICONS
import {ReactComponent as LockIcon} from "../../../assets/Icons/lock.svg";
import {ReactComponent as VisibilityOff} from "../../../assets/Icons/hide-eye.svg";
import {ReactComponent as Visibility} from "../../../assets/Icons/eye.svg";
import {AxiosError} from "axios";
import AuthServices from "../../../services/AuthServices";

const newPasswordSchema = Joi.object({
  password: Joi.string().empty().min(8).required().messages({
    "string.base": "Password is required.",
    "string.required": "Password is required.",
    "string.empty": "Password is required.",
    "string.min": "Must have 8 letters.",
  }),
  confirmPassword: Joi.string().valid(Joi.ref("password")).required().empty().messages({
    "any.only": "Passwords do not match.",
    "string.base": "Confirm Password is required.",
    "string.required": "Confirm Password is required.",
    "string.empty": "Confirm Password is required.",
  }),
});

interface FormData {
  password: string;
  confirmPassword: string;
}

const MNewPassword = () => {
  const {state} = useLocation() as {state: {email: string}};
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();

  // MODAL HANDLERS - VERIFIED MODAL
  const [openVerified, setOpenVerified] = useState(false);
  const handleShowVerified = () => setOpenVerified(true);
  const handleCloseVerified = () => {
    setOpenVerified(false);
    navigate("/m/sign-in");
  };

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    resolver: joiResolver(newPasswordSchema),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    const {password, confirmPassword} = formData;
    setLoading(true);
    try {
      const resp = await AuthServices.ResetPassword({email: state.email, password, confirmPassword});

      // SUCCESS
      if (resp.status === 200) {
        enqueueSnackbar(t("Your password has been updated."), {variant: "success"});
        handleShowVerified();
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      // EMAIL NOT FOUND
      if (err.response?.status === 404) {
        enqueueSnackbar(t("This email does not exist."), {variant: "error"});
      }

      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          paddingX: 2,
          maxWidth: "600px",
          position: "relative",
          marginX: "auto",
        }}>
        <NavigationHeader />

        {/* HEADING - FORGOT PASSWORD */}
        <HeaderForgotPassword label={t("Enter your details to continue")} />

        <Box marginTop={6}>
          <form onSubmit={handleSubmit(submitForm)}>
            {/* PASSWORD */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="password"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="password"
                      size="medium"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.password ? true : false}
                      helperText={errors?.password ? errors?.password?.message : null}
                      placeholder="Password"
                    />
                  );
                }}
              />
            </FormControl>

            {/* CONFIRM PASSWORD */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="confirmPassword"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="confirmPassword"
                      size="medium"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.confirmPassword ? true : false}
                      helperText={t(errors?.confirmPassword?.message as string)}
                      placeholder="Confirm Password"
                    />
                  );
                }}
              />
            </FormControl>

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 4}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                disabled={isLoading}
                sx={{
                  fontSize: 16,
                  borderRadius: 2.5,
                  paddingY: 1.3,
                  textTransform: "none",
                  fontWeight: 600,
                  boxShadow: "4px 8px 24px 0px rgba(0, 0, 0, 0.25)",
                }}>
                {t("Continue")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>
        </Box>
      </Box>

      {/* VERIFIED MODAL */}
      <MVerifiedModal
        open={openVerified}
        handleClose={handleCloseVerified}
        verificationType="password"
        btnText={t("Redirect to Sign In")}
      />
    </>
  );
};

export default MNewPassword;
