import AppInstance from "../config/global.axios";
import { IJob } from "../interfaces/IJob";
import { IGetJobsOnSearch } from "../pages/Mobile/Search";

// CREATE NEW JOB
async function CreateJob(job: Partial<IJob>) {
  const response = await AppInstance({
    url: "/jobs/new-job",
    method: "POST",
    data: job
  });
  return response;
}

interface GetJobsPayload {
  client?: string;
  helper?: string;
  search?: string;
  startOn?: Date | "";
  endOn?: Date | "";
}
// GET JOBS WITH FILTER
async function GetJobsFiltered(payload: GetJobsPayload) {
  const response = await AppInstance({
    url: "/jobs",
    method: "POST",
    data: payload
  });
  return response;
}

// GET SINGLE JOB
async function GetJob(jobId?: string) {
  const response = await AppInstance({
    url: `/jobs/${jobId}`,
    method: "GET"
  });
  return response;
}

// UPDATE JOB
async function UpdateJob(jobId?: string, jobPayload?: Partial<IJob>) {
  const response = await AppInstance({
    url: `/jobs/${jobId}`,
    method: "PATCH",
    data: jobPayload
  });
  return response;
}

// DELETE JOB
async function DeleteJob(jobId: string) {
  const response = await AppInstance({
    url: `/jobs/${jobId}`,
    method: "DELETE"
  });
  return response;
}

const JobServices = {
  CreateJob,
  GetJobsFiltered,
  GetJob,
  UpdateJob,
  DeleteJob
}

export default JobServices;