// HOOKS
import {useTranslation} from "react-i18next";

import {Box, Typography} from "@mui/material";
import ZigzagHeading from "../ZigzagHeading";
import {ABOUT_US_HOME, LOGO_TEXT} from "../../../../assets/Images";

const AboutUs = () => {
  const {t} = useTranslation();
  return (
    <>
      <div id="AboutUs">
        <ZigzagHeading title={t("About Us")} />
      </div>
      <Box sx={{display: "flex", paddingX: 10, justifyContent: "space-between", marginBottom: 5}}>
        {/* LEFT IMAGE */}
        <img
          src={ABOUT_US_HOME}
          alt="about-us"
          style={{objectFit: "contain", flexBasis: "50%", height: 500, alignSelf: "center"}}
        />

        {/* RIGHT SIDE TEXT */}
        <Box sx={{padding: 4, flexBasis: "50%"}}>
          <img src={LOGO_TEXT} alt="logo-text" style={{marginBottom: 12}} />
          <Typography fontWeight={700} color={"#3B3B3B"} lineHeight={2} fontSize={24} mb={8}>
            {t(
              "Helper2GO is revolutionizing the world of services, sales agreements, and social projects by bringing all these elements together under one roof. The principle here is simple: you have a wish or a need, and someone else fulfills it for you. At the heart of Helper2GO are two key roles: the CLIENT, who posts a miniJob, and the HELPER, who takes on and completes the task."
            )}
          </Typography>
          <Typography fontWeight={700} color={"#3B3B3B"} lineHeight={2} fontSize={24} mb={8}>
            {t(
              "Our vision for the future is to develop Helper2GO into your personal digital assistant – a central hub for all your I need something done needs. With the goal of making Helper2GO more than just a platform; it's the beginning of a movement aimed at redefining how we view services and mutual support. Helper2GO was brought to life by Saçi Khan, right out of Niedergösgen, Switzerland. Helper2GO stands for innovation, community, and a new era of digital connectivity."
            )}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AboutUs;
