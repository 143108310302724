// HOOKS
import {FC} from "react";
import {useTranslation} from "react-i18next";

// UI
import {Box, Typography} from "@mui/material";
import {LOGO_HANDS} from "../../../assets/Images";
import {theme} from "../../../styles/theme";

interface Props {
  label: string;
}

const HeaderForgotPassword: FC<Props> = ({label}) => {
  const {t} = useTranslation();
  return (
    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <Box>
        <Typography variant="h5" fontWeight={700} color={theme.palette.primary.main}>
          {t("Forgot Password?")}
        </Typography>
        <Typography variant="subtitle2" marginTop={1} fontWeight={500} color={theme.palette.primary.main}>
          {label}
        </Typography>
      </Box>
      <img src={LOGO_HANDS} alt="logo-hands" style={{height: 90, width: 90}} />
    </Box>
  );
};

export default HeaderForgotPassword;
