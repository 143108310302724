// HOOKS
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

// UI
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import Navbar from "../../../components/Desktop/Navbar";
import Job from "../MyJobs/Job";
import Footer from "../../../components/Desktop/Footer";
import JobDetailsModal from "../MyJobs/JobDetailsModal";
import {theme} from "../../../styles/theme";

// ASSETS & ICONS
import {BLOBS_MY_JOBS} from "../../../assets/Images";
import {ReactComponent as SearchIcon} from "../../../assets/Icons/search.svg";
import {ReactComponent as FilterIcon} from "../../../assets/Icons/filter.svg";
import {useNavigate} from "react-router-dom";
import {IJobOnSearch} from "../../../interfaces/IJob";
import {useAppSelector} from "../../../store";
import {IGetJobsOnSearch} from "../../Mobile/Search";
import {useDebounce} from "use-debounce";
import JobServices from "../../../services/JobServices";
import {AxiosError} from "axios";
import {enqueueSnackbar} from "notistack";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import moment from "moment";

const SearchHelpers = () => {
  const {t} = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<IJobOnSearch[]>([]);
  const {id} = useAppSelector(state => state.user);

  const [searchFilters, setSearchFilters] = useState<IGetJobsOnSearch>({
    search: "",
    startOn: "",
    endOn: "",
  });
  const [debouncedSearchFilters] = useDebounce(searchFilters, 1000);

  // FILTER HANDLERS
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // MODAL HANDLERS - JOB DETAILS
  const [jobDetails, setJobDetails] = useState<null | IJobOnSearch>(null);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const handleShowJobDetails = (job: IJobOnSearch) => {
    setJobDetails(job);
    setShowJobDetails(true);
  };
  const handleCloseJobDetails = () => {
    setJobDetails(null);
    setShowJobDetails(false);
  };

  // GET JOBS WHILE SEARCH
  async function getJobsOnSearch() {
    setLoading(true);
    try {
      const resp = await JobServices.GetJobsFiltered({
        search: searchFilters.search,
        startOn: searchFilters.startOn ? new Date(searchFilters.startOn) : "",
        endOn: searchFilters.endOn ? new Date(searchFilters.endOn) : "",
      });

      // SUCCESS
      if (resp.status === 200) {
        const arrOfJobs = resp.data as IJobOnSearch[];
        setJobs(arrOfJobs.filter(item => item.client?._id !== id && item.status === "PENDING"));
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar("Make sure your internet is working.", {variant: "error"});
      } else {
        enqueueSnackbar("Something went wrong.", {variant: "error"});
      }
    }
  }

  useEffect(() => {
    getJobsOnSearch();
  }, [debouncedSearchFilters]);

  // const { budget }=jobDetails;

  return (
    <>
      <div style={{backgroundColor: "#D2EBFF"}}>
        <Navbar />
        <Box
          paddingX={10}
          paddingY={4}
          sx={{
            backgroundImage: `url(${BLOBS_MY_JOBS})`,
            backgroundPositionX: "right",
            backgroundPositionY: "-30px",
            backgroundRepeat: "no-repeat",
            backgroundColor: "#D2EBFF",
            backgroundSize: 200,
            position: "relative",
          }}>
          {/* SEARCH INPUT */}
          <FormControl fullWidth margin="normal" sx={{marginBottom: 4}}>
            <TextField
              id="search-helper"
              size="medium"
              variant="outlined"
              type="text"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" edge="end" onClick={handleClick}>
                      <FilterIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t("Search Here")}
              sx={{
                mb: 2,
                "& .MuiInputBase-input": {
                  backgroundColor: "white",
                  borderRadius: 2.5,
                },
                "& .MuiInputBase-root": {
                  backgroundColor: "white",
                },
              }}
              onChange={e => setSearchFilters({...searchFilters, search: e.target.value})}
            />
          </FormControl>

          {/* SEARCH RESULTS */}
          <Box
            sx={{padding: 4, borderRadius: 4, filter: "blur(40)", backgroundColor: "rgba(9, 44, 72, 0.08)"}}>
            {/* HEADINGS */}
            <Box sx={{marginBottom: 4}}>
              <Typography
                variant="h4"
                fontWeight={700}
                color={theme.palette.common.black}
                textTransform={"uppercase"}>
                {t("Orders to be done")}
              </Typography>
            </Box>

            {/* LOADER */}
            {isLoading ? (
              <>
                <Skeleton variant="rounded" height={104} sx={{borderRadius: 2.5, marginBottom: 2}} />
                <Skeleton variant="rounded" height={104} sx={{borderRadius: 2.5, marginBottom: 2}} />
                <Skeleton variant="rounded" height={104} sx={{borderRadius: 2.5, marginBottom: 2}} />
              </>
            ) : null}

            {/* MAPPING JOBS */}
            {!isLoading && jobs.length
              ? jobs.map((item: IJobOnSearch) => {
                  return <Job key={item._id} job={item} showDetails={() => handleShowJobDetails(item)} />;
                })
              : null}

            {/* NO RESULT */}
            {!isLoading && !jobs.length ? (
              <Typography textAlign={"center"} marginTop={4}>
                No Result found.
              </Typography>
            ) : null}
          </Box>
        </Box>

        {/* JOB DETAILS MODAL */}
        <JobDetailsModal
          job={
            jobDetails && jobDetails.client?._id
              ? {
                  id: jobDetails._id,
                  client: jobDetails.client?._id,
                  budget: jobDetails.budget,
                  createdAt: jobDetails.createdAt,
                  description: jobDetails.description,
                  endOn: jobDetails.endOn,
                  experience: jobDetails.experience,
                  startOn: jobDetails.startOn,
                  status: jobDetails.status,
                  isNotified: jobDetails.isNotified,
                  title: jobDetails.title,
                  tools: jobDetails.tools,
                  updatedAt: jobDetails.updatedAt,
                }
              : null
          }
          open={showJobDetails}
          handleClose={handleCloseJobDetails}
          setOpenJobDetails={setShowJobDetails}
          isApplyigForJob
        />

        {/* FOOTER */}
        <Footer />
      </div>

      {/* FILTERS DROPDOWN */}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -295,
        }}
        // transformOrigin={{horizontal: "right", vertical: "center"}}
        sx={{
          marginTop: 1,
          "& .MuiPaper-root": {
            borderRadius: 5,
          },
        }}>
        <Box sx={{width: 300, padding: 2}}>
          <FormControl fullWidth sx={{mb: 1}}>
            <FormLabel
              sx={{fontSize: 18, fontWeight: 600, color: theme.palette.common.black}}
              htmlFor="startOn">
              {t("Start On")}
            </FormLabel>
            <MobileDateTimePicker
              key={"startOn"}
              value={moment(searchFilters.startOn)}
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: 2.5,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0000003b !important",
                },
              }}
              onChange={value =>
                setSearchFilters({...searchFilters, startOn: moment(value).toString(), endOn: ""})
              }
            />
          </FormControl>
          <FormControl fullWidth>
            <FormLabel
              sx={{fontSize: 18, fontWeight: 600, color: theme.palette.common.black}}
              htmlFor="endOn">
              {t("End On")}
            </FormLabel>
            <MobileDateTimePicker
              key={"endOn"}
              value={moment(searchFilters.endOn)}
              minDate={moment(searchFilters.startOn)}
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: 2.5,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#0000003b !important",
                },
              }}
              onChange={value => setSearchFilters({...searchFilters, endOn: moment(value).toString()})}
            />
          </FormControl>
        </Box>
      </Menu>
    </>
  );
};

export default SearchHelpers;
