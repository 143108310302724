import {useState, useEffect} from "react";
import {useAppSelector} from "../store";
import FAVICON_IMAGE from "../assets/Icons/favico.png";

type FaviconState = string | null;

export const useNotification = () => {
  const title = document.title;
  const [favicon, setFavicon] = useState<FaviconState>(null);
  const [notificationFavicon, setNotificationFavicon] = useState<FaviconState>(null);
  const jobs = useAppSelector(state => state.jobs);

  useEffect(() => {
    createFavicon();
    const jobNotNotifiedYet = jobs.filter(item => !item.isNotified);
    if (jobNotNotifiedYet.length) {
      addNotification();
    } else {
      clearNotification();
    }
  }, [jobs]);

  const createFavicon = (): void => {
    // Save original favicon
    const faviconElement: HTMLLinkElement | null = document.getElementById(
      "favicon"
    ) as HTMLLinkElement | null;
    if (faviconElement) {
      setFavicon(faviconElement.href);
      // Create new favicon using the existing favicon but add red dot
      const img: HTMLImageElement = new Image();
      img.src = faviconElement.href;
      img.onload = () => {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const context: CanvasRenderingContext2D | null = canvas.getContext("2d");
        if (context) {
          context.drawImage(img, 0, 0, img.width, img.height);
          context.beginPath();
          context.arc(img.width - img.width / 5, img.height / 5, img.width / 5, 0, 2 * Math.PI);
          context.fillStyle = "#ff0000";
          context.fill();
          setNotificationFavicon(canvas.toDataURL("image/png"));
        }
      };
    }
  };

  const addNotification = (): void => {
    const faviconElement: HTMLLinkElement | null = document.getElementById(
      "favicon"
    ) as HTMLLinkElement | null;
    if (faviconElement && notificationFavicon) {
      faviconElement.href = notificationFavicon;
    }
  };

  const clearNotification = (): void => {
    document.title = title;
    const faviconElement: HTMLLinkElement | null = document.getElementById(
      "favicon"
    ) as HTMLLinkElement | null;
    if (faviconElement && favicon) {
      faviconElement.href = FAVICON_IMAGE;
    }
  };

  return {addNotification, clearNotification};
};

// export default Notifications;
