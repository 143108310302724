// HOOKS
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";
import {useTranslation} from "react-i18next";

// UI
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import AuthHeader from "../Register/AuthHeader";

// SERVICES & UTILES
import {theme} from "../../../styles/theme";

// ASSETS
import {ReactComponent as LockIcon} from "../../../assets/Icons/lock.svg";
import {ReactComponent as VisibilityOff} from "../../../assets/Icons/hide-eye.svg";
import {ReactComponent as Visibility} from "../../../assets/Icons/eye.svg";
import {ALRIGHT_AUTH, TOP_RIGHT_BLOB} from "../../../assets/Images";
import Joi from "joi";
import AuthServices from "../../../services/AuthServices";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";

const newPasswordSchema = Joi.object({
  password: Joi.string().empty().min(8).required().messages({
    "string.base": "Password is required.",
    "string.required": "Password is required.",
    "string.empty": "Password is required.",
    "string.min": "Must have 8 letters.",
  }),
  confirmPassword: Joi.string().valid(Joi.ref("password")).required().empty().messages({
    "any.only": "Passwords do not match.",
    "string.base": "Confirm Password is required.",
    "string.required": "Confirm Password is required.",
    "string.empty": "Confirm Password is required.",
  }),
});

interface FormData {
  password: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const {state} = useLocation() as {state: {email: string}};
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    handleSubmit,
    formState: {errors, isDirty, isValid},
    control,
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
    mode: "all",
    resolver: joiResolver(newPasswordSchema),
  });

  // SUBMIT FORM
  async function submitForm(formData: FormData) {
    const {password, confirmPassword} = formData;
    setLoading(true);
    try {
      const resp = await AuthServices.ResetPassword({email: state.email, password, confirmPassword});

      // SUCCESS
      if (resp.status === 200) {
        enqueueSnackbar(t("Your password has been updated."), {variant: "success"});
        setLoading(false);
        navigate("/login");
      }
    } catch (error) {
      const err = error as AxiosError;
      // EMAIL NOT FOUND
      if (err.response?.status === 404) {
        enqueueSnackbar(t("This email does not exist."), {variant: "error"});
      }

      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <AuthHeader title={t("Set new Password")} headline={ALRIGHT_AUTH} />

          <Box py={6} px={10} pr={20} display={"flex"} justifyContent={"center"}>
            <form onSubmit={handleSubmit(submitForm)}>
              {/* PASSWORD */}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="password"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="password"
                        size="medium"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon fill={theme.palette.grey[400]} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={errors?.password ? true : false}
                        helperText={t(errors?.password?.message || "")}
                        placeholder="Password"
                        sx={{mb: 2}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* CONFIRM PASSWORD */}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="confirmPassword"
                        size="medium"
                        variant="outlined"
                        type={showPassword ? "text" : "password"}
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon fill={theme.palette.grey[400]} />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={errors?.confirmPassword ? true : false}
                        helperText={t(errors?.confirmPassword?.message || "")}
                        placeholder="Confirm Password"
                        sx={{mb: 2}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* SUBMIT BUTTON */}
              <FormControl sx={{display: "block", marginTop: 15}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                  disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                  {t("Continue")}
                  {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
                </Button>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
