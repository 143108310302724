// HOOKS
import {FC} from "react";

// UI
import {Box, Typography} from "@mui/material";
import {LOGO_HANDS, LOGO_TEXT} from "../../../assets/Images";
import {theme} from "../../../styles/theme";

interface Props {
  title?: string;
  headline?: string;
}

const MAuthBanner: FC<Props> = ({title, headline}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: 2,
          textAlign: "center",
        }}>
        <img src={LOGO_HANDS} alt="logo" style={{height: 80, objectFit: "contain"}} />
        <img src={LOGO_TEXT} alt="logo" style={{height: 24, objectFit: "contain"}} />
        {title ? (
          <Typography mt={2} fontSize={24} fontWeight={700} color={theme.palette.primary.main}>
            {title}
          </Typography>
        ) : null}
        {headline ? (
          <Typography mt={1} fontSize={14} color={theme.palette.primary.main}>
            {headline}
          </Typography>
        ) : null}
      </Box>
    </>
  );
};

export default MAuthBanner;
