// HOOKS & UI
import {FC} from "react";
import {useNavigate} from "react-router-dom";
import {Box, Typography} from "@mui/material";

// ASSETS & ICONS
import {ReactComponent as GoBackIcon} from "../../../assets/Icons/go-back-arrow.svg";

interface Props {
  title?: string;
}

const NavigationHeader: FC<Props> = ({title}) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          marginY: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          minHeight: 32,
        }}>
        <GoBackIcon onClick={() => navigate(-1)} style={{position: "absolute", left: 0}} />
        <Typography variant="h6" fontWeight={700} marginX={"auto"}>
          {title}
        </Typography>
      </Box>
    </>
  );
};

export default NavigationHeader;
