import axios from "axios";

const AppInstance = axios.create({
  baseURL: "https://api.helper2go.ch/api"
  // baseURL: "http://localhost:5050/api"
});

AppInstance.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});

AppInstance.interceptors.response.use((response) => response);

export default AppInstance;