// HOOKS
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {joiResolver} from "@hookform/resolvers/joi";

// UI
import {Box, Button, FormControl, InputAdornment, TextField, Typography} from "@mui/material";
import AuthLeftBanner from "../../../components/Desktop/LeftBanner";
import AuthHeader from "../Register/AuthHeader";

// SERVICES & UTILES
import {theme} from "../../../styles/theme";

// ASSETS
import {ReactComponent as Call} from "../../../assets/Icons/call.svg";
import {NEARLY_THERE_AUTH, TOP_RIGHT_BLOB} from "../../../assets/Images";
import {verifyPhone} from "./schema";

const VerifyPhone = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = useForm({
    defaultValues: {
      phone: "",
    },
    resolver: joiResolver(verifyPhone),
  });

  return (
    <>
      <Box sx={{display: "flex"}}>
        <AuthLeftBanner />

        <Box
          sx={{
            backgroundImage: `url(${TOP_RIGHT_BLOB})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            width: "55%",
            minHeight: "100vh",
            backgroundSize: "300px",
          }}>
          <AuthHeader title="Verify your Mobile Number to continue" headline={NEARLY_THERE_AUTH} />

          <Box py={6} px={10} pr={20}>
            <form onSubmit={handleSubmit(() => navigate("/verify-otp"))}>
              {/* PHONE NUMBER */}
              {/* PASSWORD */}
              <FormControl fullWidth margin="normal">
                <Controller
                  name="phone"
                  control={control}
                  render={({field}) => {
                    return (
                      <TextField
                        id="phone"
                        size="medium"
                        variant="outlined"
                        type={"text"}
                        {...field}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Call
                                fill={errors?.phone ? theme.palette.error.main : theme.palette.primary.main}
                              />
                            </InputAdornment>
                          ),
                        }}
                        error={errors?.phone ? true : false}
                        helperText={errors?.phone ? errors?.phone?.message : null}
                        placeholder="+1 000 000 000"
                        sx={{mb: 2}}
                      />
                    );
                  }}
                />
              </FormControl>

              {/* SUBMIT BUTTON */}
              <FormControl sx={{display: "block", marginTop: 15}}>
                <Button
                  fullWidth
                  variant="contained"
                  type="submit"
                  sx={{fontSize: 20, borderRadius: 2.5, py: 1}}>
                  Continue
                </Button>
              </FormControl>
            </form>
          </Box>
          {/* GREY TEXT */}
          <Box mt={14} mb={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              px={8}
              variant="body2"
              fontWeight={500}
              color={theme.palette.grey[400]}
              mr={1}
              textAlign={"center"}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quae quaerat maiores consequuntur
              nostrum ex aut debitis saepe provident soluta a!
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VerifyPhone;
