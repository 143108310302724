// HOOKS
import {FC, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDebounce} from "use-debounce";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../store";

// UI
import {
  Avatar,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {theme} from "../../../styles/theme";
import FilterDrawer from "./FilterDrawer";

// SERVICES & UTILS
import {calculateDuration} from "../../../utils/JobUtils";
import JobServices from "../../../services/JobServices";
import {IJobOnSearch} from "../../../interfaces/IJob";
import {AxiosError} from "axios";
import moment from "moment";

// ASSETS & ICONS
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import {ReactComponent as SearchIcon} from "../../../assets/Icons/search.svg";
import {ReactComponent as FilterIcon} from "../../../assets/Icons/filter.svg";
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";
import HomeNavigationHeader from "../Home/HomeNavigationHeader";
import MSplitText from "../../../components/Mobile/MSplitText";
import {isGuestMode} from "../../../utils/AuthUtils";

export interface IGetJobsOnSearch {
  search?: string;
  startOn?: string;
  endOn?: string;
}

const MSearch = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [jobs, setJobs] = useState<IJobOnSearch[]>([]);
  const {id} = useAppSelector(state => state.user);
  const {t} = useTranslation();

  const [searchFilters, setSearchFilters] = useState<IGetJobsOnSearch>({
    search: "",
    startOn: "",
    endOn: "",
  });
  const [debouncedSearchFilters] = useDebounce(searchFilters, 1000);

  // DRAWER HANDLERS
  const [openDrawer, setOpenDrawer] = useState(false);
  const hanldeOpenDrawer = () => setOpenDrawer(true);
  const hanldeCloseDrawer = () => setOpenDrawer(false);

  // MODAL HANDLERS - JOB DETAILS
  const [jobDetails, setJobDetails] = useState<null | IJobOnSearch>(null);
  const [showJobDetails, setShowJobDetails] = useState(false);
  const handleShowJobDetails = (job: IJobOnSearch) => {
    setJobDetails(job);
    setShowJobDetails(true);
  };
  const handleCloseJobDetails = () => {
    setJobDetails(null);
    setShowJobDetails(false);
  };

  // GET JOBS WHILE SEARCH
  async function getJobsOnSearch() {
    setLoading(true);
    try {
      const resp = await JobServices.GetJobsFiltered({
        search: searchFilters.search,
        startOn: searchFilters.startOn ? new Date(searchFilters.startOn) : "",
        endOn: searchFilters.endOn ? new Date(searchFilters.endOn) : "",
      });

      // SUCCESS
      if (resp.status === 200) {
        const arrOfJobs = resp.data as IJobOnSearch[];
        setJobs(arrOfJobs.filter(item => item.client?._id !== id && item.status === "PENDING"));
        setLoading(false);
      }
    } catch (error) {
      const err = error as AxiosError;
      setLoading(false);
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    getJobsOnSearch();
  }, [debouncedSearchFilters]);

  return (
    <>
      <HomeNavigationHeader isPrimary primaryText="Search" />
      {/* SEARCH INPUT */}
      <FormControl fullWidth margin="none" sx={{marginBottom: 1}}>
        <TextField
          id="search-helper"
          size="medium"
          variant="outlined"
          type="text"
          value={searchFilters.search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fill={theme.palette.primary.main} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end" onClick={hanldeOpenDrawer}>
                  <FilterIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder={t("Search Here")}
          sx={{
            mb: 2,
            "& .MuiInputBase-input": {
              backgroundColor: "white",
              borderRadius: 2.5,
            },
            "& .MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          onChange={e => setSearchFilters({...searchFilters, search: e.target.value})}
        />
      </FormControl>

      {/* SEARCH RESULTS */}
      <Box>
        {isLoading ? (
          <>
            <Skeleton variant="rounded" height={104} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={104} sx={{borderRadius: 2.5, marginBottom: 2}} />
            <Skeleton variant="rounded" height={104} sx={{borderRadius: 2.5, marginBottom: 2}} />
          </>
        ) : null}
        {!isLoading && jobs.length
          ? jobs.map((item: IJobOnSearch) => {
              return <SearchResultCard key={item._id} job={item} showDetails={handleShowJobDetails} />;
            })
          : null}
        {!isLoading && !jobs.length ? (
          <Typography textAlign={"center"} marginTop={20}>
            No Result found.
          </Typography>
        ) : null}
      </Box>

      {/* FILTER DRAWER */}
      <FilterDrawer
        open={openDrawer}
        closeDrawer={hanldeCloseDrawer}
        onChangeFilter={({startOn, endOn}) => {
          setSearchFilters({
            ...searchFilters,
            startOn: new Date(startOn).toString(),
            endOn: new Date(endOn).toString(),
          });
        }}
      />

      {/* JOB DETAILS MODAL */}
      <JobDetailsModal
        open={showJobDetails}
        job={jobDetails}
        closeModal={handleCloseJobDetails}
        onApplForyJob={() => {
          handleCloseJobDetails();
          navigate(`/m/apply-job?job_id=${jobDetails?._id}`, {state: jobDetails});
        }}
      />
    </>
  );
};

export default MSearch;

interface SearchResultCardProps {
  showDetails: (job: IJobOnSearch) => void;
  job: IJobOnSearch;
}

export const SearchResultCard: FC<SearchResultCardProps> = ({showDetails, job}) => {
  return (
    <>
      <Container
        disableGutters
        sx={{
          padding: 2,
          borderRadius: 2.5,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          position: "relative",
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
        onClick={() => showDetails(job)}>
        <Avatar sx={{height: 60, width: 60}} src={job?.client?.profile}>
          {job?.client?.name.split("")[0].toUpperCase()}
        </Avatar>
        <Box sx={{marginLeft: 2}}>
          <Typography variant="h6" className="truncate-1">
            {job.title}
          </Typography>
          <Typography variant="body2" className="truncate-2">
            {job.description}
          </Typography>
        </Box>
        <Box sx={{display: "flex", alignItems: "center", position: "absolute", top: 8, right: 8}}>
          <ClockIcon style={{width: 12, height: 12}} fill="#fff" />
          <Typography variant="body2" sx={{marginLeft: 0.5, fontSize: 10}}>
            {moment(job.createdAt).format("DD/MM/YYYY")}
          </Typography>
        </Box>
      </Container>
    </>
  );
};

interface JobDetailsModalProps {
  open: boolean;
  closeModal: () => void;
  onApplForyJob: () => void;
  job: IJobOnSearch | null;
}

const JobDetailsModal: FC<JobDetailsModalProps> = ({open, closeModal, onApplForyJob, job}) => {
  const {t} = useTranslation();
  const requests = useAppSelector(state => state.requests);

  function isAlreadyApplied() {
    let findJob = requests.find(item => item?.job?._id === job?._id);
    if (findJob) return true;
    else return false;
  }

  return (
    <>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        sx={{
          height: "100vh",
          overflowY: "scroll",
          display: "grid",
          placeItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            marginY: 2,
            width: "80%",
            boxShadow: 24,
            px: 1,
            py: 2,
            borderRadius: 2,
            position: "relative",
          }}>
          {/* CLOSE BUTTON */}
          <Box sx={{position: "absolute", top: 10, right: 10}}>
            <CloseIcon style={{cursor: "pointer", height: 24, width: 24}} onClick={closeModal} />
          </Box>

          {/* USER INFO */}
          <Box sx={{display: "flex", alignItems: "center"}}>
            <Avatar sx={{height: 70, width: 70, textTransform: "uppercase"}} src={job?.client?.profile}>
              {job?.client?.name.split("")[0]}
            </Avatar>
            <Box sx={{marginLeft: 2}}>
              <Typography variant="body2" fontSize={16}>
                {job?.client?.name}
              </Typography>
              <Typography variant="h6" lineHeight={1.2}>
                {job?.title}
              </Typography>
            </Box>
          </Box>

          {/* DATE & TIME */}
          <Box sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 1}}>
            <ClockIcon style={{width: 16, height: 16}} fill={theme.palette.primary.main} />
            <Typography variant="body2" sx={{marginLeft: 0.5, fontSize: 12}}>
              {moment(job?.createdAt).format("DD/MM/YYYY")}
            </Typography>
          </Box>

          {/* JOB INFO */}
          <Box
            sx={{
              padding: 1,
              paddingY: 2,
              marginTop: 1,
              borderRadius: 2.5,
              border: `1px solid ${theme.palette.grey[300]}`,
            }}>
            {job?.startOn && job?.endOn ? (
              <MSplitText title={t("Timeline")} value={calculateDuration(job?.startOn, job?.endOn)} />
            ) : null}
            <MSplitText title={t("Budget")} value={job?.budget.toString()} />
            <MSplitText title={t("Experience")} value={job?.experience} />
            <MSplitText title={t("Required Tools")} value={job?.tools} />
          </Box>

          {/* JOB DESCRIPTION */}
          <Box
            sx={{
              padding: 1,
              paddingY: 2,
              marginTop: 2,
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 2.5,
            }}>
            <Typography color={theme.palette.primary.main} fontSize={14} mb={1}>
              {t("Description")}
            </Typography>
            <Typography fontSize={14} whiteSpace={"pre-wrap"}>
              {job?.description}
            </Typography>
          </Box>

          {/* BUTTON - APPLY AS HELPER */}
          <Box sx={{textAlign: "center", marginTop: 3}}>
            {!isGuestMode() ? (
              <Button
                variant="contained"
                sx={{textTransform: "none", borderRadius: 2.5, paddingX: 4}}
                disabled={isAlreadyApplied()}
                onClick={onApplForyJob}>
                {isAlreadyApplied() ? t("Already Applied") : t("Apply as a Helper")}
              </Button>
            ) : (
              <Typography color={theme.palette.primary.main} fontSize={14} mb={1}>
                {t("Please Sign In first to apply for the job.")}
              </Typography>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
