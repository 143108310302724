// HOOKS
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {useNavigate} from "react-router";
import {joiResolver} from "@hookform/resolvers/joi";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {useAppDispatch} from "../../../../store";

// SERVICES & UTILS
import AuthServices from "../../../../services/AuthServices";
import {app, messaging} from "../../../../config/global.firebase";
import {setUser} from "../../../../store/Slices/UserSlice";
import {getMessaging, getToken, isSupported} from "firebase/messaging";
import {registerUser} from "./schema";
import {AxiosError} from "axios";

// UI
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import TncModal from "../../../../components/Desktop/TnCModal/TncModal";
import {theme} from "../../../../styles/theme";
import PhoneInput from "react-phone-input-2";
import AuthHeader from "../AuthHeader";

// ASSESTS & ICONS
import {ReactComponent as CheckBoxCheckedIcon} from "../../../../assets/Icons/tnc-checked-icon.svg";
import {ReactComponent as VisibilityOff} from "../../../../assets/Icons/hide-eye.svg";
import {ReactComponent as CheckBoxIcon} from "../../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as ProfileIcon} from "../../../../assets/Icons/profile.svg";
import {ReactComponent as EmailIcon} from "../../../../assets/Icons/email.svg";
import {ReactComponent as Visibility} from "../../../../assets/Icons/eye.svg";
import {ReactComponent as LockIcon} from "../../../../assets/Icons/lock.svg";
import {IRegisterForm, ISignUpResponse} from "../../../../interfaces/IUser";
import {HELLO_AUTH, TOP_RIGHT_BLOB} from "../../../../assets/Images";

const CreateAccount = () => {
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [fcm, setFCM] = useState<string | undefined>();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();

  // TnC MODAL HANDLER
  const [openTnc, setOpenTnC] = useState(false);
  const hanldeShowTnC = () => setOpenTnC(true);
  const hanldeCloseTnC = () => setOpenTnC(false);

  const {
    handleSubmit,
    formState: {errors, isValid, isDirty},
    control,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      agreedToTC: false,
    },
    mode: "all",
    resolver: joiResolver(registerUser),
  });
  // console.log(errors);

  async function submitToCreateAccount(formData: IRegisterForm) {
    setLoading(true);
    const {name, email, phone, password} = formData;

    try {
      const res = await AuthServices.SignUp({name, email, phone, password, fcm});
      const userData: ISignUpResponse = res.data;
      setLoading(false);
      // SUCCESS
      if (res.status === 201) {
        // NEED EMAIL VERIFICATION
        if (!res.data?.isEmailVerified) {
          dispatch(setUser(userData));
          navigate("/verify-email?verification_channel=email&verification_path=account");
          return enqueueSnackbar(t("We need to verify your email."), {
            variant: "success",
          });
        }
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      const message = (err.response?.data as {message: string})?.message;
      // EMAIL ALREADY REGISTERED
      if (err.response?.status === 400 && message === "Email is registered already.") {
        enqueueSnackbar(t("This email is already registered."), {variant: "error"});
      }
      // PHONE ALREADY REGISTERED
      else if (err.response?.status === 400 && message === "Phone is already registered.") {
        enqueueSnackbar(t("This phone number is already registered."), {variant: "error"});
      }
      // INTERNET ISSUE
      else if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  async function getPermission() {
    if (await isSupported()) {
      const messaging = getMessaging(app);
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const tokenFCM = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_WEB_PUSH,
        });
        setFCM(tokenFCM);
      } else if (permission === "denied") {
        alert("You've denied the notifications.");
      }
    }
  }

  useEffect(() => {
    getPermission();
  }, []);

  const TermsAndConditionsLink = (
    <Typography
      component="span"
      sx={{textDecoration: "underline", color: theme.palette.primary.main}}
      onClick={hanldeShowTnC}>
      Terms & Conditions
    </Typography>
  );

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url(${TOP_RIGHT_BLOB})`,
          backgroundPosition: "top right",
          backgroundRepeat: "no-repeat",
          width: "55%",
          minHeight: "100vh",
          backgroundSize: "300px",
        }}>
        <AuthHeader title={t("Create your free Account")} headline={HELLO_AUTH} />

        <Box py={6} px={10} pr={20}>
          <form onSubmit={handleSubmit(submitToCreateAccount)}>
            {/* NAME */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="name"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="name"
                      size="medium"
                      variant="outlined"
                      type="text"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ProfileIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.name ? true : false}
                      helperText={t(errors?.name?.message || "")}
                      placeholder={t("Name")}
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* EMAIL */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="email"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="email"
                      size="medium"
                      variant="outlined"
                      type="email"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.email ? true : false}
                      helperText={t(errors?.email?.message || "")}
                      placeholder={t("Email")}
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* PHONE NUMBER */}
            <FormControl fullWidth margin="normal" sx={{border: "none"}}>
              <Controller
                name="phone"
                control={control}
                render={({field}) => {
                  return (
                    <PhoneInput
                      autoFormat
                      country={"af"}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      inputStyle={{width: "100%"}}
                    />
                  );
                }}
              />
              <Typography variant="caption" color="error" marginLeft={2} marginTop={0.5}>
                {t(errors.phone?.message || "")}
              </Typography>
            </FormControl>

            {/* PASSWORD */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="password"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="password"
                      size="medium"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.password ? true : false}
                      helperText={t(errors?.password?.message || "")}
                      placeholder={t("Password")}
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* CONFIRM PASSWORD */}
            <FormControl fullWidth margin="normal">
              <Controller
                name="confirmPassword"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="confirmPassword"
                      size="medium"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fill={theme.palette.grey[400]} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end">
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors?.confirmPassword ? true : false}
                      helperText={t(errors?.confirmPassword?.message || "")}
                      placeholder={t("Confirm Password")}
                      sx={{mb: 2}}
                    />
                  );
                }}
              />
            </FormControl>

            {/* AGREED TO T&c */}
            <FormControl>
              <FormControlLabel
                label={
                  <>
                    {t("I Agree to the Helper2Go’s")} {TermsAndConditionsLink}
                  </>
                }
                control={
                  <Controller
                    name="agreedToTC"
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        color="primary"
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxCheckedIcon />}
                      />
                    )}
                  />
                }
              />
            </FormControl>

            {/* SUBMIT BUTTON - CREATE ACCOUNT */}
            <FormControl sx={{display: "block", marginTop: 15}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{
                  fontSize: 20,
                  borderRadius: 2.5,
                  py: 1,
                  textTransform: "none",
                  fontWeight: 400,
                }}
                disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                {t("Create Account")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>

          {/* ALREADY HAVE AN ACCOUNT */}
          <Box mt={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant="body2" fontWeight={500} color={theme.palette.grey[400]} mr={1}>
              {t("Already have an account?")}
            </Typography>
            <Button variant="contained" sx={{px: 2, borderRadius: 2.5}} onClick={() => navigate("/login")}>
              {t("Log In")}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* TERMS & CONDITIONS MODAL */}
      <TncModal closeModal={hanldeCloseTnC} open={openTnc} />
    </>
  );
};

export default CreateAccount;
