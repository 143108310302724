const LOGO_HANDS = require('./logo-hands.webp');
const LOGO_TEXT = require('./logo-text.webp');
const LOGO_FULL = require('./logo-full.webp');
const WORKERS_HOME = require('./workers.webp');
const UI_RECTAGLES_HOME = require('./ui-reactagles-home.webp');
const ZIGZAG_HOME = require('./how-it-works-home.webp');
const JOBS_EVERYWHERE_HOME = require('./jobs-everywhere-home.webp');
const HELPER_EVERYWHERE_HOME = require('./helper-everywhere-home.webp');
const ABOUT_US_HOME = require('./about-us-home.webp');
const LOGO_FOOTER = require('./logo-footer.webp');
const LINES_FOOTER = require('./lines-footer.webp');
const HANDS_FOOTER = require('./hands-footer.png');
const AUTH_BANNER = require('./auth-banner.png');
const AUTH_LOGO = require('./auth-logo.png');
const HELLO_AUTH = require('./hello-register.png');
const TOP_RIGHT_BLOB = require('./top-right-blob.png');
const ALRIGHT_AUTH = require('./alright-auth.png');
const NEARLY_THERE_AUTH = require('./nearly-there-auth.png');
const VERIFIED_AUTH = require('./verified-modal.png');
const BLOBS_MY_JOBS = require('./blobs-my-jobs.png');
const LOWER_BLOBS = require('./lower-blob.png');
const TNC_AUTH = require('./terms-and-conditions.png');
const M_ONBOARDING_1 = require('./m-onboarding-1.png');
const M_ONBOARDING_2 = require('./m-onboarding-2.png');
const M_ONBOARDING_3 = require('./m-onboarding-3.png');

export {
  LOGO_HANDS,
  LOGO_TEXT,
  LOGO_FULL,
  WORKERS_HOME,
  UI_RECTAGLES_HOME,
  ZIGZAG_HOME,
  JOBS_EVERYWHERE_HOME,
  HELPER_EVERYWHERE_HOME,
  ABOUT_US_HOME,
  LOGO_FOOTER,
  LINES_FOOTER,
  HANDS_FOOTER,
  AUTH_BANNER,
  AUTH_LOGO,
  HELLO_AUTH,
  TOP_RIGHT_BLOB,
  ALRIGHT_AUTH,
  NEARLY_THERE_AUTH,
  VERIFIED_AUTH,
  BLOBS_MY_JOBS,
  LOWER_BLOBS,
  TNC_AUTH,
  M_ONBOARDING_1,
  M_ONBOARDING_2,
  M_ONBOARDING_3
}