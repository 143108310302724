// HOOKS
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useNavigate, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

// UI
import TncModal from "../../../components/Desktop/TnCModal/TncModal";
import Navbar from "../../../components/Desktop/Navbar";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import {theme} from "../../../styles/theme";
import {MobileDateTimePicker} from "@mui/x-date-pickers";
import Footer from "../../../components/Desktop/Footer";

// SERVICES & UTILS
import {addJob, updateJob} from "../../../store/Slices/JobsSlice";
import JobServices from "../../../services/JobServices";
import {enqueueSnackbar} from "notistack";
import {newJobForm} from "./schema";
import {AxiosError} from "axios";

// ASSETS & ICONS
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {BLOBS_MY_JOBS, LOGO_HANDS} from "../../../assets/Images";
import {useAppDispatch, useAppSelector} from "../../../store";
import moment from "moment";
import {IJob} from "../../../interfaces/IJob";

interface NewJobFormData {
  title: string;
  budget: number;
  experience: string;
  tools: string;
  description: string;
  startOn: string;
  endOn: string;
  agreedToTC: boolean;
  agreedToFeeDeduction: boolean;
}

const NewJob = () => {
  const [isLoading, setLoading] = useState(false);
  const {id} = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const jobState = location.state as IJob;

  // TnC MODAL HANDLER
  const [openTnc, setOpenTnC] = useState(false);
  const hanldeShowTnC = () => setOpenTnC(true);
  const hanldeCloseTnC = () => setOpenTnC(false);

  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
    setValue,
    watch,
    reset,
  } = useForm<NewJobFormData>({
    defaultValues: {
      title: "",
      budget: 0,
      experience: "",
      tools: "",
      description: "",
      startOn: "",
      endOn: "",
      agreedToTC: false,
      agreedToFeeDeduction: false,
    },
    mode: "all",
    resolver: joiResolver(newJobForm),
  });

  // SUBMIT FORM
  async function submitForm(formData: NewJobFormData) {
    setLoading(true);
    const {title, budget, experience, tools, description, startOn, endOn} = formData;

    // UPDATE EXISTING JOB
    if (jobState) {
      try {
        const resp = await JobServices.UpdateJob(jobState.id, {
          title,
          budget,
          experience,
          tools,
          description,
          startOn,
          endOn,
        });

        // SUCCESS UPDATE
        if (resp.status === 200) {
          enqueueSnackbar(t("Job updated successfully."), {variant: "success"});
          dispatch(
            updateJob({id: jobState.id, title, budget, experience, tools, description, startOn, endOn})
          );
          navigate("/my-jobs");
          return;
        }
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        // INTERNET ISSUE
        if (err.code === "ERR_NETWORK") {
          enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
        } else {
          enqueueSnackbar(t("Something went wrong."), {variant: "error"});
        }
        return;
      }
    }

    // CREATE NEW JOB
    try {
      const resp = await JobServices.CreateJob({
        title,
        budget,
        experience,
        tools,
        description,
        startOn,
        endOn,
        client: id,
      });

      // SUCCESS
      if (resp.status === 201) {
        enqueueSnackbar(t("New job created successfully."), {variant: "success"});
        const {_id, createdAt, updatedAt, clientId} = resp.data;
        dispatch(
          addJob({
            id: _id,
            title,
            budget,
            experience,
            tools,
            startOn,
            endOn,
            description,
            clientId,
            createdAt,
            updatedAt,
          })
        );
        reset();
        setLoading(false);
        navigate("/my-jobs");
      }
    } catch (error) {
      setLoading(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  useEffect(() => {
    if (jobState) {
      setValue("title", jobState.title);
      setValue("budget", jobState.budget);
      setValue("startOn", jobState.startOn);
      setValue("endOn", jobState.endOn);
      setValue("experience", jobState.experience);
      setValue("tools", jobState.tools);
      setValue("description", jobState.description);
    }
  }, []);

  const TermsAndConditionsLink = (
    <Typography
      component="span"
      sx={{textDecoration: "underline", color: theme.palette.primary.main}}
      onClick={e => {
        e.stopPropagation();
        hanldeShowTnC();
      }}>
      Terms & Conditions
    </Typography>
  );

  return (
    <>
      <Navbar />
      <Box
        paddingX={10}
        paddingY={4}
        sx={{
          backgroundImage: `url(${BLOBS_MY_JOBS})`,
          backgroundPositionX: "right",
          backgroundPositionY: "-120px",
          backgroundRepeat: "no-repeat",
          backgroundColor: "#D2EBFF",
          backgroundSize: 200,
          position: "relative",
        }}>
        {/* HEADLINE */}
        <Container
          disableGutters
          sx={{margin: 0, display: "flex", justifyContent: "space-between", alignItems: "center"}}
          maxWidth={"xl"}>
          <Typography textAlign={"left"} variant="h4" fontWeight={600}>
            {t("Setup New Job")}
          </Typography>
          <img src={LOGO_HANDS} style={{opacity: 0.4}} width={150} height={150} alt="logo-hands" />
        </Container>

        <Container disableGutters sx={{margin: 0, width: "75%"}}>
          <form onSubmit={handleSubmit(submitForm)}>
            {/* TITLE */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                htmlFor="title">
                {t("Job Title")}
              </FormLabel>
              <Controller
                name="title"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="title"
                      size="medium"
                      variant="outlined"
                      type="text"
                      {...field}
                      error={errors?.title ? true : false}
                      helperText={t(errors?.title?.message || "")}
                      placeholder={t("Title here")}
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input": {
                          backgroundColor: "white",
                          borderRadius: 2.5,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#D2EBFF !important",
                        },
                      }}
                    />
                  );
                }}
              />
            </FormControl>

            {/* BUDGET */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                htmlFor="budget">
                {t("Your Budget")}
              </FormLabel>
              <Controller
                name="budget"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="budget"
                      size="medium"
                      variant="outlined"
                      type="number"
                      {...field}
                      error={errors?.budget ? true : false}
                      helperText={t(errors?.budget?.message || "")}
                      placeholder="CHF"
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input": {
                          backgroundColor: "white",
                          borderRadius: 2.5,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#D2EBFF !important",
                        },
                      }}
                    />
                  );
                }}
              />
            </FormControl>

            {/* START ON */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: theme.palette.common.black,
                  display: "flex",
                  alignItems: "center",
                }}
                htmlFor="startOn">
                <Typography variant="body2" mr={0.5}>
                  (optional)
                </Typography>
                {t("Start On")}
              </FormLabel>
              <Controller
                name="startOn"
                control={control}
                render={({field}) => {
                  return (
                    <MobileDateTimePicker
                      key={"startOn"}
                      {...field}
                      value={moment(field.value)}
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        borderRadius: 2.5,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: errors.startOn ? "red !important" : "#0000003b !important",
                        },
                      }}
                      onChange={value => {
                        if (value) {
                          setValue("startOn", value.toString());
                          setValue("endOn", "");
                        }
                      }}
                    />
                  );
                }}
              />
              <FormHelperText sx={{color: theme.palette.error.main}}>
                {t(errors.startOn?.message || "")}
              </FormHelperText>
            </FormControl>

            {/* END ON */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  color: theme.palette.common.black,
                  display: "flex",
                  alignItems: "center",
                }}
                htmlFor="endOn">
                <Typography variant="body2" mr={0.5}>
                  (optional)
                </Typography>
                {t("End On")}
              </FormLabel>
              <Controller
                name="endOn"
                control={control}
                render={({field}) => {
                  return (
                    <MobileDateTimePicker
                      key={"endOn"}
                      {...field}
                      value={moment(field.value)}
                      disabled={!watch("startOn")}
                      minDateTime={moment(watch("startOn"))}
                      onChange={value => {
                        if (value) {
                          setValue("endOn", value.toString());
                        }
                      }}
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        borderRadius: 2.5,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: errors.endOn ? "red !important" : "#0000003b !important",
                        },
                      }}
                    />
                  );
                }}
              />
              <FormHelperText sx={{color: theme.palette.error.main}}>
                {t(errors.endOn?.message || "")}
              </FormHelperText>
            </FormControl>

            {/* REQUIRED EXPERIENCE */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                htmlFor="experience">
                {t("Required Experience")}
              </FormLabel>
              <Controller
                name="experience"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="experience"
                      size="medium"
                      variant="outlined"
                      type="text"
                      {...field}
                      error={errors?.experience ? true : false}
                      helperText={t(errors?.experience?.message || "")}
                      placeholder={t("Details here")}
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input": {
                          backgroundColor: "white",
                          borderRadius: 2.5,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#D2EBFF !important",
                        },
                      }}
                    />
                  );
                }}
              />
            </FormControl>

            {/* REQUIRED TOOLS */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                htmlFor="tools">
                {t("Required Tools")}
              </FormLabel>
              <Controller
                name="tools"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      id="tools"
                      size="medium"
                      variant="outlined"
                      type="text"
                      {...field}
                      error={errors?.tools ? true : false}
                      helperText={t(errors?.tools?.message || "")}
                      placeholder={t("Details here")}
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input": {
                          backgroundColor: "white",
                          borderRadius: 2.5,
                        },
                        "& .MuiInputBase-root": {
                          backgroundColor: "#D2EBFF !important",
                        },
                      }}
                    />
                  );
                }}
              />
            </FormControl>

            {/* DESCRIPTION */}
            <FormControl fullWidth margin="none" sx={{marginY: 1}}>
              <FormLabel
                sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                htmlFor="description">
                {t("Description")}
              </FormLabel>
              <Controller
                name="description"
                control={control}
                render={({field}) => {
                  return (
                    <TextField
                      multiline
                      id="description"
                      size="medium"
                      variant="outlined"
                      type="text"
                      {...field}
                      error={errors?.description ? true : false}
                      helperText={t(errors?.description?.message || "")}
                      placeholder={t("Description here")}
                      rows={6}
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-input": {
                          backgroundColor: "white",
                          borderRadius: 2.5,
                          padding: 2,
                        },
                        "& .MuiInputBase-root": {
                          padding: 0,
                        },
                      }}
                    />
                  );
                }}
              />
            </FormControl>

            {/* AGREED TO T&C */}
            <FormControl>
              <FormControlLabel
                label={
                  <>
                    {t("I Agree to the Helper2Go’s")} {TermsAndConditionsLink}
                  </>
                }
                control={
                  <Controller
                    name="agreedToTC"
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        color="primary"
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxCheckedIcon />}
                      />
                    )}
                  />
                }
              />
            </FormControl>
            <br />

            {/* AGREED TO FEE DEDUCTION */}
            <FormControl>
              <FormControlLabel
                label={t("I Agree to the Helper2Go’s 5% fees of the order")}
                control={
                  <Controller
                    name="agreedToFeeDeduction"
                    control={control}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        color="primary"
                        icon={<CheckBoxIcon />}
                        checkedIcon={<CheckBoxCheckedIcon />}
                      />
                    )}
                  />
                }
              />
            </FormControl>

            {/* SUBMIT BUTTON */}
            <FormControl sx={{display: "block", marginTop: 4}}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                disabled={isLoading || !isDirty || !isValid || Object.keys(errors).length > 0}>
                {t("Post")}
                {isLoading ? <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} /> : null}
              </Button>
            </FormControl>
          </form>
        </Container>
      </Box>

      {/* FOOTER */}
      <Footer />

      {/* TERMS & CONDITIONS MODAL */}
      <TncModal closeModal={hanldeCloseTnC} open={openTnc} />
    </>
  );
};

export default NewJob;
