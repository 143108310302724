// HOOKS
import {BaseSyntheticEvent, FC, SyntheticEvent, useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {joiResolver} from "@hookform/resolvers/joi";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useAppSelector, useAppDispatch} from "../../../store";

// UI
import BoxPrimaryGradient from "../../../components/Desktop/BoxPrimaryGradient";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import {theme} from "../../../styles/theme";
import SplitText from "./SplitText";
import JobRequest from "./JobRequest";

// SERVICES & UTILS

import {addNewRequest} from "../../../store/Slices/RequestsSlice";
import RequestServices from "../../../services/RequestServices";

import {IJob, IJobOnSearch} from "../../../interfaces/IJob";
import {IRequestAPI} from "../../../interfaces/IRequest";
import JobServices from "../../../services/JobServices";
import {isLoggedIn} from "../../../utils/AuthUtils";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import moment from "moment";
import Joi from "joi";

// ASSETS & ICONS
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/Icons/tnc-checked-icon.svg";
import {ReactComponent as HorizontalDotsIcon} from "../../../assets/Icons/horizontal-dots.svg";
import {ReactComponent as CloseIcon} from "../../../assets/Icons/close-square.svg";
import {ReactComponent as CheckBoxIcon} from "../../../assets/Icons/tnc-icon.svg";
import {ReactComponent as ClockIcon} from "../../../assets/Icons/clock.svg";
import {calculateDuration} from "../../../utils/JobUtils";
import DeleteJobConfirmModal from "./DeleteJobConfirmModal";
import {removeJob} from "../../../store/Slices/JobsSlice";
import {Link} from "react-router-dom";

interface Props {
  job: IJob | null;
  open: boolean;
  handleClose: () => void;
  setOpenJobDetails: (flag: boolean) => void;
  isApplyigForJob?: boolean;
}

interface FormData {
  addCounterOffer: boolean;
  budget: number;
  budgetNote: string;
  note: string;
  agreedToTC: boolean;
}

export const counterOfferForm = Joi.object({
  addCounterOffer: Joi.boolean().optional(),
  budget: Joi.when("addCounterOffer", {
    is: true,
    then: Joi.number().min(1).required().messages({
      "number.base": "Budget is required.",
      "number.required": "Budget is required.",
      "number.min": "Budget can not be zero.",
      "any.required": "Budget can not be zero.",
    }),
    otherwise: Joi.number().optional().allow(null),
  }),
  budgetNote: Joi.when("addCounterOffer", {
    is: true,
    then: Joi.string().required().empty().messages({
      "string.base": "Budget Notes are required.",
      "string.required": "Budget Notes are required.",
      "string.empty": "Budget Notes are required.",
      "any.required": "Budget Notes are required.",
    }),
    otherwise: Joi.optional(),
  }),
  note: Joi.when("addCounterOffer", {
    is: false,
    then: Joi.string().required().empty().messages({
      "string.base": "Note are required.",
      "string.required": "Note are required.",
      "string.empty": "Note are required.",
      "any.required": "Note are required.",
    }),
    otherwise: Joi.optional(),
  }),
  agreedToTC: Joi.boolean().valid(true).required().messages({
    "any.only": "You must agreed to the Terms & Conditions.",
    "any.required": "You must agreed to the Terms & Conditions.",
  }),
});

const JobDetailsModal: FC<Props> = ({open, handleClose, isApplyigForJob, job, setOpenJobDetails}) => {
  const [jobRequests, setJobRequests] = useState<IRequestAPI[]>([]);
  const {id} = useAppSelector(state => state.user);
  const requests = useAppSelector(state => state.requests);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [isLoading2, setLoading2] = useState(false);
  const [isLoading3, setLoading3] = useState(false);

  // DELETE JOB MODAL HANDELERS
  const [delLoading, setDelLoading] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const handleDelOpen = () => {
    handleMenuClose();
    setOpenJobDetails(false);
    setOpenDel(true);
  };
  const handleDelClose = () => {
    setOpenDel(false);
  };

  const {t} = useTranslation();
  const [jobDetails, setJobDetails] = useState<IJobOnSearch | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // HANDLE FORM
  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
    watch,
    reset,
  } = useForm({
    defaultValues: {
      addCounterOffer: false,
      budget: 0,
      budgetNote: "",
      note: "",
      agreedToTC: false,
    },
    mode: "all",
    resolver: joiResolver(counterOfferForm),
  });
  const addCounterOfferFlag = watch("addCounterOffer");

  // USER IS CLIENT, SHOW HIM WHO REQUEST FOR THE JOB
  async function getRequestsOfJob() {
    if (job?.id && job?.client) {
      setLoading(true);
      try {
        const resp = await RequestServices.GetRequestOfJob(job?.id, job?.client);
        const arrOfRequests = resp.data as IRequestAPI[];
        setJobRequests(arrOfRequests);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const err = error as AxiosError;
        // INTERNET ISSUE
        if (err.code === "ERR_NETWORK") {
          enqueueSnackbar("Make sure your internet is working.", {variant: "error"});
        } else {
          enqueueSnackbar("Something went wrong.", {variant: "error"});
        }
      }
    }
  }

  // USER IS HELPER, SHOW HIM WHO IS CLIENT
  async function getJobDetails() {
    setLoading2(true);
    try {
      const resp = await JobServices.GetJob(job?.id);

      // SUCCESS
      if (resp.status === 200) {
        setJobDetails(resp.data as IJobOnSearch);
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
    }
  }

  // SEND REQUEST
  async function sendRequestForJob(formData: FormData) {
    setLoading3(true);
    const {budgetNote, budget, note} = formData;

    try {
      const resp = await RequestServices.CreateRequest({
        budget: budget || job?.budget,
        budgetNote,
        note,
        job: job?.id,
        client: job?.client,
        helper: id,
      });

      // SUCCESS
      if (resp.status === 201) {
        enqueueSnackbar(t("Request created successfully."), {variant: "success"});
        dispatch(addNewRequest(resp.data));
        setLoading3(false);
        handleClose();
        reset();
      }
    } catch (error) {
      setLoading3(false);
      const err = error as AxiosError;
      // INTERNET ISSUE
      if (err.code === "ERR_NETWORK") {
        enqueueSnackbar(t("Make sure your internet is working."), {variant: "error"});
      } else {
        enqueueSnackbar(t("Something went wrong."), {variant: "error"});
      }
    }
  }

  // DELETE THIS JOB
  async function deleteThisJob() {
    setDelLoading(true);
    try {
      const resp = await JobServices.DeleteJob(job?.id as string);
      // SUCCESS
      if (resp.status === 200) {
        enqueueSnackbar(t("Job deleted successfully."), {variant: "success"});
        setOpenDel(false);
        handleClose();
        dispatch(removeJob(job as IJob));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setDelLoading(false);
    }
  }

  // HANDLE EDIT JOB
  function handleEditJob() {
    navigate(`/my-jobs/new-job?job_id=${job?.id}`, {state: job});
  }

  function isAlreadyApplied() {
    let findJob = requests.find(item => item?.job?._id === jobDetails?._id);
    if (findJob) return true;
    else return false;
  }

  useEffect(() => {
    // USER IS CLIENT, SHOW HIM WHO REQUESTED FOR THE JOB
    if (job?.id && !isApplyigForJob) {
      getRequestsOfJob();
    }

    // USER IS HELPER, SHOW HIM FORM TO APPLY
    else if (job?.id && isApplyigForJob) {
      getJobDetails();
    }
  }, [job]);

  useEffect(() => {
    if (!addCounterOfferFlag) {
      const {note} = watch();
      reset(
        {
          note,
          addCounterOffer: false,
          agreedToTC: false,
        },
        {keepDirty: false, keepDirtyValues: false}
      );
    }
  }, [addCounterOfferFlag]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
        sx={{
          height: "100vh",
          overflowY: "scroll",
          display: "grid",
          placeItems: "center",
        }}>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            marginY: 3,
            width: "80%",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}>
          {/* CLOSE BUTTON */}
          <Box sx={{textAlign: "right", marginBottom: 2}}>
            <CloseIcon
              style={{cursor: "pointer"}}
              onClick={() => {
                handleClose();
                reset();
              }}
            />
          </Box>

          {/* JOB TITLE & DATE */}
          <Box sx={{borderRadius: 2.5, overflow: "hidden", marginBottom: 3}}>
            <BoxPrimaryGradient>
              <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2}}>
                <Box>
                  <Typography mb={0.5} color={theme.palette.common.white}>
                    {job?.title}
                  </Typography>
                  <Typography
                    fontSize={12}
                    variant="body2"
                    color={theme.palette.common.white}
                    sx={{display: "flex"}}>
                    <ClockIcon style={{width: 16, height: 16, fill: "white", marginRight: 4}} />
                    {moment(job?.createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Box>
                {!isApplyigForJob ? (
                  <Button onClick={handleMenuClick} variant="text">
                    <HorizontalDotsIcon style={{fill: "white"}} />
                  </Button>
                ) : null}
              </Box>
            </BoxPrimaryGradient>
          </Box>

          {/* DROPDOWN MENU */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            sx={{
              marginTop: 1,
              "& .MuiPaper-root": {
                borderRadius: 5,
              },
              textAlign: "center",
            }}>
            <MenuItem
              onClick={handleEditJob}
              sx={{
                width: 108,
              }}>
              Edit
            </MenuItem>
            <MenuItem onClick={handleDelOpen} sx={{color: theme.palette.error.dark}}>
              Delete
            </MenuItem>
          </Menu>

          {/* JOB INFO */}
          <Box
            sx={{
              padding: 2,
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 2.5,
              marginBottom: 3,
            }}>
            {job?.startOn && job.endOn && (
              <SplitText title={t("Timeline")} value={calculateDuration(job?.startOn, job?.endOn)} />
            )}
            <SplitText title={t("Bugdet")} value={job?.budget.toLocaleString()} />
            <SplitText title={t("Experience")} value={job?.experience} />
            <SplitText title={t("Required Tools")} value={job?.tools} />
          </Box>

          {/* JOB DESCRIPTION */}
          <Box
            sx={{
              padding: 2,
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 2.5,
              marginBottom: 3,
            }}>
            <Typography variant="h5" fontWeight={600} mb={1}>
              {t("Description")}
            </Typography>
            <Typography variant="body1" whiteSpace={"pre-wrap"}>
              {job?.description}
            </Typography>
          </Box>

          {/* REQUESTS */}
          {!isApplyigForJob ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: theme.palette.common.black,
                  width: "fit-content",
                  paddingX: 2,
                  paddingY: 1,
                  borderRadius: 10,
                }}>
                <Typography color={theme.palette.common.white}>{t("Requests")}</Typography>
              </Box>
              <Typography variant="h5" fontWeight={600} color={theme.palette.common.black} marginY={3}>
                {t("Pending")}
              </Typography>

              {/* PENDING - REQUESTS FOR THE JOB */}
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={100} sx={{borderRadius: 2.5, marginBottom: 2}} />
                  <Skeleton variant="rounded" height={100} sx={{borderRadius: 2.5, marginBottom: 2}} />
                </>
              ) : null}
              {!isLoading &&
                jobRequests
                  .filter(job => job.status === "PENDING")
                  .map(request => <JobRequest key={request._id} request={{id: request._id, ...request}} />)}
              {!isLoading && !jobRequests.filter(job => job.status === "PENDING").length && (
                <Typography textAlign={"center"} marginTop={0}>
                  {t("No pending request yet.")}
                </Typography>
              )}

              {/* ACCEPTED - REQUESTS FOR THE JOB */}
              <Typography variant="h5" fontWeight={600} color={theme.palette.common.black} marginY={3}>
                {t("Approved")}
              </Typography>
              {isLoading ? (
                <>
                  <Skeleton variant="rounded" height={100} sx={{borderRadius: 2.5, marginBottom: 2}} />
                  <Skeleton variant="rounded" height={100} sx={{borderRadius: 2.5, marginBottom: 2}} />
                </>
              ) : null}
              {!isLoading &&
                jobRequests
                  .filter(job => job.status === "APPROVED")
                  .map(request => <JobRequest key={request._id} request={{id: request._id, ...request}} />)}
              {!isLoading && !jobRequests.filter(job => job.status === "APPROVED").length && (
                <Typography textAlign={"center"} marginTop={0}>
                  {t("No approved request yet.")}
                </Typography>
              )}
            </>
          ) : null}

          {/* APPLYING FOR JOB */}
          {isApplyigForJob && isLoggedIn() ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                  backgroundColor: theme.palette.primary.main,
                  padding: 2,
                  borderRadius: 2.5,
                }}>
                <Avatar sx={{height: 70, width: 70}} src={jobDetails?.client?.profile}>
                  {jobDetails?.client?.name.split("")[0]}
                </Avatar>
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    paddingX: 2,
                    borderRadius: 2.5,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    width: "80%",
                  }}>
                  {/* I AM CLIENT HERE */}
                  <Typography variant="body2" color={theme.palette.common.white}>
                    {t("Client")}:
                  </Typography>
                  <Typography variant="h6" color={theme.palette.common.white}>
                    {jobDetails?.client?.name}
                  </Typography>
                </Box>
                <Typography variant="body1" fontWeight={300} color={theme.palette.common.white} ml={"auto"}>
                  {moment(jobDetails?.createdAt).fromNow()}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: theme.palette.common.black,
                  width: "fit-content",
                  paddingX: 4,
                  paddingY: 1,
                  borderRadius: 10,
                }}>
                <Typography color={theme.palette.common.white}>{t("Apply")}</Typography>
              </Box>

              {/* ADD NOTES */}
              <Box sx={{marginTop: 2, marginBottom: 4}}>
                <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                  <FormLabel
                    sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                    htmlFor="addNote">
                    {t("Add Note")}
                  </FormLabel>
                  <Controller
                    name="note"
                    control={control}
                    render={({field}) => {
                      return (
                        <TextField
                          multiline
                          id="addNote"
                          size="medium"
                          variant="outlined"
                          type="text"
                          {...field}
                          error={errors?.note ? true : false}
                          helperText={t(errors?.note?.message || "")}
                          placeholder={t("Note here")}
                          rows={6}
                          sx={{
                            mb: 2,
                            "& .MuiInputBase-input": {
                              backgroundColor: "white",
                              borderRadius: 2.5,
                              padding: 2,
                            },
                            "& .MuiInputBase-root": {
                              padding: 0,
                            },
                          }}
                        />
                      );
                    }}
                  />
                </FormControl>
              </Box>

              {/* ADD COUNTER OFFER */}
              <Box>
                <form onSubmit={handleSubmit(sendRequestForJob)}>
                  {/* ADD COUNTER OFFER */}
                  <FormControl
                    sx={{
                      "& .MuiTypography-body1": {
                        fontSize: 24,
                        fontWeight: 600,
                      },
                      marginBottom: 2,
                    }}>
                    <FormControlLabel
                      label={
                        <>
                          {t("Add Counter Offer")}
                          <Typography variant="body2" display={"inline"} ml={0.5} component={"span"}>
                            (optional)
                          </Typography>
                        </>
                      }
                      control={
                        <Controller
                          name="addCounterOffer"
                          control={control}
                          render={({field}) => (
                            <Checkbox
                              {...field}
                              color="primary"
                              icon={<CheckBoxIcon />}
                              checkedIcon={<CheckBoxCheckedIcon />}
                            />
                          )}
                        />
                      }
                    />
                  </FormControl>

                  {addCounterOfferFlag ? (
                    <>
                      {/* NEW BUDGET */}
                      <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                        <FormLabel
                          sx={{
                            fontSize: 24,
                            fontWeight: 600,
                            color: theme.palette.common.black,
                            display: "budget",
                          }}
                          htmlFor="note">
                          {t("New Budget")}
                        </FormLabel>
                        <Controller
                          name="budget"
                          control={control}
                          render={({field}) => {
                            return (
                              <TextField
                                id="budget"
                                size="medium"
                                variant="outlined"
                                type="number"
                                {...field}
                                error={errors?.budget ? true : false}
                                helperText={t(errors?.budget?.message || "")}
                                disabled={!watch("addCounterOffer")}
                                placeholder={t("Write here")}
                                sx={{
                                  mb: 2,
                                  "& .MuiInputBase-input": {
                                    backgroundColor: "white",
                                    borderRadius: 2.5,
                                  },
                                  "& .MuiInputBase-root": {
                                    backgroundColor: "#D2EBFF !important",
                                  },
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>

                      {/* BUDGET NOTES */}
                      <FormControl fullWidth margin="none" sx={{marginY: 1}}>
                        <FormLabel
                          sx={{fontSize: 24, fontWeight: 600, color: theme.palette.common.black}}
                          htmlFor="budgetNote">
                          {t("Budget Notes")}
                        </FormLabel>
                        <Controller
                          name="budgetNote"
                          control={control}
                          render={({field}) => {
                            return (
                              <TextField
                                multiline
                                id="budgetNote"
                                size="medium"
                                variant="outlined"
                                type="text"
                                {...field}
                                error={errors?.budgetNote ? true : false}
                                helperText={t(errors?.budgetNote?.message || "")}
                                placeholder={t("Description here")}
                                disabled={!watch("addCounterOffer")}
                                rows={6}
                                sx={{
                                  mb: 2,
                                  "& .MuiInputBase-input": {
                                    backgroundColor: "white",
                                    borderRadius: 2.5,
                                    padding: 2,
                                  },
                                  "& .MuiInputBase-root": {
                                    padding: 0,
                                  },
                                }}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </>
                  ) : null}
                  <br />
                  {/* AGREED TO T&C */}
                  <FormControl>
                    <FormControlLabel
                      // label={t("I Agree to the Helper2Go’s Terms & Conditions")}
                      label={
                        <>
                          {t("I Agree to the Helper2Go’s")}{" "}
                          <Link to={"/terms-and-conditions"}>{t("Terms & Conditions")}</Link>
                        </>
                      }
                      control={
                        <Controller
                          name="agreedToTC"
                          control={control}
                          render={({field}) => (
                            <Checkbox
                              {...field}
                              checked={Boolean(field.value)}
                              color="primary"
                              icon={<CheckBoxIcon />}
                              checkedIcon={<CheckBoxCheckedIcon />}
                            />
                          )}
                        />
                      }
                    />
                  </FormControl>

                  {/* SUBMIT BUTTON */}
                  <FormControl sx={{display: "block", marginTop: 4}}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      sx={{fontSize: 20, borderRadius: 2.5, py: 1}}
                      disabled={
                        isLoading3 ||
                        !isDirty ||
                        !isValid ||
                        Object.keys(errors).length > 0 ||
                        isAlreadyApplied()
                      }>
                      {isAlreadyApplied() ? t("Already applied for this job") : t("Send Offer")}
                      {isLoading3 ? (
                        <CircularProgress size={16} color="inherit" sx={{marginLeft: 1}} />
                      ) : null}
                    </Button>
                  </FormControl>
                </form>
              </Box>
            </>
          ) : null}
          {!isLoggedIn() ? (
            <Typography textAlign={"center"} marginTop={3}>
              {t("Please login to apply for the job.")}
            </Typography>
          ) : null}
        </Box>
      </Modal>

      {/* DELETE JOB MODAL */}
      <DeleteJobConfirmModal
        onCancel={handleDelClose}
        onDelete={deleteThisJob}
        open={openDel}
        deleteLoading={delLoading}
      />
    </>
  );
};

export default JobDetailsModal;
