// UI
import {Box, Typography} from "@mui/material";
import {FC} from "react";

interface Props {
  title: string;
  value?: string;
}

const SplitText: FC<Props> = ({title, value}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
          ":last-child": {marginBottom: 0},
        }}>
        <Typography>{title}</Typography>
        <Typography textTransform={"capitalize"} fontWeight={600}>
          {value}
        </Typography>
      </Box>
    </>
  );
};

export default SplitText;
