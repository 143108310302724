// HOOKS
import {FC} from "react";

// UI
import {Box, Typography} from "@mui/material";
import {theme} from "../../../../styles/theme";

// ASSETS
import {AUTH_LOGO} from "../../../../assets/Images";

interface Props {
  title?: string;
  headline?: string;
}

const AuthHeader: FC<Props> = ({title, headline}) => {
  return (
    <>
      <Box mt={10} px={10} sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
        <Box>
          <img src={headline} alt="headline" />
          <Typography mt={3} color={theme.palette.primary.main} fontSize={24}>
            {title}
          </Typography>
        </Box>
        <img
          src={AUTH_LOGO}
          alt="auth-logo"
          style={{minHeight: 150, maxHeight: 150, position: "relative", top: -30}}
        />
      </Box>
    </>
  );
};

export default AuthHeader;
