// HOOKS
import {FC} from "react";
import {useTranslation} from "react-i18next";

// UI
import {Box, Typography} from "@mui/material";
import {theme} from "../../../styles/theme";
import SplitText from "../../../pages/Desktop/MyJobs/SplitText";
import {ICounterOffer} from "../../../interfaces/IRequest";

interface Props {
  isMe: boolean;
  counterOffer?: ICounterOffer | undefined;
}

const CounterOffer: FC<Props> = ({isMe, counterOffer}) => {
  const {t} = useTranslation();
  return (
    <>
      <Box
        sx={{
          padding: 2,
          border: `1px solid ${theme.palette.grey[300]}`,
          marginBottom: 3,
          backgroundColor: theme.palette.common.white,
          borderRadius: isMe ? "20px 20px 0px 20px" : "0px 20px 20px 20px",
        }}>
        <SplitText title={t("Suggested Budget")} value={counterOffer?.budget?.toLocaleString()} />
        {counterOffer?.budgetNote ? (
          <>
            <Typography variant="body1" fontWeight={600} mb={1}>
              {t("Budget Notes")}
            </Typography>
            <Typography variant="body1" fontWeight={400} mb={1} whiteSpace={"pre-wrap"}>
              {counterOffer?.budgetNote}
            </Typography>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default CounterOffer;
